import { useState, useEffect, useCallback, useContext } from 'react';
import { doGetList, doPost, getAxiosInstance } from '../action/services';
import Search from './search.component';
import ListKontakter from './list-kontakter.component';
import { Container, Table, Nav } from 'react-bootstrap';
import ListBesiktningar from './list-besiktningar.component';
import ListProjekt from './list-projekt.component';
import ListColdcase from './list-coldcase.component';
import ListOrder from './list-order.component';
import { GlobalStateContext } from '../../global.context';
import Scheman from '../scheman/schemameny.component';
import { hasRole } from 'utils/auth';
const axios = getAxiosInstance();

const STATE_SOK = 5,
  STATE_KONTAKT = 0,
  STATE_BESIKTNING = 1,
  STATE_PROJEKT = 2,
  STATE_ORDER = 3,
  STATE_COLDCASE = 4;

const Start = (props) => {
  const [renderList, setRenderList] = useState(false);
  const [lists, setLists] = useState({
    [STATE_KONTAKT]: [],
    [STATE_BESIKTNING]: [],
    [STATE_PROJEKT]: [],
    [STATE_ORDER]: [],
    [STATE_COLDCASE]: [],
    [STATE_SOK]: [],
  });
  const [active, setActive] = useState(STATE_KONTAKT);
  const [isAdmin, setIsAdmin] = useState(false);
  const { session } = useContext(GlobalStateContext);

  const checkRoll = useCallback(() => {
    if (session && session.roller) {
      setIsAdmin(hasRole(['admin', 'affärsAdmin'], session));
    }
  }, [session]);

  useEffect(() => {
    if (!isAdmin) {
      checkRoll();
    }
  }, [isAdmin, checkRoll]);

  async function changeTab(active) {
    switch (active) {
      case STATE_SOK:
        if (!lists[STATE_SOK]) {
          setLists({ ...lists, [STATE_KONTAKT]: [] });
        }
        break;
      case STATE_KONTAKT:
        if (lists[STATE_KONTAKT].length === 0) {
          const listResponse = await axios.get(`/kontakter`);
          setLists({ ...lists, [STATE_KONTAKT]: listResponse.data || [] });
        }
        break;
      case STATE_BESIKTNING:
        if (lists[STATE_BESIKTNING].length === 0) {
          const listResponse = await axios.get(`/besiktningar/latest`);
          setLists({ ...lists, [STATE_BESIKTNING]: listResponse.data || [] });
        }
        break;
      case STATE_PROJEKT:
        if (lists[STATE_PROJEKT].length === 0) {
          const listResponse = await doGetList(`/projekt/latest`);
          setLists({ ...lists, [STATE_PROJEKT]: listResponse.data || [] });
        }
        break;
      case STATE_ORDER:
        if (lists[STATE_ORDER].length === 0) {
          const listResponse = await doGetList(`/order/latest`);
          setLists({ ...lists, [STATE_ORDER]: listResponse.data || [] });
        }
        break;
      case STATE_COLDCASE:
        if (lists[STATE_COLDCASE].length === 0) {
          const listResponse = await doGetList(`/coldcase/latest`);
          setLists({ ...lists, [STATE_COLDCASE]: listResponse.data || [] });
        }
        break;
      default:
      // none
    }
    setRenderList(true);
  }

  useEffect(() => {
    if (isAdmin) {
      changeTab(active);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, isAdmin]);

  const changeActive = (tab) => {
    if (tab !== active) {
      setRenderList(false);
      setActive(tab);
    }
  };

  const setSearchResult = (result) => {
    setRenderList(false);
    setLists({ ...lists, [STATE_SOK]: result });
    setActive(STATE_SOK);
    setRenderList(true);
  };

  const filter = async (options) => {
    if (options.kundlevel || options.ansvarig) {
      if (active === STATE_BESIKTNING) {
        let list = await doPost('/besiktningar/filterlatest', options);
        setLists({ ...lists, [STATE_BESIKTNING]: list.data });
      }
    } else {
      setRenderList(true);
    }
  };

  return (
    <>
      {isAdmin && (
        <div className="list-wrapper">
          <h2>Kontakter och projekt</h2>
          <Search
            handler={setSearchResult}
            clear={() => {
              changeActive(STATE_KONTAKT);
              setRenderList(true);
            }}
          />
          <Nav variant="tabs" defaultActiveKey={active} activeKey={active}>
            <Nav.Item>
              <Nav.Link
                eventKey={STATE_KONTAKT}
                onClick={(e) => {
                  changeActive(STATE_KONTAKT);
                }}
              >
                Kontakter
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey={STATE_BESIKTNING}
                onClick={(e) => {
                  changeActive(STATE_BESIKTNING);
                }}
              >
                Besiktningar
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey={STATE_PROJEKT}
                onClick={(e) => {
                  changeActive(STATE_PROJEKT);
                }}
              >
                Projekt
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey={STATE_ORDER}
                onClick={(e) => {
                  changeActive(STATE_ORDER);
                }}
              >
                Order
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey={STATE_COLDCASE}
                onClick={(e) => {
                  changeActive(STATE_COLDCASE);
                }}
              >
                Coldcase
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey={STATE_SOK}
                onClick={(e) => {
                  changeActive(STATE_SOK);
                }}
              >
                Sök
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Container className={'tab-container'}>
            {active === STATE_SOK && (
              <>
                <ListKontakter list={lists[STATE_SOK]} />
              </>
            )}
            {active === STATE_KONTAKT && (
              <>
                <ListKontakter list={lists[active]} />
              </>
            )}
            {active === STATE_BESIKTNING && (
              <>
                <ListBesiktningar list={lists[active]} filter={filter} />
              </>
            )}
            {active === STATE_PROJEKT && renderList && (
              <>
                <ListProjekt list={lists[active]} />
              </>
            )}
            {active === STATE_ORDER && renderList && (
              <>
                <ListOrder list={lists[active]} />
              </>
            )}
            {active === STATE_COLDCASE && renderList && (
              <>
                <ListColdcase list={lists[active]} />
              </>
            )}
            {!renderList && (
              <div className="list-wrapper fade-in">
                <Table hover striped>
                  <tbody>
                    <tr>
                      <td colSpan={3}>
                        <i>Laddar...</i>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            )}
          </Container>
        </div>
      )}
      {!isAdmin && <Scheman />}
    </>
  );
};

export default Start;
