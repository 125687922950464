import { useState, useRef } from 'react';
import { themeColors } from 'constants/colors';
import { Button, ListGroup } from 'react-bootstrap';
import { convertFileSize, formatFileSize } from 'utils/file';
import { Trash } from 'react-bootstrap-icons';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import mimedb from 'mime-db';

const Wrapper = styled.div``;

const InnerContainer = styled.div<any>`
  display: flex;
  border: 1px solid ${themeColors.primary};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

const ListGroupItemInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AddButtonWrapper = styled.div`
  min-height: 50px;
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

const InternalFileInput = styled.input`
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  visibility: hidden;
`;

const FileList = styled(ListGroup)`
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
`;

const Label = styled.legend<any>`
  padding: 0 5px;
  left: 9px;
  background: white;
  position: absolute;
  transform: translateY(-50%);
  color: ${themeColors.primary};
  font-size: 12px;
  top: 0;
`;

const Error = styled.div`
  color: ${themeColors.danger};
  margin-top: 3px;
  font-size: 0.75rem;
`;

export interface IFileInputProps {
  onError?: (error: string) => unknown;
  limit?: number;
  name?: string;
  onChange?: (files: File[]) => unknown;
  hideFileName?: boolean;
  label?: string;
  uploadLabel?: string;
  style?: React.CSSProperties;
  errorText?: string;
  files?: File[];
  acceptedFileTypes?: string[];
  maxFileSize?: string;
}

export function FileInput({
  maxFileSize = '20mb',
  onError,
  acceptedFileTypes = ['png', 'jpeg', 'pdf'],
  limit = 10,
  name,
  onChange,
  hideFileName,
  label,
  uploadLabel = 'Ladda upp',
  style,
  errorText,
  files,
}: IFileInputProps) {
  const fileLimitErrorText = 'Du kan max ladda upp 10 filer';
  const fileSizeErrorText = 'Filen är för stor';
  let fileExtensionErrorText = `Filen är på ett format som inte stöds. Tillåtna format är ${acceptedFileTypes.join(
    ', '
  )}`;
  const [internalFiles, setInternalFiles] = useState<File[]>([]);
  const [internalError, setInternalError] = useState<string>();
  const fileInput = useRef<HTMLInputElement>(null);
  errorText = errorText || internalError;

  errorText = errorText || '';
  if (typeof hideFileName !== 'boolean') {
    hideFileName = false;
  }

  files = files || internalFiles;

  const empty = !files || files.length === 0;
  const full = limit !== undefined && internalFiles.length >= limit;

  function internalOnError(error: string) {
    setInternalError(error);
    if (typeof onError === 'function') {
      onError(error);
    }
  }

  let addButton = (
    <AddButtonWrapper onClick={() => fileInput?.current?.click()}>
      <Button
        style={{
          alignSelf: 'center',
        }}
        variant="outlined"
        color="secondary"
      >
        {uploadLabel}
        <InternalFileInput
          ref={fileInput}
          type="file"
          accept={getMimeTypes(acceptedFileTypes)}
          multiple
          onChange={onFileInputChange}
        />
      </Button>
    </AddButtonWrapper>
  );

  return (
    <Wrapper style={style}>
      <InnerContainer empty={empty}>
        <Label empty={empty}>{label}</Label>
        {!full && addButton}
        <div>
          {!empty && (
            <FileList dense full={full}>
              {files.map((file, index) => (
                <ListGroup.Item key={uuid()}>
                  <ListGroupItemInner>
                    <div>
                      {file.name} {formatFileSize(file.size, 2)}
                    </div>
                    <Button
                      key={`${name}${index}`}
                      onClick={() => removeFile(index)}
                    >
                      <Trash />
                    </Button>
                  </ListGroupItemInner>
                </ListGroup.Item>
              ))}
            </FileList>
          )}
        </div>
      </InnerContainer>
      {errorText && <Error>{errorText}</Error>}
    </Wrapper>
  );

  function onFileInputChange(event) {
    const maxSize = convertFileSize(maxFileSize || '100kb');
    const newFiles: File[] = Array.from(event.target.files);

    const tooLargeFiles = newFiles.filter((f) => f.size > maxSize);
    const acceptedFiles = newFiles.filter((f) => f.size < maxSize);

    if (tooLargeFiles.length > 0) {
      setInternalFiles(internalFiles);
      const error = tooLargeFiles
        .map((f) => {
          let e = fileSizeErrorText || 'File too large';
          return e + ` (${f.name})`;
        })
        .join(', ');
      internalOnError(error);
      if (fileInput.current) {
        fileInput.current.value = '';
      }
      return;
    }

    const invalidFileExtensions = acceptedFiles.filter((f) => {
      const extension = f.name.substring(
        f.name.lastIndexOf('.') + 1,
        f.name.length
      );
      return !acceptedFileTypes.includes(extension.toLowerCase());
    });

    if (invalidFileExtensions.length > 0) {
      setInternalFiles(internalFiles);

      const error = invalidFileExtensions
        .map((f) => {
          let e = fileExtensionErrorText || 'File has invalid extension';
          return e + ` (${f.name})`;
        })
        .join(', ');
      internalOnError(error);
      if (fileInput.current) {
        fileInput.current.value = '';
      }
      return;
    }

    if (!isNaN(limit) && limit < internalFiles.length + newFiles.length) {
      setInternalFiles(internalFiles);

      let error = fileLimitErrorText || `The maximum file limit is ${limit}`;
      internalOnError(error);
      return;
    }

    let files = internalFiles.slice(0);
    if (newFiles.length > 0) {
      files = newFiles.concat(files);
    }
    setInternalFiles(files);
    if (fileInput.current) {
      fileInput.current.value = '';
    }
    if (onChange) {
      onChange(files);
    }
  }

  function removeFile(index) {
    let newFiles = internalFiles.slice(0);
    newFiles.splice(index, 1);
    setInternalFiles(newFiles);
    if (onChange) {
      onChange(newFiles);
    }
  }
}

function getMimeTypes(types) {
  const result = types.map((type) => {
    const mimeType = lookupMimeType(type);
    if (mimeType) {
      return mimeType;
    }

    return false;
  });

  if (result.some((t) => !t)) {
    return [];
  }

  return result.join(',');
}

function lookupMimeType(extension) {
  const matchingType = Object.keys(mimedb).find((type) => {
    if (!Array.isArray(mimedb[type].extensions)) {
      return false;
    }
    return mimedb[type].extensions.includes(extension);
  });

  return matchingType;
}
