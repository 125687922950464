import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

export interface IConfirmationFieldProps {
  code: string;
  onChange?: (success: boolean) => unknown;
  className?: string;
  style?: React.CSSProperties;
}

export function ConfirmationField({
  code,
  onChange = () => null,
  className,
  style,
}: IConfirmationFieldProps) {
  const [confirmationInput, setConfirmationInput] = useState('');

  useEffect(() => {
    if (confirmationInput === code) {
      onChange(true);
      return;
    }

    if (confirmationInput === `"${code}"`) {
      onChange(true);
      return;
    }

    onChange(false);
  }, [code, confirmationInput, onChange]);

  return (
    <Form.Group className={className} style={style}>
      <Form.Label>
        Ange "{code}" i fältet nedan för att bekräfta borttagningen
      </Form.Label>
      <Form.Control
        value={confirmationInput}
        onChange={(event) => setConfirmationInput(event.target.value)}
      />
    </Form.Group>
  );
}
