import { createContext } from 'react';
import { IPersonal } from 'types/personal';

export interface ICalendarContext {
  personal: null | IPersonal;
}

export const CalendarContext = createContext<ICalendarContext>({
  personal: null,
});
