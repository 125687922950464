import axios from 'axios';

export function getAxiosInstance() {
  return axios.create({
    baseURL: '/radea',
  });
}

export const doPost = async (path, data, open) => {
  return await axios({
    method: 'post',
    url: `${open ? '' : '/radea'}${path}`,
    data: data,
  });
};

export const doGet = async (path, open) => {
  return await axios({
    method: 'get',
    url: `${open ? '' : '/radea'}${path}`,
  });
};

export const doGetList = async (path, open) => {
  let result = await doGet(path, open);
  if (typeof result.success !== 'undefined' && !result.success) {
    console.log('Error:', result.msg);
    return { data: [] };
  }
  return result;
};

export const getSessionInfo = async () => {
  const { data } = await doGet('/auth/sessioninfo', true);
  if (data.success) {
    return data.session;
  }
  return null;
};

export const getPersonal = async () => {
  const res = await doGetList(`/personal/aktiv`);
  return res.data;
};

export default { doPost, doGet, doGetList, getSessionInfo, getPersonal };
