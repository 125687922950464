import EGDatePicker from '@edgeguideab/datepicker';
import moment, { Moment, MomentInput } from 'moment-timezone';
import Popover from 'react-bootstrap/Popover';
import { Form, InputGroup, Overlay, Modal } from 'react-bootstrap';
import { useState, useRef } from 'react';
import { CalendarWeek } from 'react-bootstrap-icons';
import { RenderFunction } from 'types';
import { useScreenSize } from 'hooks/useScreenSize';
import styled from 'styled-components';

const StyledModal = styled(Modal)`
  .variable-width {
    display: flex;
    justify-content: center;

    .modal-content {
      width: auto;
    }
  }
`;

const MODAL_BREAKPOINT = 845;

export interface IDateDisplayProps extends IDatePickerProps {
  onClick: () => unknown;
}

export interface IDatePickerProps {
  value: string | Date | Moment;
  onChange: (date: Date) => unknown;
  label?: string;
  minDate?: MomentInput;
  maxDate?: MomentInput;
  onRenderDisplay?: RenderFunction<IDateDisplayProps>;
  markRangeEnds?: boolean;
  range?: Date;
  showToday?: boolean;
}

export function DatePicker(props: IDatePickerProps) {
  const {
    value,
    onChange,
    minDate,
    maxDate,
    onRenderDisplay,
    markRangeEnds,
    range,
    showToday = true,
  } = props;
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const { width } = useScreenSize();
  const displayAsModal = width < MODAL_BREAKPOINT;

  const defaultDisplayRenderer = ({
    label,
    value,
    onClick,
  }: IDateDisplayProps) => (
    <>
      <Form.Label>{label}</Form.Label>
      <InputGroup onClick={onClick}>
        <Form.Control disabled value={moment(value).format('YYYY-MM-DD')} />
        <InputGroup.Append>
          <InputGroup.Text>
            <CalendarWeek />
          </InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    </>
  );

  const picker = (
    <EGDatePicker
      value={moment(value).toDate()}
      modal={false}
      onChange={(newValue: Date) => {
        onChange(newValue);
        setShow(false);
      }}
      showToday={showToday}
      locale="sv-se"
      minDate={minDate ? moment(minDate).toDate() : undefined}
      maxDate={maxDate ? moment(maxDate).toDate() : undefined}
      showWeekNumbers
      markRangeEnds={markRangeEnds}
      range={range}
    />
  );

  const display =
    typeof onRenderDisplay === 'function'
      ? onRenderDisplay(
          { ...props, onClick: () => setShow(!show) },
          defaultDisplayRenderer
        )
      : defaultDisplayRenderer({ ...props, onClick: () => setShow(!show) });

  if (displayAsModal) {
    return (
      <>
        {display}
        <StyledModal
          dialogClassName="variable-width"
          centered
          show={show}
          onHide={() => setShow(false)}
        >
          {picker}
        </StyledModal>
      </>
    );
  }

  return (
    <div ref={target}>
      {display}
      <Overlay
        target={target}
        placement="bottom"
        show={show}
        onHide={() => setShow(false)}
        rootClose
      >
        <Popover
          id="popover-basic"
          style={{ fontFamily: 'Roboto', maxWidth: '100%' }}
        >
          {picker}
        </Popover>
      </Overlay>
    </div>
  );
}

export default DatePicker;
