import moment from 'moment';
import { ITidsbokning } from 'types';

export interface ICalendarHourgridProps {
  rangeEvents: ITidsbokning[];
  minHour?: number;
  maxHour?: number;
}

export const CalendarHourGrid = ({
  rangeEvents,
  minHour = 7,
  maxHour = 18,
}: ICalendarHourgridProps) => {
  const hours = maxHour - minHour;

  return (
    <div className={'calendar-grid'}>
      {rangeEvents.map(() => (
        <span className="whole-day"></span>
      ))}
      {Array.from(Array(hours)).map((_, idx) => {
        const time = moment()
          .set('hour', idx + minHour)
          .set('minutes', 0);

        return (
          <>
            <span>{time.format('HH:mm')}</span>
            <span className="half">
              {time.add(30, 'minutes').format('HH:mm')}
            </span>
          </>
        );
      })}
      <span>
        {moment().set('hour', maxHour).set('minute', 0).format('HH:mm')}
      </span>
    </div>
  );
};
