import { useEffect, useState } from 'react';
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import { SearchContacts } from './search-contacts';
import { IKontaktFull, IKontaktSearchResult } from 'types';
import { ConfirmationField } from 'components/common/confirmation-field';
import util from 'utils/util';

export interface IMigrationConfig {
  destinationId: number;
  updateContactInformation: boolean;
  deleteSource: boolean;
}

export interface IMigrationConfirmDialogProps {
  current?: IKontaktFull;
  onDismissed: () => unknown;
  onConfirm: (config: IMigrationConfig) => unknown;
  show?: boolean;
}

export function MigrationConfirmDialog({
  show,
  current,
  onDismissed,
  onConfirm,
}: IMigrationConfirmDialogProps) {
  const [deleteSource, setDeleteSource] = useState(false);
  const [updateContactInformation, setUpdateContactInformation] =
    useState(false);
  const [selectedContact, setSelectedContact] =
    useState<IKontaktSearchResult | null>(null);
  const [hasConfirmedDeletion, setHasConfirmedDeletion] = useState(false);

  useEffect(() => {
    setHasConfirmedDeletion(!deleteSource);
  }, [deleteSource]);

  function reset() {
    setDeleteSource(false);
    setUpdateContactInformation(false);
    setSelectedContact(null);
    setHasConfirmedDeletion(false);
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        reset();
        onDismissed();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Migrera kontakt</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Ange en kontakt du vill flytta projekt och beställningar till.
        <Form.Check
          type="checkbox"
          label="Ta bort kontakt efter migrering"
          value={String(deleteSource)}
          onChange={(e) => {
            setDeleteSource(e.target.checked);
          }}
        />
        <Form.Check
          type="checkbox"
          label="För över kontaktinformation"
          value={String(updateContactInformation)}
          onChange={(e) => {
            setUpdateContactInformation(e.target.checked);
          }}
        />
        <SearchContacts
          currentId={current?.id}
          onChange={(r) => setSelectedContact(r)}
        />
        {deleteSource && (
          <ConfirmationField
            style={{ marginTop: '1rem' }}
            code="ta bort"
            onChange={(confirmed) => setHasConfirmedDeletion(confirmed)}
          />
        )}
        {selectedContact && (
          <Alert variant="info">
            Alla projekt och beställningar för kontakt{' '}
            <strong>
              {util.getKontaktNameRemoveDuplicate(current)} ({current?.id})
            </strong>{' '}
            kommer att flyttas till{' '}
            <strong>
              {util.getKontaktNameRemoveDuplicate(selectedContact)} (
              {selectedContact?.id})
            </strong>
            {updateContactInformation && (
              <>
                <br />
                <span>Kontaktinformation kommer att skrivas över.</span>
              </>
            )}
          </Alert>
        )}
        {deleteSource && (
          <Alert variant="danger" style={{ margin: '1rem 0' }}>
            Kontakten{' '}
            <strong>
              {util.getKontaktNameRemoveDuplicate(current)} ({current?.id})
            </strong>{' '}
            kommer att tas bort
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={() => {
            reset();
            onDismissed();
          }}
        >
          Avbryt
        </Button>
        <Button
          disabled={!selectedContact || !hasConfirmedDeletion}
          variant={'info'}
          onClick={() => {
            if (selectedContact === null) {
              return;
            }
            onConfirm({
              destinationId: selectedContact.id || 0,
              updateContactInformation,
              deleteSource,
            });
            reset();
          }}
        >
          Migrera
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
