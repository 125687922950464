import { useState, useEffect, useCallback } from 'react';
import { doGet } from '../action/services';
import BackButton from '../common/back-button.component';
import Container from 'react-bootstrap/Container';
import { DropdownButton, Dropdown, Button, Alert } from 'react-bootstrap';
import { Trash, BoxArrowLeft } from 'react-bootstrap-icons';
import Kontaktuppgifter from './kontaktuppgifter.component';
import VisaBesiktning from '../projekt/visa-besiktning.component';
import VisaProjekt from '../projekt/visa-projekt.component';
import { RollKoll } from '../common/roll.component';
import { confirm } from 'utils/ui';
import styled from 'styled-components';
import { formatName } from 'utils/personal';
import {
  RadeaLoadingModal,
  RadeaLoadingModalState,
  IRadeaLoadingModalProps,
} from 'components/common/radea-loading-modal';
import { getAxiosInstance } from 'components/action/services';
import { useHistory } from 'react-router-dom';
import paths from 'constants/paths';
import { MigrationConfirmDialog } from './migration-confirm-dialog';
import { IKontaktFull } from 'types/kontakt';

const axios = getAxiosInstance();

const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const STATE_DEFAULT = 0,
  STATE_SKAPA_BESIKTNING = 1,
  STATE_VISA_BESIKTNING = 2,
  STATE_FLYTTA_TILL_KUND = 3,
  STATE_VISA_PROJEKT = 4,
  STATE_STARTA_PROJEKT = 5;

const VisaKontakt = (props) => {
  const { id } = props.match.params;
  const history = useHistory();

  const [loadingModalState, setLoadingModalState] =
    useState<IRadeaLoadingModalProps>({
      msg: 'Laddar...',
      state: RadeaLoadingModalState.loading,
      show: false,
    });
  const [activeProjektId, setActiveProjektId] = useState<number>(0);
  const [pageState, setPageState] = useState(STATE_DEFAULT);
  const [kontakt, setKontakt] = useState<IKontaktFull>({});
  const activeProjekt =
    kontakt?.projekt?.find((p) => p.id === activeProjektId) ?? null;
  const [showMigrationDialog, setShowMigrationDialog] = useState(false);

  const getKontakt = useCallback(async () => {
    const res = await doGet(`/kontakter/get/${id}`);
    setKontakt(res.data);
    setActiveProjektId(res.data?.projekt?.[0]?.id || 0);
  }, [id]);

  useEffect(() => {
    getKontakt();
  }, [getKontakt]);

  useEffect(() => {
    if (!activeProjekt && !activeProjektId && kontakt?.projekt?.[0]?.id) {
      setActiveProjektId(kontakt?.projekt?.[0].id);
    }
  }, [kontakt, activeProjektId, activeProjekt, setActiveProjektId]);

  useEffect(() => {
    if (activeProjekt !== null) {
      activeProjekt.ort = kontakt?.postort;
      setPageState(
        activeProjekt.flyttadTillKund
          ? STATE_VISA_PROJEKT
          : STATE_VISA_BESIKTNING
      );
    }
  }, [activeProjekt, setPageState, kontakt?.postort]);

  const determinePageState = () => {
    if (activeProjekt) {
      setPageState(
        activeProjekt.flyttadTillKund
          ? STATE_VISA_PROJEKT
          : STATE_VISA_BESIKTNING
      );
    } else {
      setPageState(STATE_DEFAULT);
    }
  };

  return (
    <>
      <Container>
        <RadeaLoadingModal
          {...loadingModalState}
          onHide={() =>
            setLoadingModalState({ ...loadingModalState, show: false })
          }
        />
        <ActionBar>
          <BackButton useBackUrl />
          <div>
            {kontakt?.id && (
              <Button
                variant="outline-info"
                className="icon-button"
                onClick={async () => {
                  setShowMigrationDialog(true);
                }}
                style={{ marginRight: '0.5rem' }}
              >
                <BoxArrowLeft />
                <span>Migrera kontakt</span>
              </Button>
            )}
            {kontakt?.id && (
              <Button
                variant="outline-danger"
                className="icon-button"
                onClick={async () => {
                  const shouldRemove = await confirm(
                    <>
                      Följande information för "{formatName(kontakt)}
                      {kontakt.foretagsnamn
                        ? `, ${kontakt.foretagsnamn}`
                        : ''}{' '}
                      " kommer att tas bort och går inte att återställa:
                      <ul>
                        <li>Kontakt</li>
                        <li>Projekt</li>
                        <li>Beställningar av mätpaket</li>
                        <li>Tidsbokningar</li>
                        <li>Orderbekräftelser</li>
                        <li>Aktiviteter</li>
                      </ul>
                      <Alert variant="info">
                        Notera att information som skickats till Fortnox eller
                        Radonova måste tas bort i respektive system
                      </Alert>
                    </>,
                    {
                      title: 'Ta bort kontakt',
                      confirmText: 'Ta bort',
                      cancelText: 'Avbryt',
                      variant: 'danger',
                      confirmationCode: 'ta bort',
                    }
                  );
                  if (!shouldRemove) {
                    return;
                  }
                  setLoadingModalState({
                    msg: 'Tar bort kontakt...',
                    show: true,
                    state: RadeaLoadingModalState.loading,
                  });
                  try {
                    await axios.delete(`/kontakter/${kontakt.id}`);
                    await setLoadingModalState({
                      msg: 'Kontakten har blivit borttagen',
                      show: true,
                      state: RadeaLoadingModalState.success,
                    });
                    setTimeout(() => {
                      history.push(paths.START);
                    }, 1500);
                  } catch (error) {
                    setLoadingModalState({
                      msg: 'Kunde inte ta bort kontakten, något gick fel',
                      show: true,
                      state: RadeaLoadingModalState.error,
                      showCloseButton: true,
                    });
                  }
                }}
              >
                <Trash />
                <span>Ta bort kontakt</span>
              </Button>
            )}
          </div>
        </ActionBar>
        <RollKoll role={['admin', 'affärsAdmin']} msg={false}>
          <Kontaktuppgifter
            kontakt={kontakt}
            refresh={() => {
              getKontakt();
            }}
          />
        </RollKoll>
      </Container>
      <MigrationConfirmDialog
        current={kontakt}
        show={showMigrationDialog}
        onDismissed={() => setShowMigrationDialog(false)}
        onConfirm={async (options) => {
          setShowMigrationDialog(false);
          setLoadingModalState({
            msg: 'Migrerar kontakt...',
            show: true,
            state: RadeaLoadingModalState.loading,
          });
          try {
            await axios.post(`/kontakter/${kontakt?.id}/migrate`, options);
            setLoadingModalState({
              msg: 'Kontakten har migrerad',
              show: true,
              state: RadeaLoadingModalState.success,
            });
            setTimeout(() => {
              history.push(paths.START);
            }, 1500);
          } catch (error) {
            setLoadingModalState({
              msg: 'Kunder inte migrera kontakten, något gick fel',
              show: true,
              state: RadeaLoadingModalState.error,
              showCloseButton: true,
            });
          }
        }}
      />
      <RollKoll role={['admin', 'affärsAdmin']} msg={true}>
        <Container className="bottom-item button-row">
          {kontakt?.projekt && (
            <DropdownButton
              key={'projekt'}
              id={'projectDropdown'}
              variant={'primary'}
              title={`Tillgängliga projekt: ${kontakt?.projekt.length}`}
              onSelect={(selected) => {
                setActiveProjektId(parseInt(selected || '0', 10));
              }}
            >
              {kontakt?.projekt.length > 0 &&
                kontakt?.projekt.map((p) => {
                  return (
                    <Dropdown.Item key={p.id} eventKey={p.id}>
                      {p.friendlyName ? `${p.friendlyName} - ` : `${p.namn === '' ? 'Projekt' : p.namn} ${p.id} - `}
                      {p.coldcase
                        ? 'Coldcase'
                        : p.avslutat
                        ? 'Avslutat'
                        : 'Pågående'}
                    </Dropdown.Item>
                  );
                })}
            </DropdownButton>
          )}
          <span>
            {pageState !== STATE_FLYTTA_TILL_KUND && (
              <Button
                onClick={() => {
                  setPageState(STATE_SKAPA_BESIKTNING);
                }}
                className="radea-button"
              >
                Boka ny besiktning
              </Button>
            )}
            {pageState !== STATE_FLYTTA_TILL_KUND &&
              activeProjekt !== null &&
              activeProjekt.flyttadTillKund === 0 && (
                <Button
                  onClick={() => {
                    setPageState(STATE_FLYTTA_TILL_KUND);
                  }}
                  className="radea-button"
                >
                  Gör till kund
                </Button>
              )}
            {pageState !== STATE_FLYTTA_TILL_KUND &&
              activeProjekt !== null &&
              (activeProjekt.flyttadTillKund || 0) > 0 && (
                <Button
                  onClick={() => {
                    setPageState(STATE_STARTA_PROJEKT);
                  }}
                  className="radea-button"
                >
                  Starta nytt projekt
                </Button>
              )}
            {pageState !== STATE_FLYTTA_TILL_KUND && activeProjekt === null && (
              <Button
                onClick={() => {
                  setPageState(STATE_FLYTTA_TILL_KUND);
                }}
                className="radea-button"
              >
                Starta projekt
              </Button>
            )}
          </span>
        </Container>
        <Container className="bottom-container">
          {pageState === STATE_VISA_PROJEKT && (
            <VisaProjekt
              kontakt={kontakt}
              projekt={activeProjekt}
              refresh={() => {
                getKontakt();
              }}
              cancel={() => null}
              flyttaTillKund={false}
            />
          )}
          {pageState === STATE_FLYTTA_TILL_KUND && activeProjekt === null && (
            <VisaProjekt
              kontakt={kontakt}
              projekt={{ kontaktId: kontakt?.id, id: 0 }}
              refresh={() => {
                getKontakt();
              }}
              flyttaTillKund={true}
              cancel={() => {
                determinePageState();
              }}
            />
          )}
          {pageState === STATE_FLYTTA_TILL_KUND && activeProjekt !== null && (
            <VisaProjekt
              kontakt={kontakt}
              projekt={activeProjekt}
              refresh={() => {
                getKontakt();
              }}
              flyttaTillKund={true}
              cancel={() => {
                determinePageState();
              }}
            />
          )}
          {pageState === STATE_STARTA_PROJEKT && (
            <VisaProjekt
              kontakt={kontakt}
              projekt={{ kontaktId: kontakt?.id, id: 0 }}
              refresh={() => {
                getKontakt();
              }}
              cancel={() => {
                determinePageState();
              }}
              flyttaTillKund={true}
            />
          )}
          {pageState === STATE_VISA_BESIKTNING && (
            <VisaBesiktning
              kontakt={kontakt}
              projekt={activeProjekt}
              refresh={() => {
                getKontakt();
              }}
            />
          )}
          {pageState === STATE_SKAPA_BESIKTNING && (
            <VisaBesiktning
              projekt={{ kontaktId: kontakt?.id }}
              create={true}
              refresh={() => {
                getKontakt();
              }}
              cancel={() => {
                determinePageState();
              }}
            />
          )}
        </Container>
      </RollKoll>
    </>
  );
};

export default VisaKontakt;
