import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import Fakturera from './fakturera.component';
import Ordrar from './ordrar.component';
import { RollKoll } from '../common/roll.component';
import BackButton from '../common/back-button.component';

const STATE_FAKTURERA = 0,
  STATE_ORDRAR = 1;

const FakturaMeny = ({ location }) => {
  const [active, setActive] = useState(STATE_FAKTURERA);

  return (
    <>
      <BackButton />
      <RollKoll role={['admin', 'affärsAdmin']} msg={true}>
        <h2>Order och Fakturering</h2>
        <Nav className="margin-top" variant="tabs" defaultActiveKey={active}>
          <Nav.Item>
            <Nav.Link
              eventKey={STATE_FAKTURERA}
              onClick={(e) => {
                setActive(STATE_FAKTURERA);
              }}
            >
              Skicka fakturor
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey={STATE_ORDRAR}
              onClick={(e) => {
                setActive(STATE_ORDRAR);
              }}
            >
              Hantera ordrar
            </Nav.Link>
          </Nav.Item>
        </Nav>
        {active === STATE_FAKTURERA && (
          <>
            <Fakturera />
          </>
        )}
        {active === STATE_ORDRAR && (
          <>
            <Ordrar />
          </>
        )}
      </RollKoll>
    </>
  );
};

export default FakturaMeny;
