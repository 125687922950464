import React, { useState, useEffect, useCallback } from 'react';
import util from 'utils/util';
import { Form, ListGroup } from 'react-bootstrap';
import { Plus } from 'react-bootstrap-icons';
import { usePersonalList } from '../../hooks/usePersonalList';
import { IPersonal, RoleType } from 'types';
import { formatName } from 'utils/personal';

export const getPersonalForRoll = (
  rollList: RoleType | RoleType[],
  personalList: IPersonal[]
) => {
  rollList = Array.isArray(rollList) ? rollList : [rollList];

  return personalList.filter((personal) =>
    personal.roller.some((rollItem) => rollList.includes(rollItem.typ))
  );
};

export const getPersonalAktiv = (personalList: IPersonal[]) => {
  let personalAktiv: IPersonal[] = [];
  for (let personal of personalList) {
    if (personal.aktiv) {
      personalAktiv.push(personal);
    }
  }
  return personalAktiv;
};

export const getPersonalById = (id, personalList) => {
  if (!personalList) {
    return null;
  }
  for (let p of personalList) {
    if (p.id === parseInt(id)) {
      return p;
    }
  }
};

export const PersonalIdCard = (props) => {
  const { personalId } = props;
  const personalList = usePersonalList();

  let personal = getPersonalById(personalId, personalList);
  return <PersonalCard personal={personal} />;
};

export const PersonalCard = (props) => {
  const { personal } = props;
  return <span>{util.getPersonalName(personal)}</span>;
};

interface ICommonPersonalSelectFormGroupProps {
  selectedId?: number; // selected personal
  aktiva?: boolean;
  roll?: RoleType | RoleType[];
  label?: string;
  small?: boolean;
  strong?: boolean;
}

type PersonalSelectFormGroupEventProps =
  | {
      full: true;
      registerSelected: (personal: IPersonal) => unknown;
    }
  | {
      full?: false;
      registerSelected: (
        personal: React.ChangeEvent<HTMLSelectElement>
      ) => unknown;
    };

export type IPersonalSelectFormGroupProps =
  ICommonPersonalSelectFormGroupProps & PersonalSelectFormGroupEventProps;

export function PersonalSelectFormGroup({
  selectedId,
  aktiva,
  roll,
  full,
  label,
  small,
  strong,
  registerSelected,
}: IPersonalSelectFormGroupProps) {
  const [list, setList] = useState<IPersonal[]>([]);
  const [selected, setSelected] = useState(selectedId);
  const personalList = usePersonalList();

  const filterPersonalList = useCallback(() => {
    if (roll) {
      setList(getPersonalForRoll(roll, personalList));
    } else if (aktiva) {
      setList(getPersonalAktiv(personalList));
    } else {
      setList(personalList);
    }
  }, [setList, personalList, aktiva, roll]);

  useEffect(() => {
    if (personalList && personalList.length > 0) {
      filterPersonalList();
    }
  }, [personalList, filterPersonalList]);

  useEffect(() => {
    setSelected(selectedId);
  }, [selectedId]);

  const personalSelect: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    if (full) {
      registerSelected(getPersonalById(e.target.value, personalList));
    } else {
      registerSelected(e);
    }
  };

  return (
    <>
      <Form.Label>
        {strong && <strong>{label}</strong>}
        {!strong && <>{label}</>}
      </Form.Label>
      <Form.Control
        as="select"
        size={small ? 'sm' : undefined}
        value={selected}
        onChange={personalSelect}
        custom
      >
        <option>Välj personal</option>
        {list.map((data, i) => {
          return (
            <option key={i} value={data.id}>
              {util.getPersonalName(data)}
            </option>
          );
        })}
      </Form.Control>
    </>
  );
}

export interface IPersonalRollListProps {
  roller: RoleType[];
  registerSelected: (personal: IPersonal) => unknown;
}

export const PersonalRollList = ({
  roller,
  registerSelected,
}: IPersonalRollListProps) => {
  const personalList = usePersonalList();
  const [personalRollList, setPersonalRollList] = useState<IPersonal[]>([]);
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    setPersonalRollList(getPersonalForRoll(roller, personalList));
  }, [roller, personalList]);

  return (
    <ListGroup variant="flush" className="personal-lista">
      {personalRollList.map((personal, i) => {
        return (
          <ListGroup.Item
            onClick={() => {
              setSelected(personal.id || 0);
              registerSelected(personal);
            }}
            key={`grp${i}`}
            className={`click-item ${
              selected === personal.id ? 'selected' : ''
            }`}
          >
            <span>{formatName(personal)}</span>
            <Plus size={28} />
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
};

export default {
  PersonalSelectFormGroup,
  PersonalCard,
  PersonalIdCard,
  PersonalRollList,
  getPersonalById,
};
