import moment, { Moment, MomentInput } from 'moment-timezone';
import { ITidsbokning } from 'types';

export interface IPartialEvent
  extends Pick<ITidsbokning, 'starttid' | 'sluttid'> {
  heldag: number | boolean;
}

export function isRangeEvent(event: IPartialEvent) {
  if (!event.sluttid) {
    return false;
  }

  if (event.heldag) {
    return true;
  }

  if (moment(event.sluttid).isSame(moment(event.starttid), 'day')) {
    return false;
  }

  return true;
}

export function overlapsWeek(event: IPartialEvent, week: MomentInput[]) {
  if (
    week.some((day) => moment(day).isBetween(event.starttid, event.sluttid))
  ) {
    return true;
  }
  if (
    week.some(
      (day) =>
        moment(day).isSame(event.starttid, 'day') ||
        moment(day).isSame(event.sluttid, 'day')
    )
  ) {
    return true;
  }
}

export function isPartOfWeek(
  dateA: MomentInput,
  dateB: MomentInput,
  week?: MomentInput[]
) {
  let weekDays: Moment[] = week?.map((d) => moment(d)) || [];

  if (!week || weekDays.length === 0) {
    weekDays = [];
    const weekStart = moment(dateA).startOf('isoWeek');
    for (let i = 0; i < 5; i++) {
      weekDays.push(moment(weekStart).add(i, 'days'));
    }
  }

  return (
    weekDays.some((day) => day.isSame(moment(dateA), 'day')) &&
    weekDays.some((day) => day.isSame(moment(dateB), 'day'))
  );
}

export function isSameWorkWeek(dateA: MomentInput, dateB: MomentInput) {
  const weekStart = moment(dateA).startOf('isoWeek');
  const daysDiffA = moment(dateA).diff(weekStart, 'days');
  const daysDiffB = moment(dateB).diff(weekStart, 'days');

  return daysDiffB >= 0 && daysDiffB <= 4 && daysDiffA <= 4;
}

export function hasPassed(event: IPartialEvent, date?: MomentInput) {
  const hasPassedEndTime = moment(event.sluttid).isBefore(
    moment(),
    event.heldag ? 'day' : undefined
  );
  if (hasPassedEndTime) {
    return true;
  }

  if (isRangeEvent(event) && !event.heldag) {
    return moment(date).isBefore(moment(), 'day');
  }

  return false;
}
