import React, { useState, useEffect, useCallback, useContext } from 'react';
import { doGetList } from '../action/services';
import util from 'utils/util';
import { Form, Col } from 'react-bootstrap';
import RadeaCalendar from 'components/common/calendar';
import Veckodetaljer from './veckodetaljer.component';
import { PersonalSelectFormGroup } from '../common/personal.component';
import { GlobalStateContext } from '../../global.context';
import { usePersonalList } from 'hooks/usePersonalList';
import { useQueryParameter } from 'hooks/useQuery';
import paths from 'constants/paths';
import { buildPath } from 'utils/path';
import moment from 'moment';

const PERSON_PARAM = 'personId';
const MONDAY_PARAM = 'date';

const Scheman = (props) => {
  const personalList = usePersonalList();
  const [events, setEvents] = useState([]);
  const { session } = useContext(GlobalStateContext);

  const [selectedPersonId, setSelectedPersonId] = useQueryParameter<number>(
    PERSON_PARAM,
    session?.id ?? 0,
    true,
    true
  );

  const [monday, setMonday] = useQueryParameter(
    MONDAY_PARAM,
    util.formatDate(moment().startOf('week').toDate()),
    false,
    true
  );

  const [veckodetaljer, setVeckodetaljer] = useState([]);
  const [loading, setLoading] = useState(false);

  const getTidsbokningar = useCallback(async () => {
    const [res, general] = await Promise.all([
      doGetList(`/tidsbokningar/personal/${selectedPersonId}?date=${monday}`),
      doGetList(`/tidsbokningar/general`),
    ]);
    setEvents(res.data.concat(general?.data || []));
  }, [selectedPersonId, setEvents, monday]);

  const getVeckodetaljer = useCallback(async () => {
    if (selectedPersonId > 0) {
      setLoading(true);
      const res = await doGetList(
        `/tidsbokningar/veckodetaljer/${selectedPersonId}/${monday}`
      );

      setVeckodetaljer(res.data);
      setLoading(false);
    }
  }, [selectedPersonId, monday, setVeckodetaljer]);

  useEffect(() => {
    if (selectedPersonId > 0) {
      getTidsbokningar();
      getVeckodetaljer();
    }
  }, [selectedPersonId, getTidsbokningar, getVeckodetaljer]);

  useEffect(() => {
    if (monday !== null) {
      getVeckodetaljer();
    }
  }, [monday, getVeckodetaljer]);

  useEffect(() => {
    if (session && session.id && !selectedPersonId) {
      setSelectedPersonId(session.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  const selectedPerson =
    personalList.find((p) => p.id === selectedPersonId) || null;

  return (
    <>
      <Form>
        <Form.Row>
          <Form.Group className={'margin-top'} as={Col} controlId="personal">
            <PersonalSelectFormGroup
              label={'Välj schema'}
              selectedId={selectedPersonId}
              aktiva={true}
              registerSelected={(p) => {
                setSelectedPersonId(p?.id ?? 0);
              }}
              full={true}
              strong={true}
            />
          </Form.Group>
        </Form.Row>
      </Form>
      {selectedPersonId != null && (
        <>
          <RadeaCalendar
            key={util.formatDate(monday)}
            selectedMonday={moment(monday).toDate()}
            events={events}
            personal={selectedPerson}
            offHours={false}
            showDatePicker
            eventDate={null}
            registerDate={(date) => {
              setMonday(moment(date).startOf('isoWeek').toISOString());
            }}
            registerValidation={() => {
              console.log('Validation');
            }}
            registerWeekChange={(monday) => {
              setMonday(util.formatDate(monday));
            }}
            bookable={false}
            loading={loading}
          />
          <Veckodetaljer
            list={veckodetaljer}
            loading={loading}
            week={util.getWeekArray(monday).map((d) => moment(d))}
            backUrl={buildPath(paths.SCHUEDULES)
              .query({
                personId: String(selectedPersonId),
                date: monday,
              })
              .get()}
          />
        </>
      )}
    </>
  );
};

export default Scheman;
