import { ITidsbokingWrite, ITidsbokning } from 'types/tidsbokning';
import moment from 'moment';
import { isRangeEvent } from 'components/common/calendar/utils';

export function checkForConflicts(
  newBooking: ITidsbokingWrite,
  existingBookings: ITidsbokning[]
): ITidsbokning[] {
  const conflicts = existingBookings.filter(
    (booking) =>
      (newBooking.personalId === booking?.personalId || booking.general) &&
      overlaps(booking, newBooking, false)
  );
  return conflicts;
}

export function maxOverlaps(
  booking: ITidsbokning,
  otherBookings: ITidsbokning[],
  overlapCache: {
    [key: string]: {
      [key: string]: boolean;
    };
  },
  updateCache: (idA?: number, idB?: number, overlap?: boolean) => unknown
): ITidsbokning[] {
  const conflicts = otherBookings
    .filter((ob) => ob.id === booking.id || overlaps(booking, ob))
    .filter((ob) => !isRangeEvent(ob));

  function helper(
    current: ITidsbokning[],
    booking: ITidsbokning,
    bookings: ITidsbokning[]
  ): ITidsbokning[] {
    if (bookings.length === 0) {
      return current;
    }
    let isOverlapping =
      overlapCache[String(booking.id)][String(bookings[0].id)] ??
      overlaps(booking, bookings[0]);
    updateCache(booking.id, bookings[0].id, isOverlapping);
    if (isOverlapping && !current.find((b) => b.id === bookings[0].id)) {
      return helper(
        current.concat(bookings[0]),
        bookings[0],
        bookings.slice(1)
      );
    }

    return helper(current, bookings[0], bookings.slice(1));
  }

  let max = helper([], booking, conflicts);

  return max;
}

export function extraWidth(
  booking: ITidsbokning,
  max: ITidsbokning[],
  conflictMapping: { [key: string]: ITidsbokning[] }
) {
  const otherOverlaps = max
    .filter((e) => e.id !== booking.id)
    .map((e) => conflictMapping[e.id || 'undefined']?.length || 0);

  if (
    otherOverlaps.length === 0 ||
    otherOverlaps.some((o) => o <= max.length)
  ) {
    return 0;
  }

  return (
    100 / max.length -
    100 /
      otherOverlaps.reduce(
        (prev, curr) => (curr < prev ? curr : prev),
        Infinity
      )
  );
}

export function overlaps(
  bookingA: ITidsbokning,
  bookingB: ITidsbokning,
  inclusive?: boolean
) {
  const inclusivity =
    inclusive || bookingA.heldag || bookingB.heldag ? '[]' : '()';
  if (
    moment(bookingA.starttid).isSame(bookingB.starttid) &&
    moment(bookingA.sluttid).isSame(bookingB.sluttid)
  ) {
    return true;
  }
  if (
    moment(bookingA.starttid).isBetween(
      bookingB.starttid,
      bookingB.sluttid,
      bookingB.heldag ? 'day' : undefined,
      inclusivity
    )
  ) {
    return true;
  }

  if (
    moment(bookingA.sluttid).isBetween(
      bookingB.starttid,
      bookingB.sluttid,
      bookingB.heldag ? 'day' : undefined,
      inclusivity
    )
  ) {
    return true;
  }

  if (
    moment(bookingB.starttid).isBetween(
      bookingA.starttid,
      bookingA.sluttid,
      bookingA.heldag ? 'day' : undefined,
      inclusivity
    )
  ) {
    return true;
  }

  if (
    moment(bookingB.sluttid).isBetween(
      bookingA.starttid,
      bookingA.sluttid,
      bookingA.heldag ? 'day' : undefined,
      inclusivity
    )
  ) {
    return true;
  }

  return false;
}
