import { Container } from 'react-bootstrap';
import moment, { Moment } from 'moment-timezone';
import 'moment/locale/sv';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import Fade from 'react-bootstrap/Fade';
import { isRangeEvent, overlapsWeek } from './utils';
import { CalendarHourGrid } from './calendar-hour-grid';
import { ITidsbokning } from 'types/tidsbokning';
import { RadeaDay } from './calendar-day';

const SpinnerContainer = styled.div<{ show?: boolean }>`
  pointer-events: ${({ show }) => (show ? 'all' : 'none')};
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background: rgba(255, 255, 255, 0.65);
`;

export interface ICalendarBaseProps {
  events: ITidsbokning[];
  eventDate: null | ITidsbokning;
  week: Date[];
  registerDate: (date: string | null) => unknown;
  validateSlot?: (
    start: Moment,
    langd: number,
    isRangeEvent: boolean
  ) => boolean;
  offHours?: boolean;
  bookable?: boolean;
  loading?: boolean;
}

function CalendarBase({
  validateSlot,
  events,
  week,
  offHours,
  eventDate,
  registerDate,
  bookable,
  loading,
}: ICalendarBaseProps) {
  const rangeEvents = events
    .concat(eventDate || [])
    .filter((e) => isRangeEvent(e) && overlapsWeek(e, week));

  return (
    <>
      <Container className={'calendar-container'}>
        <Fade in={loading}>
          <SpinnerContainer show={loading}>
            <Spinner animation="border" />
          </SpinnerContainer>
        </Fade>
        <CalendarHourGrid rangeEvents={rangeEvents} />
        {week.map((day, daySlot) => {
          const tempDay = moment(day);
          const dayEvents = events.filter(
            (e) =>
              moment(e.starttid).isSame(tempDay, 'day') ||
              (e.sluttid &&
                daySlot === 0 &&
                (moment(e.sluttid).isSame(tempDay, 'day') ||
                  moment(tempDay).isBetween(
                    moment(e.starttid),
                    moment(e.sluttid)
                  )))
          );
          dayEvents.sort((eventA, eventB) => {
            if (moment(eventA.starttid).isSame(eventB.starttid)) {
              return moment(eventA.sluttid).isBefore(eventB.sluttid) ? 1 : -1;
            }
            return moment(eventA.starttid).isBefore(eventB.starttid) ? -1 : 1;
          });

          const eventDateIsSameDay =
            eventDate?.starttid &&
            moment(eventDate.starttid).isSame(tempDay, 'day');

          if (eventDateIsSameDay) {
            dayEvents.push(eventDate);
          }

          return (
            <RadeaDay
              key={`day${daySlot * daySlot}`}
              date={tempDay}
              registerDate={registerDate}
              offHours={offHours}
              bookable={bookable}
              rangeEvents={rangeEvents}
              week={week}
              events={dayEvents}
              validateSlot={validateSlot}
              eventDate={eventDateIsSameDay ? eventDate : null}
            />
          );
        })}
      </Container>
    </>
  );
}

export default CalendarBase;
