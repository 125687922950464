import { Table, Accordion, Card } from 'react-bootstrap';
import { IFortnoxArticle } from 'types';
import { Trash, PlusCircle, PencilSquare } from 'react-bootstrap-icons';
import { themeColors } from 'constants/colors';

export interface IInfoCardProps {
  articles: IFortnoxArticle[];
  type: 'create' | 'update' | 'remove';
  eventKey: string;
}

export function InfoCard({ articles, type, eventKey }: IInfoCardProps) {
  if (!articles || articles.length === 0) {
    return null;
  }

  let icon = <Trash color={themeColors.danger} />;
  let text = 'För borttagning';

  switch (type) {
    case 'create': {
      text = 'Nya att skapa';
      icon = <PlusCircle color={themeColors.success} />;
      break;
    }
    case 'update': {
      text = 'Uppdaterade';
      icon = <PencilSquare color={themeColors.warning} />;
      break;
    }
  }

  return (
    <Card>
      <Accordion.Toggle
        as={Card.Header}
        eventKey={eventKey}
        style={{ cursor: 'pointer' }}
      >
        {icon} {text} ({articles.length})
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={eventKey} style={{ padding: '0 17px' }}>
        <Table hover striped size="sm">
          <thead>
            <tr>
              <td>Artikelnummer</td>
              <td>Standardpris</td>
              <td>Artikelbeskrivning</td>
              <td>Konto</td>
              <td>Moms</td>
              <td>Aktiv</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {articles.map((data, i) => {
              return (
                <tr key={data.ArticleNumber}>
                  <td>
                    <p>{data.ArticleNumber}</p>
                  </td>
                  <td>{data.SalesPrice}</td>
                  <td>{data.Description}</td>
                  <td>{data.SalesAccount} </td>
                  <td>{data.VAT}</td>
                  <td>
                    <p>{data.Active ? 'Ja' : 'Nej'}</p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Accordion.Collapse>
    </Card>
  );
}

export default InfoCard;
