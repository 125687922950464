import { useState } from 'react';
import moment, { Moment } from 'moment-timezone';
import 'moment/locale/sv';
import RadeaEvent from './calendar-event';
import { ITidsbokning } from 'types/tidsbokning';
import { maxOverlaps } from 'utils/tidsbokning';

export interface IRadeaDayProps {
  events: ITidsbokning[];
  date: Moment;
  week: Date[];
  registerDate: (date: string | null) => unknown;
  rangeEvents: ITidsbokning[];
  offHours?: boolean;
  bookable?: boolean;
  validateSlot?: (
    start: Moment,
    langd: number,
    isRangeEvent: boolean
  ) => boolean;
  eventDate: null | ITidsbokning;
}

export function RadeaDay({
  events,
  date,
  validateSlot,
  registerDate,
  offHours,
  bookable,
  rangeEvents,
  eventDate,
  week,
}: IRadeaDayProps) {
  const [lastTimeslot, setLastTimeslot] = useState<string | null>(null);
  const [hoverEvent, setHoverEvent] = useState<ITidsbokning | null>(null);

  const getYClickTime = (e, date) => {
    const rect = e.target.getBoundingClientRect();
    const y = e.clientY - rect.top - rangeEvents.length * 18;
    const pos = Math.floor(y / 18);

    if (
      (!offHours && (pos === 0 || pos === 1 || (pos >= 20 && pos <= 22))) ||
      pos < 0
    ) {
      return null;
    }
    const hour = 7 + Math.floor(pos / 2);
    const minute = (pos % 2) * 30;
    return moment(date).set('hour', hour).set('minute', minute).toISOString();
  };

  const createHoverEvent = (starttid: string | null) => {
    if (bookable) {
      if (starttid !== null) {
        setHoverEvent({
          typ: 0,
          langd: eventDate?.langd ?? 30,
          starttid,
          info: '',
          ort: '',
          heldag: false,
        });
      } else {
        cancelHoverEvent();
      }
    }
  };
  const cancelHoverEvent = () => {
    setHoverEvent(null);
  };

  const overlapCache: {
    [key: string]: {
      [key: string]: boolean;
    };
  } = events.reduce((prev, curr) => {
    prev[String(curr.id)] = {};
    return prev;
  }, {});

  const conflicts: { [key: string]: ITidsbokning[] } = events.reduce(
    (prev, curr) => {
      prev[curr.id || 'undefined'] = maxOverlaps(
        curr,
        events,
        overlapCache,
        (idA, idB, overlaps) => {
          overlapCache[String(idA)][String(idB)] = overlaps ?? false;
          overlapCache[String(idB)][String(idA)] = overlaps ?? false;
        }
      );
      return prev;
    },
    {}
  );

  return (
    <div className={`calendar-day`}>
      <span className="heading">
        {date.format('dddd')} {date.format('D/M')}
      </span>
      {(moment(date).isSame(moment(), 'day') ||
        moment(date).isAfter(moment(), 'day')) && (
        <span
          className={`hover-area ${hoverEvent ? 'active' : ''}`}
          onClick={(e) => {
            if (hoverEvent) {
              registerDate(getYClickTime(e, date));
            }
          }}
          onMouseMove={(e) => {
            const time = getYClickTime(e, date);
            if (time === lastTimeslot) {
              return;
            }
            setLastTimeslot(time);
            createHoverEvent(time);
          }}
          onMouseOut={() => {
            cancelHoverEvent();
          }}
        ></span>
      )}
      {events.map((event, i) => {
        return (
          <RadeaEvent
            week={week}
            currentDate={date}
            key={`event${i * i}`}
            data={event}
            rangeEvents={rangeEvents}
            newEvent={!event.id}
            validateSlot={event.id ? undefined : validateSlot}
            allConflicts={conflicts}
          />
        );
      })}
      {hoverEvent && (
        <RadeaEvent
          week={week}
          data={hoverEvent}
          hoverEvent={true}
          rangeEvents={rangeEvents}
          allConflicts={conflicts}
        />
      )}
    </div>
  );
}
