import { Form, Col } from 'react-bootstrap';
import { ArrowDownSquareFill } from 'react-bootstrap-icons';
import DatePicker, { registerLocale } from 'react-datepicker';
import sv from 'date-fns/locale/sv';
import util from 'utils/util';
import { PersonalSelectFormGroup } from 'components/common/personal.component';
import { MalltexterSelectFormGroup } from 'components/common/malltexter.component';
import { FieldType, IOrder } from 'types';
import { OrderChanges } from './reducer';
import { generateChangeStyleFunction } from './utils';

registerLocale('sv', sv);

export interface OrderbekraftelseFormProps {
  data: IOrder;
  onChange: (field: keyof IOrder, value: any, type?: FieldType) => unknown;
  changes?: OrderChanges;
}

export const OrderbekraftelseForm = ({
  data,
  onChange,
  changes,
}: OrderbekraftelseFormProps) => {
  const eventUpdateFormData = (
    type: FieldType = 'text'
  ): React.ChangeEventHandler<any> => {
    return (e) => {
      onChange(e.target.id, e.target.value, type);
    };
  };

  const addChangeStyles = generateChangeStyleFunction<OrderChanges>(changes);

  return (
    <>
      <Form.Row>
        <Form.Group
          as={Col}
          controlId="bolag"
          className={addChangeStyles('bolag', 'select')}
        >
          <Form.Label>Avsändare</Form.Label>
          <Form.Control
            as="select"
            value={data.bolag}
            onChange={eventUpdateFormData('select')}
            custom
          >
            {util.bolag.map((data, i) => {
              return (
                <option key={`bolag${i}`} value={data.id}>
                  {data.namn}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>
        <Form.Group
          as={Col}
          controlId="orderdatum"
          className={addChangeStyles('orderdatum', 'date')}
        >
          <Form.Label>Orderdatum</Form.Label>
          <div className="input-wrapper">
            <Form.Control
              readOnly
              value={util.getDateObject(data.orderdatum, true).str}
              onChange={eventUpdateFormData()}
            />
            <DatePicker
              popperPlacement="left"
              locale="sv"
              selected={data.orderdatum ? new Date(data.orderdatum) : null}
              onChange={(date) => onChange('orderdatum', date, 'date')}
            />
          </div>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          controlId="varReferens"
          className={addChangeStyles('varReferens', 'select')}
        >
          <PersonalSelectFormGroup
            label={'Vår referens'}
            selectedId={data.varReferens}
            aktiva={true}
            registerSelected={eventUpdateFormData('select')}
          />
        </Form.Group>
        <Form.Group
          as={Col}
          controlId="leveransdatum"
          className={addChangeStyles('leveransdatum', 'date')}
        >
          <Form.Label>Leveransdatum</Form.Label>
          <div className="input-wrapper">
            <Form.Control
              readOnly
              value={util.getDateObject(data.leveransdatum, true).str}
            />
            <DatePicker
              popperPlacement="left"
              locale="sv"
              selected={
                data.leveransdatum ? new Date(data.leveransdatum) : null
              }
              onChange={(date) => onChange('leveransdatum', date, 'date')}
            />
          </div>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="fastighet" className={'col-6'}>
          <Form.Label>Fastighetsbeteckning</Form.Label>
          <Form.Control readOnly placeholder="" value={data.fastighet} />
        </Form.Group>
        <Form.Group as={Col} controlId="malltext" className={'col-6'}>
          <Form.Label>Mallar, orderbeskrivning</Form.Label>
          <div className="input-wrapper">
            <MalltexterSelectFormGroup
              registerMalltext={(txt) => {
                onChange('orderbeskrivning', txt);
              }}
              typ={2}
            />
            <ArrowDownSquareFill
              color={'#ddd'}
              size={30}
              style={{ margin: '4px' }}
            />
          </div>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <div className={'form-row-col col-6'}>
          <Form.Group
            as={Col}
            controlId="betalningsvillkor"
            className={addChangeStyles('betalningsvillkor')}
          >
            <Form.Label>Betalningsvillkor (dagar)</Form.Label>
            <Form.Control
              placeholder="Fyll i betalningsvillkor"
              value={data.betalningsvillkor}
              onChange={eventUpdateFormData()}
              autoComplete="off"
            />
          </Form.Group>
          <Form.Group
            as={Col}
            controlId="efaktura"
            className={`row-direction${addChangeStyles('efaktura')}`}
          >
            <Form.Check
              type="checkbox"
              checked={data.efaktura}
              value={data.efaktura.toString()}
              onChange={(e) => {
                onChange('efaktura', e.target.value === 'false' ? true : false);
              }}
            />
            <Form.Label>E-Faktura</Form.Label>
          </Form.Group>
          <Form.Group
            as={Col}
            controlId="orgnummer"
            className={addChangeStyles('orgnummer', 'select')}
          >
            <Form.Label>Org. nummer</Form.Label>
            <Form.Control
              placeholder="Fyll i orgnummer för E-faktura"
              value={data.orgnummer}
              onChange={eventUpdateFormData()}
              autoComplete="off"
            />
          </Form.Group>
        </div>
        <Form.Group
          as={Col}
          controlId="orderbeskrivning"
          className={`col-6${addChangeStyles('orderbeskrivning', 'select')}`}
        >
          <Form.Label>Orderbeskrivning</Form.Label>
          <Form.Control
            as="textarea"
            rows={8}
            placeholder="Fyll i orderbeskrivning"
            value={data.orderbeskrivning}
            onChange={eventUpdateFormData()}
          />
        </Form.Group>
      </Form.Row>
    </>
  );
};
