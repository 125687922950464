import { useEffect, useState } from 'react';
import { getAxiosInstance } from '../action/services';
import { Card, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatName } from 'utils/personal';
import { IEgenkontroll } from 'types/egenkontroll';
import RadeaEditor from 'components/common/radea-editor';
import moment from 'moment';
import SimpleGallery from 'components/common/SimpleGallery';
import { buildPath } from 'utils/path';
import paths from 'constants/paths';
import { useScreenSize } from 'hooks/useScreenSize';

const axios = getAxiosInstance();

export interface IEgenkontrollProps {
  projektId: number;
  hideCreateButton?: boolean;
}

export function EgenkontrollList({
  projektId,
  hideCreateButton,
}: IEgenkontrollProps) {
  const screenSize = useScreenSize();
  const [list, setList] = useState<IEgenkontroll[]>([]);

  useEffect(() => {
    axios
      .get<IEgenkontroll[]>(`/projekt/${projektId}/egenkontroll`)
      .then((r) => {
        setList(r.data);
      });
  }, [projektId]);

  if (screenSize.width < 900) {
    return (
      <>
        {list.map((data) => (
          <Card style={{ marginTop: '15px' }}>
            <Card.Body style={{ display: 'block' }}>
              <Card.Title>
                {moment(data.createdAt).format('YYYY-MM-DD HH:mm')}
              </Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                {formatName(data.createdBy)}
              </Card.Subtitle>
              <Card.Text>
                <RadeaEditor
                  initialText={data.content}
                  onChange={null}
                  id=""
                  readOnly={true}
                />{' '}
                <SimpleGallery
                  files={data.bilagor}
                  formatSrc={(bilaga) =>
                    `/radea/egenkontroll/${data.id}/bilaga/${bilaga.id}`
                  }
                />
              </Card.Text>
            </Card.Body>
          </Card>
        ))}
      </>
    );
  }

  return (
    <>
      <Table hover striped size="sm" className="aktivitets-list">
        <thead>
          <tr>
            <td style={{ width: '150px' }}>Skapad</td>
            <td style={{ minWidth: '250px' }}>Anteckning</td>
            <td style={{ width: '150px' }}>Bilagor</td>
            <td>Skapad av</td>
          </tr>
        </thead>
        <tbody>
          {list.map((data, i) => (
            <tr key={data.id}>
              <td>{moment(data.createdAt).format('YYYY-MM-DD HH:mm')}</td>
              <td>
                <RadeaEditor
                  initialText={data.content}
                  onChange={null}
                  id=""
                  readOnly={true}
                />{' '}
              </td>
              <td>
                <SimpleGallery
                  files={data.bilagor}
                  formatSrc={(bilaga) =>
                    `/radea/egenkontroll/${data.id}/bilaga/${bilaga.id}`
                  }
                />
              </td>
              <td>{formatName(data.createdBy)}</td>
            </tr>
          ))}
          {list.length === 0 && (
            <tr>
              <td colSpan={4}>
                <i>Inga egenkontroller skapade</i>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {!hideCreateButton && (
        <div className="button-row right bottom-item">
          <Link
            to={buildPath(paths.SELF_INSPECTION)
              .query({ projektId: String(projektId) })
              .toString()}
          >
            Ny egenkontroll
          </Link>
        </div>
      )}
    </>
  );
}
