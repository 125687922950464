import { useState } from 'react';
import util, { daysAndHours } from 'utils/util';
import { Form, Table, Spinner } from 'react-bootstrap';
import moment, { Moment } from 'moment-timezone';
import { ClockFill } from 'react-bootstrap-icons';
import RadeaEditor from '../common/radea-editor';
import ProjektDeltagare from './projektdeltagare.component';
import { IVeckodetaljer } from 'types/veckodetaljer';
import { Link } from 'react-router-dom';
import { buildPath } from 'utils/path';
import paths from 'constants/paths';
import { RollKoll } from 'components/common/roll.component';
import { hasPassed, overlapsWeek } from 'components/common/calendar/utils';

export interface IVeckodetaljerProps {
  list: IVeckodetaljer[];
  week: Moment[];
  loading?: boolean;
  backUrl?: string;
}

function Veckodetaljer({ list, loading, backUrl, week }: IVeckodetaljerProps) {
  const [showPassed, setShowPassed] = useState(false);

  return (
    <>
      <div className="veckoheader">
        <span className="header">Veckan i detalj</span>
        <span className="hide-passed">
          <Form.Check
            type="checkbox"
            defaultChecked={showPassed}
            value={String(showPassed)}
            onClick={() => {
              setShowPassed(!showPassed);
            }}
          />{' '}
          Visa passerade
        </span>
      </div>
      <Table hover striped className="veckodetaljer">
        <thead>
          <tr>
            <th className="col-3">Tid och Plats</th>
            <th className="col-5">Information</th>
            <th className="col-4">Bokningar i projektet</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td
                colSpan={3}
                style={{
                  display: 'flex',
                  flexGrow: 1,
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Spinner animation="border" role="status" />
                Laddar in veckan.
              </td>
            </tr>
          ) : (
            <>
              {week
                .map((day) =>
                  list
                    .filter((event) => overlapsWeek(event, [day]))
                    .map((data, i) => {
                      return (
                        <DataRow
                          date={day}
                          obj={data}
                          key={String(data.tidsbokningId) + day.toISOString()}
                          showPassed={showPassed}
                          backUrl={backUrl}
                        />
                      );
                    })
                )
                .flat()}
            </>
          )}
        </tbody>
      </Table>
    </>
  );
}

interface IDataRowProps {
  obj: IVeckodetaljer;
  showPassed?: boolean;
  backUrl?: string;
  date: Moment;
}

const DataRow = ({ obj, showPassed, backUrl, date }: IDataRowProps) => {
  const passed = hasPassed(obj, date);

  return (
    <>
      {obj.typ > 0 && (
        <tr className={`${!passed || showPassed ? '' : 'hide'}`}>
          <td className="col-3 cell-list">
            <span className="cell-row">
              <DateDisplay passed={passed} data={obj} currentDate={date} />
              <strong>{util.getKontaktNameRemoveDuplicate(obj.kontakt)}</strong>
              <span>
                <strong>Tfn:&nbsp;</strong>
                {obj.kontakt.telefon1 && (
                  <a href={`tel:${obj.kontakt.telefon1}`}>
                    {obj.kontakt.telefon1}
                  </a>
                )}
                {!obj.kontakt.telefon1 && <>(telefon saknas)</>}
              </span>
              <span>
                <strong>Gata:</strong> {obj.kontakt.gatuadress}
              </span>
              <span>
                <strong>Ort:</strong> {obj.kontakt.postnummer}{' '}
                {obj.kontakt.postort}
              </span>
              <span>
                <a
                  href={util.getGoogleMapsURL(obj.kontakt)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google maps
                </a>
              </span>
              <RollKoll role={['admin', 'affärsAdmin']}>
                <Link
                  to={buildPath(paths.VIEW_CONTACT, obj.kontakt?.id || '')
                    .backUrl(backUrl)
                    .toString()}
                >
                  Öppna kontakt
                </Link>
              </RollKoll>
              {obj.flyttadTillKund > 0 && (
                <Link
                  to={buildPath(paths.SELF_INSPECTION)
                    .query({ projektId: String(obj.id) })
                    .backUrl(backUrl)
                    .toString()}
                >
                  Egenkontroll
                </Link>
              )}
            </span>
          </td>
          <td className="col-5">
            <RadeaEditor
              initialText={
                !obj.monteringsanvisning
                  ? obj.besiktningsinformation
                  : obj.monteringsanvisning
              }
              onChange={null}
              id="info"
              readOnly={true}
            />{' '}
            <span className="cell-list"></span>
          </td>
          <td className="col-4">
            <ProjektDeltagare projektId={obj.id} showPassed={showPassed} />
          </td>
        </tr>
      )}
    </>
  );
};

export interface IDateDisplayProps {
  data: IVeckodetaljer;
  passed?: boolean;
  currentDate: Moment;
}

function DateDisplay({ data, passed, currentDate }: IDateDisplayProps) {
  const isSameDay =
    !data.sluttid || moment(data.starttid).isSame(data.sluttid, 'day');
  const dateFormat = data.heldag
    ? 'dddd YYYY-MM-DD'
    : !isSameDay
    ? 'dddd YYYY-MM-DD 08:00'
    : 'dddd YYYY-MM-DD HH:mm';
  const date = moment(isSameDay ? data.starttid : currentDate).format(
    dateFormat
  );

  const rangeFormat = 'YYYY-MM-DD HH:mm';

  const range = isSameDay ? null : (
    <div className="range">
      från {moment(data.starttid).format(rangeFormat)} till{' '}
      {moment(data.sluttid).format(rangeFormat)}
    </div>
  );

  const duration = isSameDay
    ? null
    : daysAndHours(
        moment.min(
          moment(currentDate).set('minutes', 0).set('hours', 17),
          moment(data.sluttid)
        ),
        moment(currentDate).set('minutes', 0).set('hours', 8)
      );

  return (
    <span className={`cell-header ${!passed ? 'blue' : 'gray'}`}>
      <ClockFill /> {date}
      {range}
      <div>
        {util.atgardstypToText(data.typ)}{' '}
        {duration ??
          (data.heldag ? '(heldag)' : util.getHoursMinutes(data.langd))}
      </div>
    </span>
  );
}

export default Veckodetaljer;
