import moment, { Moment, MomentInput } from 'moment-timezone';
import { AtgardsTyp, IOrderRow, IPersonal, RoleType } from 'types';
import { formatName } from './personal';

export const formatDate = (date: string | Date, time?: boolean) => {
  date = new Date(date);
  let format = 'YYYY-MM-DD';
  if (time) {
    format = 'YYYY-MM-DD[,] [kl.] HH:mm';
  }
  return moment(date).format(format);
};

export const getHoursMinutes = (totalMin) => {
  return (
    (totalMin / 60 >= 1 ? `${Math.floor(totalMin / 60)}h` : '') +
    (totalMin % 60 >= 1 ? ` ${totalMin % 60}min` : '')
  ).trim();
};

export const getEndDate = (date, totalMin) => {
  // add total time in millis to start date
  date = new Date(new Date(date).getTime() + totalMin * 60000);
  return `${formatPos(date.getUTCHours())}:${formatPos(date.getMinutes())}`;
};

export function convertWithTimePreservation(dateString: string | Date) {
  const originalDate = moment.parseZone(dateString);
  let convertedDate = moment(dateString).set(
    'hours',
    originalDate.get('hours')
  );
  return convertedDate.toISOString();
}

export function distanceInCalendarDays(dateA: MomentInput, dateB: MomentInput) {
  const normalizedDateA = moment(dateA).startOf('day');
  const normalizedDateB = moment(dateB).startOf('day');

  return normalizedDateA.diff(normalizedDateB, 'days');
}

export function daysAndHours(dateA: MomentInput, dateB: MomentInput): string {
  const hourDiff = Math.abs(moment(dateA).diff(moment(dateB), 'hours'));
  const days = Math.floor(hourDiff / 24);
  const hours = Math.floor(hourDiff) % 24;
  let text = '';
  if (days > 0) {
    text += `${days}d`;
  }
  if (days > 0 && hours > 0) {
    text += ', ';
  }
  if (hours > 0) {
    text += `${hours}h`;
  }
  return text;
}

export function setTime(
  date: undefined | string | Date | Moment,
  time: string
): Moment {
  return moment(`${moment(date).format('YYYY-MM-DD')} ${time}`);
}

export function getNearestTimeSlot(
  minHour: number,
  maxHour: number,
  time?: string
): Moment {
  let now = moment();
  if (time) {
    now = setTime(now, time);
  }

  const currentHour = now.get('hour');
  const currentMinutes = now.get('minutes');
  const timeSlot = now.set('minutes', 0);

  if (currentHour >= maxHour) {
    return timeSlot.set('hour', maxHour - 1);
  }

  if (currentHour < minHour) {
    return timeSlot.set('hour', minHour);
  }

  if (currentMinutes / 30 < 1) {
    return timeSlot.set('minutes', 30);
  }

  return timeSlot.add(1, 'hour');
}

export const getDateObject = (d?: MomentInput, skipTime?: boolean) => {
  let today = moment();
  if (d) {
    today = moment(d);
  }
  const dateArr = today.format().split('T');
  const timeArr = dateArr[1].split(':');
  //ändra date: today.toDate() till new Date() om timmar blir fel
  return {
    date: today.toDate(),
    str: `${dateArr[0]}${skipTime ? '' : `kl. ${timeArr[0]}:${timeArr[1]}`}`,
  };
};

const formatPos = (value) => {
  return value < 10 ? `0${value}` : value;
};

export const shortenText = (text, n) => {
  return text.length > n ? text.substr(0, n - 1).trim() + '...' : text;
};

export const replaceCarriageReturn = (str) => {
  return str ? str.replace(/(?:\r\n|\r|\n)/g, '<br />') : '<i>ospec.</i>';
};

export const getYearArray = (year: number, size: number) => {
  let years: number[] = [];
  for (let cnt = 0; cnt < size; cnt++) {
    years[cnt] = year + cnt;
  }
  return years;
};
export const getDaysInMonth = (yearMonth) => {
  // Here January is 1 based
  return new Date(yearMonth.year, yearMonth.month, 0).getDate();
};

const daysCache = [];

export interface IDayOfMonth {
  dayStr: string;
  day: string;
}
export const getDaysOfMonth = (yearMonth): IDayOfMonth[] => {
  let key = `${yearMonth.year}${yearMonth.month}`;
  if (daysCache[key]) {
    return daysCache[key];
  } else {
    let dayCount = getDaysInMonth(yearMonth);
    let daysOfMonth: IDayOfMonth[] = [];
    let tempDate;
    for (let cnt = 0; cnt < dayCount; cnt++) {
      tempDate = new Date(`${yearMonth.year}-${yearMonth.month}-${cnt + 1}`);
      if (tempDate.getDay() !== 0 && tempDate.getDay() !== 6) {
        daysOfMonth.push({
          dayStr: `${
            cnt + 1 === 1 || cnt + 1 === 2 ? `${cnt + 1}:a` : `${cnt + 1}:e`
          }, ${veckodagar[tempDate.getDay()]}`,
          day: `${cnt + 1}`,
        });
      }
    }
    daysCache[key] = daysOfMonth;
    return daysOfMonth;
  }
};

export const getWeekArray = (date: Date | string) => {
  let monday = moment(date).startOf('isoWeek').toDate();
  let week: Date[] = [];
  week.push(monday);
  for (let i = 1; i < 5; i++) {
    week.push(moment(monday).add(i, 'days').toDate());
  }
  return week;
};

const getKontaktNameRemoveDuplicate = (obj) => {
  //När brf är beställaren ger de ofta fornamn Brf och efternamn som brf:ens namn vilket blir fult i listan, följande eliminerar de fallen
  let foretagsnamnArr = obj.foretagsnamn.split(' ');
  if (
    foretagsnamnArr.length >= 2 &&
    foretagsnamnArr[0] === obj.fornamn.trim() &&
    foretagsnamnArr[1] === obj.efternamn.trim()
  ) {
    return obj.foretagsnamn;
  }
  return `${obj.foretagsnamn ? `${obj.foretagsnamn}, ` : ''} ${
    obj.fornamn === '.' || obj.fornamn === '' ? '' : obj.fornamn
  } ${obj.efternamn === '.' || obj.efternamn === '' ? '' : obj.efternamn} `;
};

const getPersonalName = (personal: IPersonal, hideEmail?: boolean) => {
  if (personal && personal.fornamn !== '') {
    return `${formatName(personal)}${hideEmail ? ' ' : `, ${personal.epost}`}${
      personal.aktiv ? '' : ' (inaktiv)'
    }`;
  }
  return '-';
};

const getOrderTotalBelopp = (orderrader: IOrderRow[]) => {
  let belopp = 0;
  for (let order of orderrader) {
    belopp += order.antal * order.pris;
  }
  let totalBelopp = belopp.toString();
  if (totalBelopp.length >= 4) {
    let pos = totalBelopp.length - 3;
    totalBelopp = [totalBelopp.slice(0, pos), ' ', totalBelopp.slice(pos)].join(
      ''
    );
  }
  return `${totalBelopp} kr`;
};

const getGoogleMapsURL = (adress) => {
  let adressStr = `${adress.gatuadress}, ${adress.postnummer}, ${adress.postort}`;
  return `https://www.google.com/maps/place/${encodeURIComponent(adressStr)}`;
};

function validURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
}

const validateEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const bolag = [
  { namn: 'AB', id: 1 },
  { namn: 'Väst', id: 3 },
];
export const bolagArr = ['Ospec.', 'AB', 'Syd', 'Väst'];

export function atgardstypToText(typ: AtgardsTyp) {
  switch (typ) {
    case 0: {
      return 'Personligt';
    }
    case 1: {
      return 'Besiktning';
    }
    case 3: {
      return 'Besiktning';
    }
    case 4: {
      return 'Montering';
    }
    case 5: {
      return 'Håltagning';
    }
    case 6: {
      return 'Elektriker';
    }
  }
}

export function atgardstypToRole(typ: AtgardsTyp): null | RoleType {
  switch (typ) {
    case 1: {
      return 'besiktning';
    }
    case 3: {
      return 'projektbesiktning';
    }
    case 4: {
      return 'montör';
    }
    case 5: {
      return 'håltagare';
    }
    case 6: {
      return 'elektriker';
    }
    default: {
      return null;
    }
  }
}

export const veckodagar = ['sön', 'mån', 'tis', 'ons', 'tors', 'fre', 'lör'];
export const aktiviteter = [
  'Ospec.',
  'Telefonkontakt',
  'Mailkontakt',
  'Skickat offert',
  'Level',
];
export const malltextTyper = ['Ospec', 'Mailbekräftelse', 'Orderbeskrivning'];
export const orderstatus = [
  'Ny',
  'Orderbekr. skickad',
  'Klar för fakturering',
  'Fakturerad',
  'Inaktiverad',
  'Felaktig',
];

export default {
  getWeekArray,
  formatDate,
  shortenText,
  replaceCarriageReturn,
  getHoursMinutes,
  getEndDate,
  getYearArray,
  getDaysInMonth,
  getDaysOfMonth,
  getKontaktNameRemoveDuplicate,
  validURL,
  getGoogleMapsURL,
  getDateObject,
  getPersonalName,
  getOrderTotalBelopp,
  validateEmail,
  bolag,
  bolagArr,
  atgardstypToText,
  aktiviteter,
  malltextTyper,
  orderstatus,
};
