import { useReducer, useEffect, ReactNode } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Spinner, Accordion, Form, ProgressBar } from 'react-bootstrap';
import { IFortnoxArticle } from 'types/artikel';
import InfoCard from './info-card';
import {
  ExclamationCircle,
  Check2,
  ExclamationCircleFill,
} from 'react-bootstrap-icons';
import styled from 'styled-components';
import { getAxiosInstance } from 'components/action/services';
import reducer, { initState, ISyncOperations } from './reducer';
import { AxiosError } from 'axios';
import { themeColors } from 'constants/colors';

const axios = getAxiosInstance();

const PreviewContainer = styled.div`
  &:not(:first-child) {
    margin-top: 10px;
  }
`;

const ErrorList = styled.ul`
  color: ${themeColors.danger};
  list-style: none;
  padding: 0;

  li {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    </strong > svg {
      margin-right: 7px;
    }
  }
`;

export interface IArticleSyncDialogProps {
  onHide: () => unknown;
  show?: boolean;
}

interface IUpdatePreview {
  [key: string]: {
    create: IFortnoxArticle[];
    remove: IFortnoxArticle[];
    update: IFortnoxArticle[];
  };
}

export function ArtikelSyncDialog({ show, onHide }: IArticleSyncDialogProps) {
  const [
    {
      removeArticles,
      syncErrors,
      syncMessage,
      syncProgress,
      syncState,
      updatePreview,
      generalError,
    },
    dispatch,
  ] = useReducer(reducer, {}, initState);

  useEffect(() => {
    if (!show) {
      return;
    }
    dispatch({
      type: 'LOAD_UPDATE_PREVIEW_START',
    });

    axios
      .get<IUpdatePreview>(`/artiklar/fortnox-sync?dryRun=true`)
      .then((updateInfo) => {
        dispatch({
          type: 'LOAD_UPDATE_PREVIEW_DONE',
          payload: updateInfo.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'LOAD_UPDATE_PREVIEW_ERROR',
          payload: error.response.data.msg,
        });
      });
  }, [show]);

  let content: ReactNode = null;

  switch (syncState) {
    case 'loadingPreview': {
      content = (
        <div className="spinner-container">
          <Spinner animation="border" role="status" />
        </div>
      );
      break;
    }
    case 'notStarted': {
      content = (
        <div>
          <Form.Check
            type="checkbox"
            label={`Ta bort artiklar i Fortnox som inte finns i CRM`}
            checked={removeArticles}
            onChange={(e) => {
              dispatch({
                type: 'SET_REMOVE_ARTICLES',
                payload: e.target.checked,
              });
            }}
          />
          {Object.keys(updatePreview).map((key) => {
            return (
              <PreviewContainer key={key}>
                <h5>{key}</h5>
                <Accordion>
                  <InfoCard
                    articles={updatePreview[key].create}
                    eventKey="create"
                    type="create"
                  />
                  <InfoCard
                    articles={updatePreview[key].update}
                    eventKey="update"
                    type="update"
                  />
                  <InfoCard
                    articles={updatePreview[key].remove}
                    eventKey="remove"
                    type="remove"
                  />
                </Accordion>
              </PreviewContainer>
            );
          })}
        </div>
      );
      break;
    }
    case 'active': {
      content = (
        <div>
          <h6>
            För över artiklar till Fortnox, var god stäng inte detta fönster i
            webbläsaren
          </h6>
          <ProgressBar animated now={syncProgress} />
          <p>{syncMessage}</p>
          <ErrorList>
            {syncErrors.map((e) => (
              <li>
                <ExclamationCircle /> {e}
              </li>
            ))}
          </ErrorList>
        </div>
      );
      break;
    }
    case 'error': {
      content = (
        <div>
          <h6
            style={{
              color: themeColors.danger,
            }}
          >
            <span style={{ marginRight: '7px' }}>
              <ExclamationCircleFill />
            </span>
            {generalError}
          </h6>
        </div>
      );
      break;
    }
    case 'done': {
      content = (
        <div>
          <h6
            style={{
              color:
                syncErrors.length === 0
                  ? themeColors.success
                  : themeColors.danger,
            }}
          >
            <span style={{ marginRight: '7px' }}>
              {syncErrors.length ? <ExclamationCircleFill /> : <Check2 />}
            </span>
            Synkronisering klar
            {syncErrors.length > 0
              ? ', några artiklar kunder inte föras över korrekt till Fortnox'
              : ''}
          </h6>
          <ErrorList>
            {syncErrors.map((e) => (
              <li>
                <ExclamationCircle /> {e}
              </li>
            ))}
          </ErrorList>
        </div>
      );
      break;
    }
  }

  return (
    <Modal show={show} onHide={onHide} size="lg" backdrop="static">
      <Modal.Header>
        <Modal.Title>Full synkronisering med Fortnox</Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        {(syncState === 'notStarted' || syncState === 'error') && (
          <Button
            variant="link"
            onClick={() => {
              onHide();
            }}
          >
            Avbryt
          </Button>
        )}
        {syncState === 'notStarted' && (
          <Button
            variant="success"
            onClick={() => {
              sync();
            }}
          >
            Starta
          </Button>
        )}
        {syncState === 'done' && (
          <Button
            variant="success"
            onClick={() => {
              onHide();
            }}
          >
            Avsluta
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );

  async function sync() {
    dispatch({
      type: 'SYNC_START',
    });
    const totalOperations = Object.keys(updatePreview).reduce(
      (total, bolag) =>
        total +
        Object.keys(updatePreview[bolag]).reduce(
          (prev, curr) =>
            curr === 'remove' && !removeArticles
              ? prev
              : (updatePreview[bolag]?.[curr]?.length || 0) + prev,
          0
        ),
      0
    );

    let totalProgress = 0;

    for (let bolag of Object.keys(updatePreview)) {
      const updatedProgress = await syncBolag(
        bolag,
        updatePreview[bolag],
        totalOperations,
        totalProgress
      );
      totalProgress += updatedProgress;
    }
    dispatch({
      type: 'SYNC_DONE',
    });
  }

  async function syncBolag(
    bolag: string,
    operations: ISyncOperations,
    totalOperations: number,
    previousProgress
  ): Promise<number> {
    let progress = 0;

    if (removeArticles) {
      for (let article of operations.remove) {
        dispatch({
          type: 'SYNC_PROGRESS',
          payload: {
            message: `Tar bort artikel "${article.ArticleNumber} (${bolag})..."`,
          },
        });
        const errorPrefix = `${bolag} - Artikel ${article.ArticleNumber} (ta bort): `;
        let error = '';

        try {
          const result = await axios.delete(
            `/artiklar/fortnox/${article.ArticleNumber}`,
            {
              data: { bolag },
            }
          );
          error = result.data.error || '';
        } catch (e) {
          error = (e as AxiosError)?.response?.data as string;
        }
        progress += (1 / totalOperations) * 100;
        dispatch({
          type: 'SYNC_PROGRESS',
          payload: {
            progress: previousProgress + progress,
            error: error ? `${errorPrefix} ${error}` : '',
          },
        });
      }
    }

    for (let article of operations.create) {
      dispatch({
        type: 'SYNC_PROGRESS',
        payload: {
          message: `Skapar artikel "${article.ArticleNumber} (${bolag})..."`,
        },
      });
      const errorPrefix = `${bolag} - Artikel ${article.ArticleNumber} (ny): `;

      let error = '';
      try {
        const result = await axios.post(`/artiklar/fortnox`, {
          data: article,
          bolag,
        });
        error = result.data.error || '';
      } catch (e) {
        error = (e as AxiosError)?.response?.data as string;
      }
      progress += (1 / totalOperations) * 100;
      dispatch({
        type: 'SYNC_PROGRESS',
        payload: {
          progress: previousProgress + progress,
          error: error ? `${errorPrefix} ${error}` : '',
        },
      });
    }

    for (let article of operations.update) {
      dispatch({
        type: 'SYNC_PROGRESS',
        payload: {
          message: `Uppdaterar artikel "${article.ArticleNumber} (${bolag})..."`,
        },
      });
      const errorPrefix = `${bolag} - Artikel ${article.ArticleNumber} (uppdatering): `;
      let error = '';

      try {
        const result = await axios.put(
          `/artiklar/fortnox/${article.ArticleNumber}`,
          {
            data: article,
            bolag,
          }
        );
        error = result.data.error || '';
      } catch (e) {
        error = (e as AxiosError)?.response?.data as string;
      }
      progress += (1 / totalOperations) * 100;
      dispatch({
        type: 'SYNC_PROGRESS',
        payload: {
          progress: previousProgress + progress,
          error: error ? `${errorPrefix} ${error}` : '',
        },
      });
    }
    return progress;
  }
}

export default ArtikelSyncDialog;
