import { ReactNode } from 'react';
import { Alert, Button } from 'react-bootstrap';
import {
  XCircle,
  CheckCircle,
  ExclamationTriangleFill,
} from 'react-bootstrap-icons';
import { ThemeVariants } from 'types';

export interface IRadeaAlertProps {
  msg: ReactNode;
  type: ThemeVariants;
  show?: boolean;
  fixedWidth?: boolean;
  showCloseButton?: boolean;
  onDismiss?: () => unknown;
}

const ICON_SIZE = 28;

export const RadeaAlert = ({
  msg,
  type,
  show,
  fixedWidth = true,
  showCloseButton,
  onDismiss = () => null,
}: IRadeaAlertProps) => {
  const classNames = ['radea-alert'];

  if (fixedWidth) {
    classNames.push('fixed-width');
  }

  return (
    <>
      {show && (
        <Alert
          className={classNames.join(' ')}
          variant={type}
          transition={true}
        >
          <div className="main-content">
            {type === 'success' && <CheckCircle size={ICON_SIZE} />}
            {type === 'danger' && <XCircle size={ICON_SIZE} />}
            {type === 'warning' && <ExclamationTriangleFill size={ICON_SIZE} />}
            <div>{msg}</div>
          </div>
          <div>
            {showCloseButton && (
              <>
                <hr />
                <div className="d-flex justify-content-end">
                  <Button onClick={onDismiss} variant={`outline-${type}`}>
                    Stäng
                  </Button>
                </div>
              </>
            )}
          </div>
        </Alert>
      )}
    </>
  );
};

export const WarningMessage = (props) => {
  const { msg, show } = props;
  return (
    <>
      {show && (
        <div className={'warning-div'}>
          <ExclamationTriangleFill size={22} />
          {msg}
        </div>
      )}
      {!show && <></>}
    </>
  );
};

export default { RadeaAlert, WarningMessage };
