import { useState, useEffect, useCallback, ReactNode } from 'react';
import {
  distanceInCalendarDays,
  getNearestTimeSlot,
  setTime,
  atgardstypToText,
  atgardstypToRole,
} from 'utils/util';
import { PlusCircle, Plus, ArrowRight } from 'react-bootstrap-icons';
import { doPost, doGetList } from 'components/action/services';
import { Form, Container, Col, Button } from 'react-bootstrap';
import { PersonalRollList } from 'components/common/personal.component';
import RadeaCalendar from 'components/common/calendar';
import {
  IRadeaAlertProps,
  RadeaAlert,
} from 'components/common/radea-alert.component';
import moment, { Moment } from 'moment-timezone';
import {
  AtgardsTyp,
  IPersonal,
  ITidsbokingWrite,
  ITidsbokning,
  RoleType,
} from 'types';
import DatePicker from 'components/common/date-picker';
import { AxiosResponse } from 'axios';
import { confirm } from 'utils/ui';
import { checkForConflicts } from 'utils/tidsbokning';
import { formatName } from 'utils/personal';
import joi from 'joi';

const tidsbokningSchema = joi.object({
  startDate: joi.object().required(),
  endDate: joi.object().required(),
  endTime: joi.string().required(),
  starttid: joi.string().required(),
  langd: joi.number().required(),
  typ: joi.number(),
  info: joi.string().allow(''),
  heldag: joi.boolean(),
  visibleToCustomer: joi.boolean(),
});

export interface ITidsbokningFormData {
  startDate: Moment;
  endDate: Moment;
  endTime: string;
  starttid: string;
  personalId: number;
  typ: AtgardsTyp;
  langd: number;
  info: string;
  heldag: boolean;
  visibleToCustomer: boolean;
}

export interface ISkapaTidsbokningProps {
  atgardstyper: AtgardsTyp[]; //Array med atgardstyper som ska vara valbara för bokningen
  projektId: null | number; // Vilket projekt bokningen gäller. Kan vara null om personlig bokning.
  forPersonal?: undefined | null | IPersonal; // Om bokningen gäller en förbestämd personal, vid personlig bokning.
  refreshList: () => unknown; // Funktion för att uppdatera listning uppåt i hierarkin
  ort?: undefined | null | string; // Vilken ort bokningen sker i
  general?: boolean;
}

export const SkapaTidsbokning = ({
  atgardstyper, //Array med atgardstyper som ska vara valbara för bokningen
  projektId, // Vilket projekt bokningen gäller. Kan vara null om personlig bokning.
  refreshList, // Funktion för att uppdatera listning uppåt i hierarkin
  ort, // Vilken ort bokningen sker i
  forPersonal, // Om bokningen gäller en förbestämd personal, vid personlig bokning.
  general,
}: ISkapaTidsbokningProps) => {
  const [atgardstyp, setAtgardstyp] = useState<AtgardsTyp>(
    atgardstyper.length > 1 ? atgardstyper[0] : forPersonal || general ? 0 : 1
  );
  const [formOpen, setFormOpen] = useState(atgardstyper.length === 1);
  const [selectedPersonal, setSelectedPersonal] = useState<IPersonal | null>(
    forPersonal ? forPersonal : null
  );

  const [events, setEvents] = useState<ITidsbokning[]>([]);
  const [ovrigaTider, setOvrigaTider] = useState(false);
  const [validated, setValidated] = useState(general ? true : false);
  const [formValid, setFormValid] = useState(false);
  const [alert, setAlert] = useState<IRadeaAlertProps>({
    show: false,
    msg: '',
    type: 'info',
  });

  const [formData, setFormData] = useState<ITidsbokningFormData>({
    startDate: moment(),
    endDate: moment(),
    starttid: getNearestTimeSlot(
      ovrigaTider ? 7 : 8,
      ovrigaTider ? 18 : 17
    ).format('HH:mm'),
    endTime: '',
    personalId: selectedPersonal?.id || 0,
    typ: atgardstyp,
    langd: 30,
    info: general ? 'Allmän' : '',
    heldag: false,
    visibleToCustomer: false,
  });

  const [eventDate, setEventDate] = useState<ITidsbokning | null>({
    starttid: moment().set('hour', 8).set('minute', 0).toISOString(),
    sluttid: '',
    typ: atgardstyp,
    personalId: selectedPersonal?.id || 0,
    langd: 30,
    ort: '',
    info: '',
    heldag: false,
  });

  useEffect(() => {
    setFormValid(validateForm(formData, general));
    setEventDate({
      starttid: moment(
        `${formData.startDate.format('YYYY-MM-DD')} ${
          formData.starttid || '08:00'
        }`
      ).toISOString(),
      typ: formData.typ,
      langd: formData.langd,
      personalId: selectedPersonal?.id || 0,
      ort: '',
      info: atgardstyp === 0 ? formData.info : '',
      heldag: formData.heldag,
      sluttid:
        formData.endDate && formData.endTime
          ? moment(
              `${formData.endDate.format('YYYY-MM-DD')} ${
                formData.endTime || '08:00'
              }`
            ).toISOString()
          : undefined,
    });
  }, [formData, atgardstyp, general, selectedPersonal]);

  const getBokningForPersonal = useCallback(async () => {
    const promises: Promise<
      | AxiosResponse<any>
      | {
          data: never[];
        }
    >[] = [];

    promises.push(
      doGetList(`/tidsbokningar/personal/${selectedPersonal?.id || 0}`)
    );

    promises.push(doGetList(`/tidsbokningar/general`));

    if (projektId) {
      promises.push(doGetList(`/tidsbokningar/projekt/${projektId}`));
    }

    const [personalEventsResponse, generalEvents, projectEventsResponse] =
      await Promise.all(promises);

    let events = personalEventsResponse?.data || [];

    events = events.concat(
      projectEventsResponse?.data?.filter(
        (pe) => !events.find((e) => e.tidsbokningId === pe.id)
      ) || []
    );

    events = events.concat(generalEvents.data);

    setEvents(events);
  }, [selectedPersonal, projektId]);

  useEffect(() => {
    getBokningForPersonal();
  }, [getBokningForPersonal]);

  const handleSelection = (personal: IPersonal) => {
    setFormData({ ...formData, personalId: personal.id || 0 });
    setSelectedPersonal(personal);
  };

  const selectDate = (dateStr: string | null) => {
    let date = dateStr ? moment(dateStr) : moment();
    let newTime = date.format('HH:mm');
    let maxLangd = moment(date)
      .set('hour', 17)
      .set('minute', 0)
      .diff(date, 'minutes');

    if (formData.heldag) {
      newTime = '08:00';
    }

    const currentDifference = distanceInCalendarDays(
      formData.endDate,
      formData.startDate
    );
    const updatedLangd = Math.min(
      formData.langd > 0 ? formData.langd : 30,
      maxLangd
    );
    const updatedEndTime = date.add(updatedLangd, 'minutes');

    setFormData({
      ...formData,
      startDate: moment(dateStr),
      endDate: moment(dateStr).add(currentDifference, 'days'),
      personalId: selectedPersonal?.id || 0,
      typ: atgardstyp,
      starttid: newTime,
      endTime:
        currentDifference === 0
          ? updatedEndTime.format('HH:mm')
          : formData.endTime,
      langd: updatedLangd,
      info: atgardstyp === 0 ? formData.info : '',
    });
  };

  const showAlert = (msg, type) => {
    setAlert({ show: true, msg: msg, type: type });
    setTimeout(() => {
      setAlert({
        show: false,
        msg: '',
        type: 'success',
      });
    }, 2000);
  };

  const handleSubmit = async () => {
    const data: ITidsbokingWrite = {
      projektId: projektId,
      typ: formData.typ,
      starttid: setTime(formData.startDate, formData.starttid).toISOString(),
      sluttid: setTime(formData.endDate, formData.endTime).toISOString(),
      langd: formData.langd,
      personalId: selectedPersonal?.id || 0,
      info:
        formData.info === '' ? atgardstypToText(formData.typ) : formData.info,
      heldag: formData.heldag,
      general: general || false,
      visibleToCustomer: formData.visibleToCustomer,
    };

    const conflicts = checkForConflicts(data, events);

    if (conflicts.length > 0) {
      const proceed = await confirm(
        <>
          <p>
            Det finns existerande bokningar som överlappar den nya bokningen:
          </p>
          <ul>
            {conflicts.map((c) => (
              <li key={c.id}>
                <strong>{c.info}</strong> -{' '}
                {formatName(c.personal?.[0]?.bokadPersonal)}{' '}
                {formatBookingDuration(c)}
              </li>
            ))}
          </ul>
          <p>
            Är du säker på att du vill skapa bokningen?
            <br />
            <span>({formatBookingDuration(data)})</span>
          </p>
        </>,
        {
          title: 'Skapa tidsbokning',
          confirmText: 'Skapa',
        }
      );
      if (!proceed) {
        return;
      }
    }

    let result;
    try {
      result = await doPost('/tidsbokningar/create', data);
    } catch (err) {
      result = { success: false };
    }
    if (result.data.success) {
      showAlert('Bokningen har sparats i systemet.', 'success');
      setTimeout(() => {
        clearAndReload();
      }, 1800);
    } else {
      showAlert(
        'Fel, bokningen har inte sparats i systemet, kontakta supporten.',
        'danger'
      );
    }
  };

  const setCalendarValidation = (valid, msg) => {
    if (general) {
      setValidated(true);
      return;
    }
    if (!valid) {
      //showAlert(msg, 'danger');
      setValidated(false);
      /*setTimeout(() => {
        updateFormData('starttid', 0);
      }, 2000);*/
    } else {
      setValidated(true);
    }
  };

  const clearAndReload = () => {
    setFormData({
      ...formData,
      starttid: getNearestTimeSlot(
        ovrigaTider ? 7 : 8,
        ovrigaTider ? 18 : 17
      ).format('HH:mm'),
      endTime: '',
    });
    getBokningForPersonal();
    refreshList();
  };

  return (
    <>
      <Container className="atgard-container">
        {atgardstyper.length > 1 && (
          <>
            <strong>Skapa ny åtgärd/bokning</strong>
            <span>
              <Form.Control
                as="select"
                value={atgardstyp}
                onChange={(e) => {
                  const typ = parseInt(e.target.value, 10);
                  setAtgardstyp(typ as AtgardsTyp);
                  setFormData({
                    ...formData,
                    typ: typ as AtgardsTyp,
                  });
                }}
                custom
              >
                {atgardstyper.map((data, i) => {
                  return (
                    <option key={`at${i}`} value={data}>
                      {atgardstypToText(data)}
                    </option>
                  );
                })}
              </Form.Control>
              <span className={atgardstyp ? 'click-item' : ''}>
                <PlusCircle
                  size={34}
                  color={atgardstyp !== 0 ? '#669eb7' : '#eeeeee'}
                  onClick={() => {
                    setFormOpen(true);
                  }}
                />
              </span>
              {formOpen && (
                <Button
                  variant={'link'}
                  onClick={() => {
                    setFormOpen(false);
                    setSelectedPersonal(null);
                  }}
                >
                  Avbryt
                </Button>
              )}
            </span>
          </>
        )}
        {formOpen && (
          <>
            <Container className="bordered-container row-container">
              {!forPersonal && !general && (
                <Form className="atgard-form radea-form">
                  <strong>
                    Personal: {selectedPersonal?.fornamn || ''}{' '}
                    {selectedPersonal?.efternamn || ''}
                  </strong>
                  <Form.Row>
                    <PersonalRollList
                      roller={
                        [atgardstyp]
                          .map(atgardstypToRole)
                          .filter((r) => r !== null) as RoleType[]
                      }
                      registerSelected={handleSelection}
                    />
                  </Form.Row>
                </Form>
              )}
              {forPersonal && (
                <Form className="atgard-form radea-form">
                  <strong>Privat bokning</strong>
                  <Form.Row>
                    <Form.Group as={Col} controlId="info">
                      <Form.Control
                        defaultValue={formData.info}
                        placeholder="Info"
                        onChange={(e) => {
                          setFormData({ ...formData, info: e.target.value });
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Form.Row>
                </Form>
              )}
              {general && (
                <Form className="atgard-form radea-form">
                  <strong>Allmän bokning</strong>
                  <Form.Row>
                    <Form.Group as={Col} controlId="info">
                      <Form.Control
                        defaultValue={formData.info}
                        placeholder="Info"
                        onChange={(e) => {
                          setFormData({ ...formData, info: e.target.value });
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Form.Row>
                </Form>
              )}
              <Form className="atgard-form radea-form">
                <Form.Row>
                  <Form.Group as={Col}>
                    <DatePicker
                      label={'Startdatum'}
                      value={formData.startDate}
                      markRangeEnds
                      range={
                        !formData.startDate.isSame(formData.endDate, 'day')
                          ? formData.endDate.endOf('day').toDate()
                          : undefined
                      }
                      onChange={(newValue) => {
                        const currentDifference = distanceInCalendarDays(
                          formData.endDate,
                          formData.startDate
                        );

                        setFormData({
                          ...formData,
                          startDate: moment(newValue)
                            .set('hours', 8)
                            .set('minutes', 0),
                          endDate: moment(newValue).add(
                            currentDifference,
                            'days'
                          ),
                        });
                      }}
                    />
                  </Form.Group>
                  {!formData.heldag && (
                    <Form.Group as={Col} controlId="starttid">
                      <Form.Control
                        value={formData.starttid}
                        as="select"
                        onChange={(e) => {
                          const updateEndTime = setTime(
                            formData.endDate,
                            e.target.value
                          ).add(formData.langd, 'minutes');

                          setFormData({
                            ...formData,
                            starttid: e.target.value,
                            endTime: formData.startDate.isSame(
                              formData.endDate,
                              'day'
                            )
                              ? updateEndTime.format('HH:mm')
                              : formData.endTime,
                          });
                        }}
                        disabled={formData.heldag}
                        custom
                      >
                        <option key="starttid" value="">
                          Starttid:
                        </option>
                        {getTimes(
                          ovrigaTider ? 7 : 8,
                          ovrigaTider ? 18 : 17,
                          true
                        )}
                      </Form.Control>
                    </Form.Group>
                  )}
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <DatePicker
                      label="Slutdatum"
                      markRangeEnds
                      range={
                        !formData.startDate.isSame(formData.endDate, 'day')
                          ? formData.startDate.startOf('day').toDate()
                          : undefined
                      }
                      minDate={formData.startDate}
                      value={formData.endDate ?? formData.startDate}
                      onChange={(newValue) => {
                        setFormData({
                          ...formData,
                          endDate: moment.max(
                            moment(newValue).set('hours', 8).set('minutes', 0),
                            formData.startDate
                          ),
                        });
                      }}
                    />
                  </Form.Group>
                  {!formData.heldag && (
                    <Form.Group as={Col} controlId="endTime">
                      <Form.Control
                        value={formData.endTime}
                        as="select"
                        onChange={(e) => {
                          const updatedEndTime = setTime(
                            formData.endDate,
                            e.target.value ||
                              formData.endTime ||
                              setTime(
                                moment(formData.startDate),
                                formData.starttid
                              )
                                .add(formData.langd, 'minutes')
                                .format('HH:mm')
                          );

                          const startTime = setTime(
                            formData.startDate,
                            formData.starttid
                          );

                          setFormData({
                            ...formData,
                            endTime: e.target.value,
                            langd: formData.startDate.isSame(
                              formData.endDate,
                              'day'
                            )
                              ? updatedEndTime.diff(startTime, 'minutes')
                              : formData.langd,
                          });
                        }}
                        disabled={formData.heldag}
                        custom
                      >
                        <option key="endTime" value="">
                          Sluttid:
                        </option>
                        {getTimes(
                          ovrigaTider ? 8 : 7,
                          ovrigaTider ? 19 : 18,
                          false,
                          formData?.endDate?.isSame(formData.startDate, 'day')
                            ? formData.starttid
                            : undefined
                        )}
                      </Form.Control>
                    </Form.Group>
                  )}
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Check
                      type="switch"
                      checked={formData?.heldag}
                      label="Heldag"
                      id="heldag-switch"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          heldag: e.target.checked,
                          endTime: e.target.checked
                            ? setTime(formData.endDate, formData.starttid)
                                .add(30, 'minutes')
                                .format('HH:mm')
                            : '',
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Button
                      variant={validated && formValid ? 'success' : 'secondary'}
                      onClick={() => {
                        if (validated && formValid) {
                          handleSubmit();
                        } else if (selectedPersonal?.id === 0) {
                          showAlert(
                            'Välj utförare i personallistan.',
                            'danger'
                          );
                        } else if (!formData.startDate) {
                          showAlert(
                            'Det saknas värden i formuläret.',
                            'danger'
                          );
                        } else {
                          showAlert(
                            'Bokningen kan inte läggas till på aktuell tid.',
                            'danger'
                          );
                        }
                      }}
                    >
                      <Plus size={20} />
                      Lägg till
                    </Button>
                  </Form.Group>
                </Form.Row>
                {!forPersonal && (
                  <Form.Row>
                    <Form.Group as={Col} controlId="extratid">
                      <Form.Check
                        type="switch"
                        label="Tillåt övriga tider"
                        checked={ovrigaTider}
                        onChange={(e) => {
                          setOvrigaTider(e.target.checked);
                          if (
                            !e.target.checked &&
                            !formData.heldag &&
                            formData.startDate.isSame(formData.endDate, 'day')
                          ) {
                            let updatedEndTime = formData.endTime;
                            let updatedStartTime = formData.starttid;

                            if (
                              setTime(formData.endDate, formData.endTime).get(
                                'hour'
                              ) > 17
                            ) {
                              updatedEndTime = '17:00';
                            }

                            if (
                              setTime(
                                formData.startDate,
                                formData.starttid
                              ).get('hour') < 8
                            ) {
                              updatedStartTime = '08:00';
                            }

                            const updatedLangd = setTime(
                              formData.startDate,
                              updatedEndTime
                            ).diff(
                              setTime(formData.startDate, updatedStartTime),
                              'minutes'
                            );

                            setFormData({
                              ...formData,
                              endTime: updatedEndTime,
                              starttid: updatedStartTime,
                              langd: updatedLangd,
                            });
                          }
                        }}
                      />
                    </Form.Group>
                    <div className="align-right">
                      <strong>Ort: </strong>
                      {ort}
                    </div>
                  </Form.Row>
                )}
                {projektId && (
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Check
                        type="switch"
                        label="Synlig för kund"
                        id="visible-to-customer-switch"
                        checked={formData?.visibleToCustomer}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            visibleToCustomer: e.target.checked,
                          });
                        }}
                      />
                    </Form.Group>
                  </Form.Row>
                )}
                <RadeaAlert
                  msg={alert.msg}
                  type={alert.type}
                  show={alert.show}
                />
              </Form>
            </Container>
            <RadeaCalendar
              selectedMonday={
                eventDate?.starttid
                  ? moment(eventDate.starttid).toDate()
                  : new Date()
              }
              loading={false}
              registerWeekChange={(date) => {
                const newDate = moment(date);
                setFormData({
                  ...formData,
                  startDate: newDate,
                  endDate: newDate,
                });
              }}
              events={events}
              personal={selectedPersonal}
              offHours={ovrigaTider}
              eventDate={formValid ? eventDate : null}
              registerDate={selectDate}
              registerValidation={setCalendarValidation}
              bookable={formData.startDate.isSame(formData.endDate, 'day')}
            />
          </>
        )}
      </Container>
    </>
  );
};

function formatBookingDuration(booking: ITidsbokning): ReactNode {
  let dateFormat = booking.heldag ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm';
  const text = moment(booking.starttid).format(dateFormat);

  if (moment(booking.starttid).isSame(booking.sluttid, 'day')) {
    dateFormat = 'HH:mm';
  }

  return (
    <>
      <span>{text}</span>
      &nbsp;
      <ArrowRight style={{ verticalAlign: 'bottom', height: '24px' }} />
      &nbsp;
      <span>{moment(booking.sluttid).format(dateFormat)}</span>
    </>
  );
}

function getTimes(
  minHour: number,
  maxHour: number,
  includeLastHalf?: boolean,
  startTime?: string
): JSX.Element[] {
  const hours = maxHour - minHour;
  const start = startTime
    ? moment(`${moment().format('YYYY-MM-DD')} ${startTime}`)
    : null;
  const startHour = start ? start.get('hour') : minHour;
  const startMinute = start ? start.get('minute') : 0;

  return Array.from(Array(hours))
    .map((_, idx) => {
      const hour = idx + minHour;
      const time = moment().set('hour', hour).set('minutes', 0);

      const value = time.format('HH:mm');
      const halfValue = time.add(30, 'minutes').format('HH:mm');
      const result: JSX.Element[] = [];

      if (!startTime || hour > startHour) {
        result.push(
          <option value={value}>
            {value} {startTime ? `(${getDuration(startTime, value)})` : ''}
          </option>
        );
      }

      if (!includeLastHalf && hour + 1 === maxHour) {
        return result;
      }

      if (
        !startTime ||
        hour > startHour ||
        (startHour === hour && startMinute === 0)
      ) {
        result.push(
          <option value={halfValue}>
            {halfValue}{' '}
            {startTime ? `(${getDuration(startTime, halfValue)})` : ''}
          </option>
        );
      }
      return result;
    })
    .flat();
}

function getDuration(startTime: string, endTime: string): string {
  const duration = moment.duration(endTime).subtract(startTime).as('minutes');

  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;

  let text: string = hours >= 1 ? `${hours}h` : '';

  if (minutes > 0) {
    text += hours > 0 ? ' och ' : '';
    text += `${minutes}min`;
  }

  return text;
}
function validateForm(
  formData: ITidsbokningFormData,
  general?: boolean
): boolean {
  let schema = tidsbokningSchema.keys({
    personalId: joi.number().disallow(0),
  });

  if (general) {
    schema = tidsbokningSchema.keys({
      personalId: joi.number(),
    });
  }

  const { error } = schema.validate(formData);
  return !error;
}

export default SkapaTidsbokning;
