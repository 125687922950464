import { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { Pencil, FileEarmarkText } from 'react-bootstrap-icons';
import util from 'utils/util';
import { getPersonalById } from 'components/common/personal.component';
import { GlobalStateContext } from 'global.context';
import { IOrder } from 'types/order';

export interface IOrderbekraftelseListProps {
  list: IOrder[];
  editOrderbekraftelse: (data: IOrder, index: number) => unknown;
}

export const OrderbekraftelseList = ({
  list,
  editOrderbekraftelse,
}: IOrderbekraftelseListProps) => {
  const { personalList } = useContext(GlobalStateContext);

  return (
    <>
      <Table hover striped size="sm" className="order-list">
        <thead>
          <tr>
            <td>Orderdatum</td>
            <td>Ordernummer</td>
            <td>Vår referens</td>
            <td>Orderrader</td>
            <td>Status</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {list.map((data, i) => {
            let p = getPersonalById(data.varReferens, personalList);
            return (
              <>
                <tr
                  key={`alist${i}`}
                  onClick={() => {
                    editOrderbekraftelse(data, i);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <td>{data.orderdatum}</td>
                  <td>{data.ordernummer}</td>
                  <td>
                    {data.varReferensStr && data.varReferensStr !== '-'
                      ? data.varReferensStr
                      : util.getPersonalName(p)}
                  </td>
                  <td>{data.orderrader?.length || 0}</td>
                  <td>{util.orderstatus[data.status]}</td>
                  <td>
                    <FileEarmarkText
                      size={20}
                      className={'click-item blue radea-button'}
                      onClick={() => {
                        window.open(
                          `/radea/order/pdf/${data.ordernummer}`,
                          '_blank',
                          'noopener,noreferrer'
                        );
                      }}
                    />
                    <Pencil
                      size={20}
                      className={'click-item blue'}
                      onClick={(event) => {
                        event.stopPropagation();
                        editOrderbekraftelse(data, i);
                      }}
                    />
                  </td>
                </tr>
              </>
            );
          })}
          {list.length === 0 && (
            <tr>
              <td colSpan={5}>
                <i>Det finns ingen orderbekräftelse</i>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};
