import { ReactNode } from 'react';
import { Alert, Button, Modal, ProgressBar } from 'react-bootstrap';
import {
  XCircle,
  CheckCircle,
  ExclamationTriangleFill,
} from 'react-bootstrap-icons';
import { ThemeVariants } from 'types';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';

const StyledModal = styled(Modal)`
  .variable-width {
    display: flex;
    justify-content: center;

    .modal-content {
      width: auto;
    }
  }
`;

const ProgressWrapper = styled.div`
  min-width: 180px;
`;

export enum RadeaLoadingModalState {
  loading,
  success,
  warning,
  error,
}

export interface IRadeaLoadingModalProps {
  state: RadeaLoadingModalState;
  msg: ReactNode;
  show?: boolean;
  showCloseButton?: boolean;
  onHide?: () => unknown;
  progress?: number;
}

const ICON_SIZE = 28;

export const RadeaLoadingModal = ({
  state,
  msg,
  show,
  showCloseButton,
  onHide = () => null,
  progress,
}: IRadeaLoadingModalProps) => {
  let icon: ReactNode = null;
  let variant: ThemeVariants = 'info';

  switch (state) {
    case RadeaLoadingModalState.success: {
      icon = <CheckCircle size={ICON_SIZE} />;
      variant = 'success';
      break;
    }
    case RadeaLoadingModalState.error: {
      icon = <XCircle size={ICON_SIZE} />;
      variant = 'danger';
      break;
    }
    case RadeaLoadingModalState.warning: {
      icon = <ExclamationTriangleFill size={ICON_SIZE} />;
      variant = 'warning';
      break;
    }
    case RadeaLoadingModalState.loading: {
      variant = 'light';
      icon =
        progress !== undefined ? (
          <ProgressWrapper>
            <ProgressBar animated now={progress} />
          </ProgressWrapper>
        ) : (
          <Spinner
            animation="border"
            role="status"
            style={{ minWidth: '2rem' }}
          />
        );

      break;
    }
  }

  return (
    <StyledModal
      show={show}
      onHide={onHide}
      centered
      backdrop="static"
      keyboard={false}
      dialogClassName="variable-width"
    >
      <Alert variant={variant} transition={true} style={{ margin: 0 }}>
        <div className="d-flex justify-content-center align-items-center">
          {icon}
          <div style={{ marginLeft: '0.75rem' }}>{msg}</div>
        </div>
        <div>
          {showCloseButton && (
            <>
              <hr />
              <div className="d-flex justify-content-end">
                <Button onClick={onHide} variant={`outline-${variant}`}>
                  Stäng
                </Button>
              </div>
            </>
          )}
        </div>
      </Alert>
    </StyledModal>
  );
};

export default { RadeaLoadingModal };
