import { useState } from 'react';
import Search from 'components/start/search.component';
import { ListGroup } from 'react-bootstrap';
import util from 'utils/util';
import { IKontaktSearchResult } from 'types';
import styled from 'styled-components';

const StyledSearch = styled(Search)`
  width: 100%;
  max-width: 100%;

  input {
    width: 100%;
  }
`;

const ScrollWrapper = styled.div`
  max-height: 100px;
  overflow-y: auto;
  max-height: calc(100vh - 30px - 668px);
  min-height: 95px;
`;

export interface ISearchContactsProps {
  currentId?: number;
  onChange: (result: IKontaktSearchResult) => unknown;
  selected?: null | IKontaktSearchResult;
}

export function SearchContacts({
  selected,
  onChange,
  currentId,
}: ISearchContactsProps) {
  const [lastQuery, setLastQuery] = useState<string | null>(null);
  const [selectedContact, setSelectedContact] =
    useState<IKontaktSearchResult | null>(null);
  const [searchResults, setSearchResult] = useState<IKontaktSearchResult[]>([]);

  return (
    <>
      <div>
        <StyledSearch
          handler={(results, query) => {
            setSearchResult(results.filter((r) => r.id !== currentId));
            setLastQuery(query);
          }}
          clear={() => setSearchResult([])}
        />
        <ScrollWrapper>
          <ListGroup variant="flush">
            {searchResults.map((data, i) => {
              return (
                <ListGroup.Item
                  action
                  href={`#${data.id}`}
                  onClick={() => {
                    onChange(data);
                    setSelectedContact(data);
                  }}
                  active={data.id === (selected ?? selectedContact)?.id}
                >
                  <div>{util.formatDate(data.createdAt)}</div>
                  <h6 style={{ marginBottom: 0 }}>
                    {util.getKontaktNameRemoveDuplicate(data)}
                  </h6>
                  {data.gatuadress}, {data.postnummer} {data.postort}
                </ListGroup.Item>
              );
            })}
            {lastQuery && searchResults.length === 0 && (
              <i>Ingen data tillänglig för "{lastQuery}"</i>
            )}
          </ListGroup>
        </ScrollWrapper>
      </div>
    </>
  );
}

export default SearchContacts;
