import { ChangeEvent, useEffect, useState } from 'react';
import { Form, Col } from 'react-bootstrap';
import { FieldType } from 'types';
import { WarningMessage } from '../../common/radea-alert.component';
import { HusarbeteChanges } from './reducer';
import { generateChangeStyleFunction } from './utils';

export interface IHusarbeteData {
  husarbeteBelopp: number;
  rotavdrag: number;
  materialBelopp: number;
  ovrigtBelopp: number;
  antalTimmarHusarbete: number;
  fordelning: number;
  personnummer1: string;
  personnummer2: string;
}

export interface HusarbeteFormProps {
  data: IHusarbeteData;
  onChange: (
    field: keyof IHusarbeteData,
    value: any,
    type?: FieldType
  ) => unknown;
  changes?: HusarbeteChanges;
}

export const HusarbeteForm = ({
  data,
  onChange,
  changes,
}: HusarbeteFormProps) => {
  const [personnnummerValid, setPersonnummerValid] = useState(true);

  useEffect(() => {
    if (data.personnummer1 === '' && data.personnummer2 === '') {
      setPersonnummerValid(false);
    }
  }, [data.personnummer1, data.personnummer2]);

  const eventUpdateFormData = (type: FieldType = 'text') => {
    return (e: ChangeEvent<any>) => {
      let newValue = e.target.value;
      if (type === 'number') {
        newValue = !isNaN(newValue) ? newValue : data[e.target.id];
      }
      onChange(e.target.id, newValue, type);
    };
  };

  const addChangeStyles =
    generateChangeStyleFunction<HusarbeteChanges>(changes);

  return (
    <>
      {data.husarbeteBelopp > 0 && (
        <>
          <WarningMessage
            show={!personnnummerValid}
            msg={
              'För att kunna lägga till ROT och beräkna fördelning behöver personnummerfält/en matas in.'
            }
          />
          <Form.Label style={{ marginBottom: '5px' }}>
            <strong>Husarbete detaljer:</strong>
          </Form.Label>
          <Form.Row>
            <div className={'form-row-col col-2'}>
              <Form.Group
                as={Col}
                controlId="husarbeteBelopp"
                className={addChangeStyles('husarbeteBelopp')}
              >
                <Form.Label>Summa husarbete</Form.Label>
                <Form.Control
                  placeholder="Belopp husarbete"
                  value={data.husarbeteBelopp}
                  onChange={(event) => {
                    eventUpdateFormData('number')(event);
                    onChange(
                      'rotavdrag',
                      Math.floor(data.husarbeteBelopp * 0.3)
                    );
                  }}
                  autoComplete="off"
                />
              </Form.Group>
              <Form.Group
                as={Col}
                controlId="materialBelopp"
                className={addChangeStyles('materialBelopp')}
              >
                <Form.Label>Summa material</Form.Label>
                <Form.Control
                  placeholder="Belopp material"
                  value={data.materialBelopp}
                  onChange={eventUpdateFormData('number')}
                  autoComplete="off"
                />
              </Form.Group>
            </div>
            <div className={'form-row-col col-2'}>
              <Form.Group
                as={Col}
                controlId="rotavdrag"
                className={addChangeStyles('rotavdrag')}
              >
                <Form.Label>ROT-avdrag</Form.Label>
                <Form.Control
                  placeholder="Belopp ROT-avdrag"
                  value={data.rotavdrag}
                  onChange={eventUpdateFormData('number')}
                  autoComplete="off"
                />
              </Form.Group>
              <Form.Group
                as={Col}
                controlId="ovrigtBelopp"
                className={addChangeStyles('ovrigtBelopp')}
              >
                <Form.Label>Summa övrigt</Form.Label>
                <Form.Control
                  placeholder="Belopp övrigt"
                  value={data.ovrigtBelopp}
                  onChange={eventUpdateFormData('number')}
                  autoComplete="off"
                />
              </Form.Group>
            </div>
            <div className={'form-row-col col-2'}>
              <Form.Group
                as={Col}
                controlId="antalTimmarHusarbete"
                className={addChangeStyles('antalTimmarHusarbete')}
              >
                <Form.Label>Husarbete, timmar</Form.Label>
                <Form.Control
                  placeholder="Husarbete, antal timmar"
                  value={data.antalTimmarHusarbete}
                  onChange={eventUpdateFormData('number')}
                  autoComplete="off"
                />
              </Form.Group>
            </div>
            <Form.Group as={Col} className={'col-6'} controlId="fordelning">
              <span>Fördelning ROT-avdrag:</span>
              <div className={'border-box'}>
                <div className={'col-12 row-direction'}></div>
                <div className={`col-12 row-direction`}>
                  <div className={'radea-col'}>
                    Personnr 1:&nbsp;
                    <strong>
                      {data.personnummer1 === '' ? '-' : data.personnummer1}
                    </strong>
                  </div>
                  <div
                    className={`radea-col${
                      data?.personnummer1 &&
                      addChangeStyles('fordelning', 'select', true)
                    }`}
                  >
                    <Form.Control
                      as="select"
                      value={data.fordelning}
                      onChange={eventUpdateFormData()}
                      custom
                    >
                      <option value={0}>0%</option>
                      <option value={15}>15%</option>
                      <option value={20}>20%</option>
                      <option value={25}>25%</option>
                      <option value={30}>30%</option>
                      <option value={35}>35%</option>
                      <option value={40}>40%</option>
                      <option value={45}>45%</option>
                      <option value={50}>50%</option>
                      <option value={55}>55%</option>
                      <option value={60}>60%</option>
                      <option value={65}>65%</option>
                      <option value={70}>70%</option>
                      <option value={75}>75%</option>
                      <option value={80}>80%</option>
                      <option value={85}>85%</option>
                      <option value={90}>90%</option>
                      <option value={95}>95%</option>
                      <option value={100}>100%</option>
                    </Form.Control>
                  </div>
                </div>
                <div className={`col-12 row-direction`}>
                  <span className={'radea-col'}>
                    Personnr 2:&nbsp;
                    <strong>
                      {data.personnummer2 === '' ? '-' : data.personnummer2}
                    </strong>
                  </span>
                  <span className={'radea-col'}>
                    {100 - parseInt(String(data.fordelning))}%
                  </span>
                </div>
              </div>
            </Form.Group>
          </Form.Row>
        </>
      )}
    </>
  );
};
