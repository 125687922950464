import React, { useState, useEffect, useCallback } from 'react';
import './App.scss';
import { Switch, Route } from 'react-router-dom';
import SkapaKontakt from './components/kontakt/skapa-kontakt.component';
import VisaKontakt from './components/kontakt/visa-kontakt.component';
import Start from './components/start/start.component';
import AdminStart from './components/admin/admin';
import MinaUppgifter from './components/admin/mina-uppgifter.component';
import Scheman from './components/scheman/schemameny.component';
import FakturaMeny from './components/fakturor/fakturameny.component';
import Login from './components/open/login';
import Logout from './components/open/logout';
import RadeaNav from './components/common/menu.componenent';
import CreateEgenkontroll from './components/egenkontroll/create';
import { getSessionInfo, getPersonal } from './components/action/services';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import paths from 'constants/paths';
import moment from 'moment';
import { GlobalStateContext } from 'global.context';
moment.locale('sv');

const history = createBrowserHistory();

function App() {
  const [connected, setConnected] = useState<null | boolean>(false);
  const [globalState, setGlobalState] = useState({
    loggedIn: false,
    session: {
      authenticated: false,
      id: 0,
      login: '',
      namn: '',
      roller: [],
    },
    initialized: false,
    personalList: [],
    setLoggedIn: (session: any) => {
      setGlobalState({ ...globalState, loggedIn: true, session: session });
    },
    setLoggedOut: () => {
      setGlobalState({
        ...globalState,
        loggedIn: false,
        session: {
          authenticated: false,
          id: 0,
          login: '',
          namn: '',
          roller: [],
        },
      });
    },
  });

  const refreshSessionInfo = useCallback(async () => {
    if (!connected) {
      let sessionInfo = await getSessionInfo();
      setConnected(true);
      if (!sessionInfo) {
        sessionStorage.setItem(
          'current-path',
          `${window.location.pathname}${window.location.search}`
        );
        history.replace(`/login`);
      } else {
        globalState.setLoggedIn(sessionInfo);
      }
    }
  }, [globalState, connected]);

  const cachePersonal = useCallback(async () => {
    if (!globalState.initialized) {
      let personal = await getPersonal();
      sessionStorage.setItem('personalList', JSON.stringify(personal));
      setGlobalState({
        ...globalState,
        initialized: true,
        personalList: personal,
      });
    }
  }, [globalState]);

  useEffect(() => {
    if (globalState.loggedIn) {
      cachePersonal();
    }
    if (!globalState.loggedIn) {
      refreshSessionInfo();
    }
  }, [globalState.loggedIn, cachePersonal, refreshSessionInfo]);

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        globalState.setLoggedOut();
        window.location.href = paths.LOGIN;
      } else {
        // Todo: Server not responding. Redirect to error page.
        console.log('Error, no server response.');
      }
      return error;
    }
  );

  return (
    <GlobalStateContext.Provider value={globalState}>
      <Router history={history}>
        <div className="App radea-app">
          <RadeaNav />
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {!connected ? (
                  <div className="spinner-container">
                    <Spinner animation="border" role="status" />
                  </div>
                ) : (
                  <>
                    {globalState.session && (
                      <Switch>
                        <Route exact path={paths.ROOT} component={Start} />
                        <Route path={paths.START} component={Start} />
                        <Route
                          path={paths.CREATE_CONTACT}
                          component={SkapaKontakt}
                        />
                        <Route
                          path={paths.VIEW_CONTACT}
                          component={VisaKontakt}
                        />
                        <Route
                          path={paths.ADMIN_START}
                          component={AdminStart}
                        />
                        <Route path={paths.MY_PAGE} component={MinaUppgifter} />
                        <Route path={paths.SCHUEDULES} component={Scheman} />
                        <Route
                          path={paths.SELF_INSPECTION}
                          component={CreateEgenkontroll}
                        />
                        <Route path={paths.ORDER} component={FakturaMeny} />
                      </Switch>
                    )}
                    <Route exact path={paths.LOGIN} component={Login} />
                    <Route exact path={paths.LOGOUT} component={Logout} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Router>
    </GlobalStateContext.Provider>
  );
}

export default App;
