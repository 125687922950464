import React, { useState, useEffect, useCallback } from 'react';
import { doGet, doPost } from '../action/services';
import { Table, Button, ProgressBar, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import util from 'utils/util';
import { Person, ArrowClockwise, ThreeDots } from 'react-bootstrap-icons';
import SortHeader from './sort-header.component';
import paths from 'constants/paths';
import { resolvePath } from 'utils/path';

const Fakturera = (props) => {
  const [orders, setOrders] = useState([]);
  const [fakturerade, setFakturerade] = useState([]);
  const [felaktiga, setFelaktiga] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [fakturakorning, setFakturakorning] = useState(false);
  const [progress, setProgress] = useState(0);
  //const [faktureradeOrders, setFaktureradeOrders] = useState([]);
  const [antalFakturor, setAntalFakturor] = useState(0);
  const [orderSort, setOrderSort] = useState({
    by: 'leveransdatum',
    direction: 'DESC',
  });
  const [felaktigaSort, setFelaktigaSort] = useState({
    by: 'leveransdatum',
    direction: 'DESC',
  });
  const [faktureradeSort, setFaktureradeSort] = useState({
    by: 'updatedAt',
    direction: 'DESC',
  });

  const getOrders = useCallback(async () => {
    const res = await doPost(`/order/status`, { status: 2, sort: orderSort });
    setOrders(res.data);
  }, [setOrders, orderSort]);

  const getFelaktiga = useCallback(async () => {
    const res = await doPost(`/order/status`, {
      status: 5,
      sort: felaktigaSort,
    });
    setFelaktiga(res.data);
  }, [setFelaktiga, felaktigaSort]);

  const getFakturerade = useCallback(async () => {
    const res = await doPost(`/order/status`, {
      status: 3,
      sort: faktureradeSort,
    });
    setFakturerade(res.data);
  }, [setFakturerade, faktureradeSort]);

  useEffect(() => {
    getOrders();
  }, [orderSort, getOrders]);

  useEffect(() => {
    getFelaktiga();
  }, [felaktigaSort, getFelaktiga]);

  useEffect(() => {
    getFakturerade();
  }, [faktureradeSort, getFakturerade]);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
      getOrders();
      getFakturerade();
      getFelaktiga();
    }
  }, [refresh, getOrders, getFakturerade, getFelaktiga]);

  const fakturera = async () => {
    if (!fakturakorning) {
      setAntalFakturor(orders.length);
      let progressTotal = orders.length + 1;
      let progressCount = 0;
      setFakturakorning(true);
      for (let order of orders) {
        await doGet(`/faktura/skicka/${order.ordernummer}`);
        progressCount++;
        setProgress((progressCount / progressTotal) * 100);
      }
      setProgress(100);
      setTimeout(() => {
        setFakturakorning(false);
        setProgress(0);
        setRefresh(true);
      }, 400);
    }
  };

  const faktureraSingel = async (orderNr) => {
    if (!fakturakorning) {
      setAntalFakturor(1);
      setFakturakorning(true);
      await doGet(`/faktura/skicka/${orderNr}`);
      setProgress(100);
      setTimeout(() => {
        setFakturakorning(false);
        setProgress(0);
        setRefresh(true);
      }, 400);
    }
  };

  return (
    <>
      <h4 className={'padding-top'}>Klar för fakturering</h4>
      <Table hover striped>
        <thead>
          <tr>
            <td>
              <SortHeader
                header="Leveransdatum"
                currentSort={orderSort}
                setSort={setOrderSort}
                sortBy="leveransdatum"
              />
            </td>
            <td>
              <SortHeader
                header="Ordernr."
                currentSort={orderSort}
                setSort={setOrderSort}
                sortBy="ordernummer"
              />
            </td>
            <td>
              <SortHeader
                header="Avsändare"
                currentSort={orderSort}
                setSort={setOrderSort}
                sortBy="bolag"
              />
            </td>
            <td>Affärsomr.</td>
            <td>Mottagare</td>
            <td style={{ textAlign: 'right' }}>Summa, ex moms</td>
            <td>Status</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          <>
            {orders.map((data, i) => {
              return (
                <tr key={`tr1${i}`}>
                  <DataRow obj={data} key={i} sendFaktura={faktureraSingel} />
                  <td>
                    <MenuDropdownComponent
                      kontaktId={data.projekt.kontaktId}
                      sendFaktura={() => faktureraSingel(data.ordernummer)}
                      skickaMenuAlt={'Skicka till Fortnox'}
                    />
                  </td>
                </tr>
              );
            })}
            {orders.length === 0 && (
              <tr>
                <td colSpan="7">
                  <i>Inga ordrar markerade som klara för fakturering</i>
                </td>
              </tr>
            )}
          </>
        </tbody>
      </Table>
      <div className={'button-row right'}>
        {orders.length > 0 && (
          <Button variant="primary" onClick={fakturera}>
            Skicka till Fortnox
          </Button>
        )}
      </div>
      {fakturakorning && (
        <div>
          <span>Skickar {antalFakturor} faktura/or till Fortnox... </span>
          <ProgressBar now={progress} />
        </div>
      )}
      {felaktiga.length > 0 && (
        <>
          <h4 className={'margin-top'}>Felaktiga</h4>
          <Table hover striped>
            <thead>
              <tr>
                <td>
                  <SortHeader
                    header="Leveransdatum"
                    currentSort={felaktigaSort}
                    setSort={setFelaktigaSort}
                    sortBy="leveransdatum"
                  />
                </td>
                <td>
                  <SortHeader
                    header="Ordernr."
                    currentSort={felaktigaSort}
                    setSort={setFelaktigaSort}
                    sortBy="ordernummer"
                  />
                </td>
                <td>
                  <SortHeader
                    header="Avsändare"
                    currentSort={felaktigaSort}
                    setSort={setFelaktigaSort}
                    sortBy="bolag"
                  />
                </td>
                <td>Affärsomr.</td>
                <td>Mottagare</td>
                <td style={{ textAlign: 'right' }}>Summa, ex moms</td>
                <td>Status</td>
                <td>Felmeddelande</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {felaktiga.map((data, i) => {
                return (
                  <tr key={`tr2${i}`}>
                    <DataRow obj={data} key={i} sendFaktura={faktureraSingel} />
                    <td>
                      <MenuDropdownComponent
                        kontaktId={data.projekt.kontaktId}
                        sendFaktura={() => faktureraSingel(data.ordernummer)}
                        skickaMenuAlt={'Skicka till Fortnox'}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      )}
      <h4 className={'margin-top'}>Fakturerade</h4>
      <Table hover striped>
        <thead>
          <tr>
            <td>
              <SortHeader
                header="Leveransdatum"
                currentSort={faktureradeSort}
                setSort={setFaktureradeSort}
                sortBy="leveransdatum"
              />
            </td>
            <td>
              <SortHeader
                header="Ordernr."
                currentSort={faktureradeSort}
                setSort={setFaktureradeSort}
                sortBy="ordernummer"
              />
            </td>
            <td>
              <SortHeader
                header="Avsändare"
                currentSort={faktureradeSort}
                setSort={setFaktureradeSort}
                sortBy="bolag"
              />
            </td>
            <td>Affärsomr.</td>
            <td>Mottagare</td>
            <td style={{ textAlign: 'right' }}>Summa, ex moms</td>
            <td>Status</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {fakturerade.map((data, i) => {
            return (
              <tr key={`tr3${i}`}>
                <DataRow obj={data} key={i} sendFaktura={faktureraSingel} />
                <td>
                  <MenuDropdownComponent
                    kontaktId={data.projekt.kontaktId}
                    sendFaktura={() => faktureraSingel(data.ordernummer)}
                    skickaMenuAlt={'Skicka till Fortnox igen'}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

const DataRow = ({ obj }) => {
  // eslint-disable-next-line no-unused-vars

  return (
    <>
      <td>{util.formatDate(obj.leveransdatum)}</td>
      <td>{obj.ordernummer}</td>
      <td>{util.bolagArr[obj.bolag]}</td>
      <td>{obj.projekt.affarsomrade}</td>
      <td>
        {util.getKontaktNameRemoveDuplicate(obj.projekt.kontakt).trim()},{' '}
        {obj.projekt.kontakt.postort}
      </td>
      <td style={{ textAlign: 'right' }}>
        {util.getOrderTotalBelopp(obj.orderrader)}
      </td>
      <td>{util.orderstatus[obj.status]}</td>
      {obj.statusMsg && obj.status !== 3 && <td>{obj.statusMsg}</td>}
    </>
  );
};

const MenuDropdownComponent = (props) => {
  const { kontaktId, sendFaktura, skickaMenuAlt } = props;
  return (
    <Nav className="dropdown-nav faktura-dropdown">
      <NavDropdown
        title={<ThreeDots size={30} color="rgba(50,50,50, 0.6)" />}
        id="collapsible-nav-dropdown"
      >
        <Link
          to={resolvePath(paths.VIEW_CONTACT, kontaktId)}
          className="dropdown-item"
        >
          <Person />
          Gå till kund
        </Link>
        <span className="dropdown-item" onClick={sendFaktura}>
          <ArrowClockwise /> {skickaMenuAlt}
        </span>
        {false && (
          <>
            <NavDropdown.Divider />
            <NavDropdown.Item href={paths.LOGOUT}>
              Gör klar för fakturering
            </NavDropdown.Item>
            <NavDropdown.Item href={paths.LOGOUT}>
              Ta bort klarmarkering
            </NavDropdown.Item>
          </>
        )}
      </NavDropdown>
    </Nav>
  );
};

export default Fakturera;
