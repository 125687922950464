import { useContext } from 'react';
import { usePersonalList } from './usePersonalList';
import { GlobalStateContext } from 'global.context';
import { IPersonal } from 'types';

export function useCurrentUser(): IPersonal | null {
  const { session } = useContext(GlobalStateContext);
  const personalList = usePersonalList();

  if (!session.authenticated) {
    return null;
  }

  return personalList.find((p) => p?.id === session.id) || null;
}
