import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import util from 'utils/util';

const AktivitetModal = ({ show, onHide, list }) => {
  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Aktiviteter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="max-height-div">
            {list.map((item, i) => {
              return (
                <>
                  <span key={`${i}ii`}>
                    <strong>{item.typ}: </strong>
                    {util.formatDate(item.tidpunkt, false)}
                    <br />
                    {item.aktivitet}
                    <br />
                  </span>
                  <br />
                </>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => onHide()}>
            Stäng
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AktivitetModal;
