import { ReactNode, useEffect, useState, useContext, useCallback } from 'react';
import { GlobalStateContext } from '../../global.context';
import { Spinner } from 'react-bootstrap';
import { RadeaAlert } from './radea-alert.component';
import { hasRole } from 'utils/auth';
import { RoleType } from 'types';

export interface IRollKollProps {
  role: RoleType | RoleType[];
  children: ReactNode;
  msg?: boolean;
}

export const RollKoll = ({ role, children, msg }: IRollKollProps) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const { session } = useContext(GlobalStateContext);
  const [checked, setChecked] = useState(false);

  const rollCheck = useCallback(() => {
    if (!session.authenticated) {
      return;
    }
    setIsAdmin(hasRole(role, session));

    setChecked(true);
  }, [session, role]);

  useEffect(() => {
    if (!checked) {
      rollCheck();
    }
  }, [checked, rollCheck]);

  return (
    <>
      {isAdmin && <>{children}</>}
      {!isAdmin && msg && (
        <>
          {!checked && (
            <div className="spinner-container">
              <Spinner animation="border" role="status" />
            </div>
          )}
          {checked && (
            <RadeaAlert
              msg={
                'Ditt konto har inte rätt roll för att kunna se den här vyn.'
              }
              type="danger"
              show={true}
            />
          )}
        </>
      )}
    </>
  );
};
