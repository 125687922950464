import { useEffect, useState } from 'react';
import { Card, Button, Form, Col, Modal, Accordion } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FileInput } from 'components/common/file-input';
import { getAxiosInstance } from 'components/action/services';
import { IProjektShort, useMyProjekts } from 'hooks/useMyProjekts';
import RadeaEditor from 'components/common/radea-editor';
import {
  IRadeaLoadingModalProps,
  RadeaLoadingModal,
  RadeaLoadingModalState,
} from 'components/common/radea-loading-modal';
import paths from 'constants/paths';
import joi from 'joi';
import { useQuery } from 'hooks/useQuery';
import { AxiosError, AxiosResponse } from 'axios';
import draftToHtml from 'draftjs-to-html';
import { EgenkontrollList } from './list';

const axios = getAxiosInstance();

export const egenkontrollSchema = joi.object({
  projektId: joi.number().required().messages({
    'any.required': 'Du måste ange ett projekt',
  }),
  content: joi.string().required().messages({
    'string.empty': 'Du måste ange en anteckning',
  }),
  files: joi.array(),
});

export interface IEgenkontrollFormData {
  content: string;
  files: File[];
}

export interface IEgenkontrollProps {}

export function CreateEgenkontroll(props: IEgenkontrollProps) {
  const query = useQuery();
  const [showSaveModal, setShowSaveModal] = useState(false);
  const myProjekts = useMyProjekts(query.get('projektId') || '');
  const [loadingModal, setLoadingModal] = useState<IRadeaLoadingModalProps>({
    show: false,
    msg: '',
    state: RadeaLoadingModalState.loading,
  });
  const [selectedProjekt, setSelectedProjekt] = useState<null | IProjektShort>(
    null
  );

  const [formData, setFormData] = useState<IEgenkontrollFormData>({
    content: '',
    files: [],
  });

  useEffect(() => {
    if (selectedProjekt) {
      return;
    }
    const p = myProjekts?.find((p) => String(p.id) === query.get('projektId'));
    if (!p) {
      return;
    }

    setSelectedProjekt(p);
  }, [myProjekts, selectedProjekt, query]);
  let history = useHistory();

  const handleChange = (e) => {
    let { id, value } = e.target;

    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const createEgenkontroll = async () => {
    const base: IRadeaLoadingModalProps = {
      show: true,
      state: RadeaLoadingModalState.loading,
      progress: 0,
      showCloseButton: false,
      msg: 'Skickar egenkontroll...',
    };
    setLoadingModal(base);
    const fd = new FormData();
    formData.files.forEach((f) => {
      fd.append('files', f);
    });
    fd.append('content', formData.content);
    fd.append('projektId', String(selectedProjekt?.id));
    fd.append('raw', draftToHtml(JSON.parse(formData.content), {}, true, {}));
    let result: AxiosResponse<any>;
    try {
      result = await axios.post(`/egenkontroll`, fd, {
        onUploadProgress: (progress) => {
          setLoadingModal(() => ({
            ...base,
            progress: (progress.loaded / (progress?.total || 1)) * 100,
          }));
        },
      });
    } catch (error) {
      setLoadingModal(() => ({
        ...base,
        msg: ((error as AxiosError)?.response?.data as any)?.msg,
        state: RadeaLoadingModalState.error,
        showCloseButton: true,
      }));
      return;
    }
    if (result.data && result.data.success) {
      setLoadingModal(() => ({
        ...base,
        msg: 'Egenkontrollen har skapats',
        state: RadeaLoadingModalState.success,
        showCloseButton: true,
      }));
    } else if (result.data && result.data.msg) {
      setLoadingModal(() => ({
        ...base,
        msg: result.data.msg,
        state: RadeaLoadingModalState.error,
        showCloseButton: true,
      }));
    } else {
      setLoadingModal(() => ({
        ...base,
        msg: 'Fel på kommunikationen med servern, kontakta supporten.',
        state: RadeaLoadingModalState.error,
        showCloseButton: true,
      }));
    }
  };

  return (
    <>
      <Card className="form-card">
        <RadeaLoadingModal
          {...loadingModal}
          onHide={() => {
            if (loadingModal.state === RadeaLoadingModalState.success) {
              history.push(paths.START);
              return;
            }
            setLoadingModal({
              ...loadingModal,
              show: false,
            });
          }}
        />
        <Modal
          show={showSaveModal}
          onHide={() => {
            setShowSaveModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Skicka egenkontroll</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Är du säker?</h5>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="link"
              onClick={() => {
                setShowSaveModal(false);
              }}
            >
              Avbryt
            </Button>
            <Button
              variant="success"
              onClick={() => {
                setShowSaveModal(false);
                createEgenkontroll();
              }}
            >
              Skicka
            </Button>
          </Modal.Footer>
        </Modal>
        <Card.Header as="h5">Egenkontroll</Card.Header>
        <Card.Body>
          <Form>
            <Form.Row style={{ marginTop: '10px' }}>
              <Form.Group as={Col}>
                <Card.Title>{formatProjektDisplay(selectedProjekt)}</Card.Title>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="efternamn">
                <Form.Label>Anteckning</Form.Label>
                <RadeaEditor
                  initialText={''}
                  onChange={handleChange}
                  id="content"
                  readOnly={false}
                  placeholder="Fyll i anteckning"
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="login">
                <Form.Label>Bilagor</Form.Label>
                <FileInput
                  acceptedFileTypes={[
                    'png',
                    'jpeg',
                    'jpg',
                    'docx',
                    'pptx',
                    'ppt',
                    'xlsx',
                    'pdf',
                  ]}
                  uploadLabel={'Lägg till bilaga'}
                  onChange={(files) =>
                    handleChange({ target: { id: 'files', value: files } })
                  }
                />
              </Form.Group>
            </Form.Row>
          </Form>
        </Card.Body>
        <Card.Footer className="text-right">
          <Button
            variant="link"
            onClick={() => {
              history.goBack();
            }}
          >
            Tillbaka
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              const { error } = egenkontrollSchema.validate({
                ...formData,
                projektId: selectedProjekt?.id,
              });
              if (!error) {
                setShowSaveModal(true);
                return false;
              }
              setLoadingModal({
                show: true,
                msg: error.message,
                state: RadeaLoadingModalState.error,
                showCloseButton: true,
              });
            }}
          >
            Skicka
          </Button>
        </Card.Footer>
      </Card>
      {selectedProjekt?.id && (
        <Accordion defaultActiveKey="0" style={{ width: '100%' }}>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              Tidigare egenkontroller
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0" style={{ padding: '15px' }}>
              <EgenkontrollList
                projektId={selectedProjekt?.id}
                hideCreateButton
              />
            </Accordion.Collapse>
          </Card>
        </Accordion>
      )}
    </>
  );
}

function formatProjektDisplay(p: IProjektShort | null) {
  if (!p) {
    return '';
  }
  return `Projekt ${p.id} ${p.kontakt?.fornamn} 
  ${p.kontakt?.efternamn}
  ${p.kontakt?.foretagsnamn && ', '}
  ${p.kontakt?.foretagsnamn}`;
}

export default CreateEgenkontroll;
