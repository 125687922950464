import React, { useState, useContext } from 'react';
import { doPost, getAxiosInstance } from '../action/services';
import { Table, Button, Modal, Form, Col, Container } from 'react-bootstrap';
import {
  PlusSquareFill,
  PencilFill,
  Download,
  Trash,
} from 'react-bootstrap-icons';
import util from 'utils/util';
import { IRadeaAlertProps, RadeaAlert } from '../common/radea-alert.component';
import useKategori from 'utils/hooks/useKategori';
import kategori from '../../constants/kategori';
import { GlobalStateContext } from '../../global.context';
import { formatName } from 'utils/personal';
import { confirm } from 'utils/ui';
import useAktiviteter from './hooks/useAktiviteter';

const axios = getAxiosInstance();

const Aktiviteter = (props) => {
  const { projektId } = props;
  const { session } = useContext(GlobalStateContext);
  const [aktiviteter, refreshAktiviteter] = useAktiviteter(projektId);
  const [dokumentList, setDokumentList] = useState<any[]>([]);
  const [formDokumentValid, setFormDokumentValid] = useState(false);
  const [dokumentValidationMsg, setDokumentValidationMsg] = useState('');
  const [alert, setAlert] = useState<IRadeaAlertProps>({
    show: false,
    msg: '',
    type: 'info',
  });
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [aktivitetList] = useKategori(kategori.Aktivitetstyp, false);

  const [formData, setFormData] = useState({
    tidpunkt: util.getDateObject(),
    typ: 'Ospec.',
    aktivitet: '',
    projektId: projektId,
    personalId: session ? session.id : 0,
  });

  const [formDokumentData, setFormDokumentData] = useState({
    namn: '',
    url: '',
  });

  const updateFormData = (key, value) => {
    let temp = {
      tidpunkt: formData.tidpunkt,
      typ: key === 'typ' ? value : formData.typ,
      aktivitet: key === 'aktivitet' ? value : formData.aktivitet,
      personalId: session.id,
      projektId: projektId,
    };
    setFormData(temp);
  };

  const clearFormData = () => {
    let temp = {
      tidpunkt: util.getDateObject(),
      typ: 'Ospec.',
      aktivitet: '',
      personalId: session.id,
      projektId: projektId,
    };
    setFormData(temp);

    const tempDokument = {
      namn: '',
      url: '',
    };
    setFormDokumentData(tempDokument);
    setFormDokumentValid(validateDokument(tempDokument));
    setDokumentList([]);
  };

  const updateFormDokumentData = (e) => {
    let key = e.target.id;
    let value = e.target.value;
    let temp = {
      namn: key === 'namn' ? value : formDokumentData.namn,
      url: key === 'url' ? value : formDokumentData.url,
    };
    setFormDokumentData(temp);
    setFormDokumentValid(validateDokument(temp));
  };

  const addDokument = () => {
    if (!formDokumentValid) {
      showAlert(dokumentValidationMsg, 'danger');
    }
    dokumentList.push({
      namn: formDokumentData.namn,
      url: formDokumentData.url,
    });
    setDokumentList(dokumentList);
    setFormDokumentData({ namn: '', url: '' });
    setFormDokumentValid(false);
  };

  const editDokument = (dok) => {
    setFormDokumentData({ namn: dok.namn, url: dok.url });
    deleteDokument(dok);
    setFormDokumentValid(validateDokument(dok));
  };

  const deleteDokument = (dok) => {
    let newList: any[] = [];
    for (let listDok of dokumentList) {
      if (!isSame(dok, listDok)) {
        newList.push(listDok);
      }
    }
    setDokumentList(newList);
  };

  const isSame = (dok1, dok2) => {
    return dok1.namn === dok2.namn && dok1.url === dok2.url;
  };

  const deleteAktivitet = async (id) => {
    let result = await doPost('/aktiviteter/delete', { id: id });
    if (result.data.success) {
      showAlert('Aktiviteten har tagits bort.', 'success');
      refreshAktiviteter();
    } else {
      showAlert(
        'Fel, aktiviteten kunde inte tas bort, kontakta supporten.',
        'danger'
      );
    }
  };

  const updateAktivitet = async (
    id: number | undefined,
    visibleToCustomer: boolean
  ) => {
    if (!id) {
      return;
    }
    const action = visibleToCustomer ? 'visa' : 'dölja';
    const confirmText = visibleToCustomer ? 'Visa' : 'Dölj';
    const shouldUpdate = await confirm(
      `Är du säker på att du vill ${action} aktiviteten?`,
      {
        title: `${confirmText} aktivitet`,
        confirmText,
      }
    );
    if (!shouldUpdate) {
      return;
    }
    let result = await axios.put(`/aktiviteter/${id}`, {
      visibleToCustomer,
    });
    if (result.data.success) {
      showAlert(
        `Aktiviteten ${visibleToCustomer ? 'visas' : 'döljs'} nu för kunden`,
        'success'
      );
      refreshAktiviteter();
    } else {
      showAlert(
        'Fel, aktiviteten kunde inte uppdateras, kontakta supporten.',
        'danger'
      );
    }
  };

  const createAktivitet = async () => {
    let result = await doPost('/aktiviteter/create', {
      aktivitet: formData.aktivitet,
      personalId: session.id,
      projektId: formData.projektId,
      tidpunkt: util.getDateObject().date,
      typ: formData.typ,
      dokument: dokumentList,
    });
    if (result.data.success) {
      showAlert('Aktiviteten har lagts till.', 'success');
      refreshAktiviteter();
      clearFormData();
    } else {
      showAlert(
        'Fel, aktiviteten kunde inte läggas till, kontakta supporten.',
        'danger'
      );
    }
  };

  const validateDokument = (dok) => {
    if (dok.namn === '' && dok.url === '') {
      return false;
    }
    if (dok.namn === '') {
      setDokumentValidationMsg('Namnet får inte vara tomt.');
    }
    if (!util.validURL(dok.url)) {
      setDokumentValidationMsg('Dokumentlänken är felaktig.');
    }
    return dok.namn !== '' && util.validURL(dok.url);
  };

  const showAlert = (msg, type) => {
    setAlert({ show: true, msg: msg, type: type });
    setTimeout(() => {
      setAlert({
        show: false,
        msg: '',
        type: 'success',
      });
    }, 2000);
  };

  return (
    <>
      <AktivitetsList
        list={aktiviteter}
        deleteAktivitet={deleteAktivitet}
        updateAktivitet={updateAktivitet}
      />
      {showForm && (
        <Container className="bordered-container row-container aktivitet-container">
          <Form className="atgard-form">
            <Form.Row>
              <Form.Group as={Col} controlId="year">
                <Form.Label>Aktivitetstyp</Form.Label>
                <Form.Control
                  as="select"
                  value={formData.typ}
                  onChange={(e) => {
                    updateFormData('typ', e.target.value);
                  }}
                  custom
                >
                  {aktivitetList.map((data, i) => {
                    return (
                      <option key={`y${i}`} value={data.namn}>
                        {data.namn}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="ak">
                <Form.Control
                  as="textarea"
                  rows={6}
                  placeholder="Notering"
                  value={formData.aktivitet}
                  onChange={(e) => {
                    updateFormData('aktivitet', e.target.value);
                  }}
                />
              </Form.Group>
            </Form.Row>
          </Form>
          <Form className="atgard-form">
            <Form.Row>
              <Form.Group as={Col} controlId="namn">
                <Form.Label>Dokumentlänkar</Form.Label>
                <Form.Control
                  placeholder="Dokumentnamn, tex offert"
                  value={formDokumentData.namn}
                  onChange={(e) => {
                    updateFormDokumentData(e);
                  }}
                  autoComplete="off"
                />
              </Form.Group>
              <Form.Group as={Col} controlId="url">
                <Form.Control
                  placeholder="https://..."
                  value={formDokumentData.url}
                  onChange={(e) => {
                    updateFormDokumentData(e);
                  }}
                  autoComplete="off"
                />
              </Form.Group>
              <Form.Group>
                <PlusSquareFill
                  size={30}
                  color={formDokumentValid ? '#669eb7' : '#ccc'}
                  className={formDokumentValid ? 'click-item' : ''}
                  onClick={() => {
                    if (formDokumentValid) {
                      addDokument();
                    }
                  }}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="ak">
                <DokumentList
                  list={dokumentList}
                  editDokument={editDokument}
                  deleteDokument={deleteDokument}
                />
              </Form.Group>
            </Form.Row>
          </Form>
        </Container>
      )}

      <div className="button-row right bottom-item">
        {showForm && (
          <>
            <Button
              variant={'link'}
              onClick={() => {
                clearFormData();
                setShowForm(false);
              }}
            >
              Avbryt
            </Button>
            <Button
              onClick={() => {
                setShowSaveModal(true);
              }}
            >
              Lägg till
            </Button>
          </>
        )}
        {!showForm && (
          <Button
            variant={'link'}
            onClick={() => {
              setShowForm(true);
            }}
          >
            Lägg till ny aktivitet
          </Button>
        )}
      </div>

      <RadeaAlert msg={alert.msg} type={alert.type} show={alert.show} />

      <Modal
        show={showSaveModal}
        onHide={() => {
          setShowSaveModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Lägg till aktivitet</Modal.Title>
        </Modal.Header>
        <Modal.Body>Är du säker?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            onClick={() => {
              setShowSaveModal(false);
            }}
          >
            Avbryt
          </Button>
          <Button
            variant="success"
            onClick={() => {
              setShowSaveModal(false);
              createAktivitet();
            }}
          >
            Lägg till
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const AktivitetsList = (props) => {
  const { list, deleteAktivitet, updateAktivitet } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  return (
    <>
      <Table hover striped size="sm" className="aktivitets-list">
        <thead>
          <tr>
            <td className={'aktivitet'}>Aktivitet</td>
            <td>Notering</td>
            <td>Dokument</td>
            <td style={{ width: '120px' }}>Visas för kund</td>
            <td> </td>
          </tr>
        </thead>
        <tbody>
          {list.map((data, i) => {
            return (
              <tr key={`alist${i}`}>
                <td className={'aktivitet'}>
                  {data.typ}
                  <br />
                  {formatName(data.personal)}
                  <br />
                  {util.getDateObject(data.tidpunkt).str}
                </td>
                <td>
                  {data.aktivitet === '' && <i>Utan notering</i>}
                  {data.aktivitet !== '' && (
                    <>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: util.replaceCarriageReturn(data.aktivitet),
                        }}
                      ></span>
                    </>
                  )}
                </td>
                <td className={'dokument'}>
                  <DokumentList list={data.dokument} readOnly={true} />
                </td>
                <td>
                  <Form.Check
                    checked={data?.visibleToCustomer}
                    onChange={(e) => {
                      updateAktivitet(data?.id, e.target.checked);
                    }}
                  />
                </td>
                <td className="align-right click-item">
                  <Trash
                    onClick={() => {
                      setShowDeleteModal(true);
                      setDeleteItem(data.id);
                    }}
                    color={'#666'}
                  />
                </td>
              </tr>
            );
          })}
          {list.length === 0 && (
            <tr>
              <td colSpan={5}>
                <i>Inga aktiveteter registrerade</i>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <Modal
        show={showDeleteModal}
        onHide={() => {
          setShowDeleteModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Ta bort aktivitet</Modal.Title>
        </Modal.Header>
        <Modal.Body>Ta bort aktiviteten?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            onClick={() => {
              setShowDeleteModal(false);
            }}
          >
            Avbryt
          </Button>
          <Button
            variant="success"
            onClick={() => {
              setShowDeleteModal(false);
              deleteAktivitet(deleteItem);
            }}
          >
            Ta bort
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const DokumentList = (props) => {
  const { list, editDokument, deleteDokument, readOnly } = props;
  return (
    <Table hover striped size="sm" className="dokument-table">
      <tbody>
        {list.length === 0 && (
          <tr>
            <td>
              <i>Inga dokument</i>
            </td>
          </tr>
        )}
        {list.map((data, i) => {
          return (
            <tr key={`${i}dok`}>
              {readOnly && (
                <td>
                  <a href={data.url} target="_blank" rel="noopener noreferrer">
                    <Download style={{ marginRight: '4px' }} />
                  </a>
                  {data.namn}
                </td>
              )}
              {!readOnly && (
                <>
                  <td>
                    <a
                      href={data.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Download style={{ marginRight: '4px' }} />
                    </a>
                    {data.namn}{' '}
                    <PencilFill
                      onClick={() => {
                        editDokument(data);
                      }}
                      size={12}
                      color={'#669eb7'}
                      className={'click-item'}
                    />
                  </td>
                  <td className="align-right click-item">
                    <Trash
                      onClick={() => {
                        deleteDokument(data);
                      }}
                      color={'#666'}
                    />
                  </td>
                </>
              )}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
export default Aktiviteter;
