import { Table } from 'react-bootstrap';
import { Pencil } from 'react-bootstrap-icons';
import util from 'utils/util';
import { IOrderRow } from 'types';
import { RowChange, RowDataChange } from './reducer';
import { generateChangeStyleFunction } from './utils';

export interface IOrderraderProps {
  list: IOrderRow[];
  editArtikel: (data: IOrderRow, index: number) => unknown;
  changes: RowChange[];
  rotavdrag: number;
}

export const Orderrader = ({
  list,
  editArtikel,
  rotavdrag,
  changes,
}: IOrderraderProps) => {
  return (
    <>
      <Table
        hover
        striped
        size="sm"
        className="order-list border-bottom radea-table"
      >
        <thead>
          <tr>
            <td>Artikelnr</td>
            <td>Husarb.</td>
            <td>Beskrivn.</td>
            <td>Extrainfo.</td>
            <td>Antal</td>
            <td>Pris</td>
            <td>
              Summa,
              <br />
              ink. moms
            </td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {(changes || [])
            ?.filter((change) => change.type === 'deleted')
            .map((change, index) => (
              <Row
                index={index + list.length}
                data={change.data}
                editArtikel={editArtikel}
                changes={changes}
              ></Row>
            ))}
          {list.map((data, i) => (
            <Row
              data={data}
              editArtikel={editArtikel}
              index={i}
              changes={changes}
            />
          ))}
          {list.length > 0 && rotavdrag > 0 && (
            <tr>
              <td>*</td>
              <td></td>
              <td>ROT-avdrag</td>
              <td></td>
              <td></td>
              <td></td>
              <td>-{rotavdrag}</td>
              <td></td>
            </tr>
          )}
          {list.length === 0 && (
            <tr>
              <td colSpan={7}>
                <i>Det finns inga artiklar inlagda</i>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

interface IRowProps {
  data: IOrderRow;
  index: number;
  changes?: RowChange[];
  editArtikel: (data: IOrderRow, index: number) => unknown;
}

function Row({ data, index, changes, editArtikel }: IRowProps) {
  const addChangeStyles = (id?: number) => {
    const change = changes?.find((c) => c.id === id);

    if (!change) {
      return () => '';
    }
    return generateChangeStyleFunction<RowDataChange>(change.fields, true);
  };

  const isRemoved = !!changes?.find(
    (change) => change.id === data.id && change.type === 'deleted'
  );

  return (
    <tr
      key={data.id === -1 ? `added${index}` : String(data.id)}
      className={`${
        data?.id === -1 &&
        changes?.find((c) => c.index === index && c.id === -1)
          ? 'added'
          : isRemoved
          ? 'removed'
          : ''
      }`}
    >
      <td className={addChangeStyles(data.id)('artikelnummer')}>
        {data.artikelnummer}
      </td>
      <td className={addChangeStyles(data.id)('husarbete')}>
        {data.husarbete ? 'Ja' : 'Nej'}
      </td>
      <td className={addChangeStyles(data.id)('beskrivning')}>
        {util.shortenText(data.beskrivning, 40)}
      </td>
      <td className={addChangeStyles(data.id)('extrainformation')}>
        {util.shortenText(data.extrainformation, 40)}
      </td>
      <td className={addChangeStyles(data.id)('antal')}>{data.antal}</td>
      <td className={addChangeStyles(data.id)('pris')}>{data.pris}</td>
      <td>{Math.round(data.antal * data.pris * 1.25)}</td>
      <td>
        {!isRemoved && (
          <Pencil
            size={20}
            className={'click-item blue'}
            onClick={() => {
              editArtikel(data, index);
            }}
          />
        )}
      </td>
    </tr>
  );
}
