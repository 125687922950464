import { useState, useEffect, useCallback } from 'react';
import { doGetList } from 'components/action/services';

export default function useAktiviteter(projektId): [any[], () => unknown]{
  const [aktiviteter, setAktiviteter] = useState([]);
  
  const getAktiviteter = useCallback(async () => {
    const res = await doGetList(`/aktiviteter/projekt/${projektId}`);
    setAktiviteter(res.data);
  }, [projektId]);


  useEffect(() => {
    getAktiviteter();
  }, [projektId, getAktiviteter]);

  return [aktiviteter, () => {
    getAktiviteter();
  }];
}
