import { useState } from 'react';
import SkapaTidsbokning from '../projekt/skapatidsbokning.component';
import PrivataBokningar from './privata-bokningar.component';
import { Button } from 'react-bootstrap';
import { useCurrentUser } from 'hooks/useCurrentUser';

const PrivatBokning = () => {
  const [showDeleteList, setShowDeleteList] = useState(false);
  const refresh = () => {};
  const currentUser = useCurrentUser();

  return (
    <>
      {!showDeleteList && (
        <>
          <div className="button-row right padding-top">
            <Button
              variant="primary"
              onClick={() => {
                setShowDeleteList(true);
              }}
            >
              Ta bort bokningar
            </Button>
          </div>
          <SkapaTidsbokning
            projektId={null}
            atgardstyper={[0]}
            refreshList={refresh}
            ort={null}
            forPersonal={currentUser}
          />
        </>
      )}
      {showDeleteList && (
        <>
          <PrivataBokningar />
          <div className="button-row right padding-top">
            <Button
              variant="primary"
              onClick={() => {
                setShowDeleteList(false);
              }}
            >
              Tillbaka
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default PrivatBokning;
