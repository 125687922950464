import joi from 'joi';

export interface IFormatNameObject {
  fornamn?: string;
  efternamn?: string;
}

export function formatName(
  personal: undefined | IFormatNameObject,
  emptyValue = ''
): string {
  if (!personal?.fornamn && !personal?.efternamn) {
    return emptyValue;
  }

  let name = personal?.fornamn || '';

  if (name && personal?.efternamn) {
    name += ` ${personal?.efternamn}`;
  }

  return name;
}

export const personalSchema = joi.object({
  id: joi.number(),
  aktiv: joi.number(),
  fornamn: joi.string().required().messages({
    'string.empty': 'Användaren måste ha ett förnamn.',
  }),
  efternamn: joi.string(),
  epost: joi
    .string()
    .email({ allowUnicode: false, tlds: { allow: false } })
    .allow('')
    .messages({
      'string.email': 'E-postadressen är inte korrekt ifylld.',
    }),
  login: joi
    .string()
    .min(3)
    .max(20)
    .pattern(/^[A-z]+$/)
    .required()
    .error(
      () =>
        new Error(
          'Användarnamnet måste vara mellan 3 och 20 tecken långt, a - z.'
        )
    ),
  roller: joi.array().items(joi.string()),
  password: joi
    .string()
    .min(8)
    .max(156)
    .when('id', {
      not: joi.exist(),
      then: joi.required(),
      otherwise: joi.allow(''),
    })
    .messages({
      'string.empty': 'Du måste ange ett lösenord',
      'string.min':
        'Lösenordet måste vara minst åtta tecken långt, gärna med siffror och bokstäver blandat.',
      'string.max': 'Lösenordet får max vara 156 tecken långt',
    }),
  passwordagain: joi
    .when('password', {
      is: joi.exist(),
      then: joi.string().required().valid(joi.ref('password')),
    })
    .error(() => new Error('Du har inte skrivit samma lösenord två gånger')),
});
