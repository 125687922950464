import React, { useState, useEffect, useCallback } from 'react';
import { doPost } from '../action/services';
import { Table } from 'react-bootstrap';
import util from 'utils/util';
import { BoxArrowUp } from 'react-bootstrap-icons';
import SortHeader from './sort-header.component';

const Ordrar = (props) => {
  const [orders, setOrders] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [orderSort, setOrderSort] = useState({
    by: 'leveransdatum',
    direction: 'DESC',
  });

  const getOrders = useCallback(async () => {
    const res = await doPost(`/order/ofakturerade`, { sort: orderSort });
    setOrders(res.data);
  }, [setOrders, orderSort]);

  useEffect(() => {
    getOrders();
  }, [orderSort, getOrders]);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
      getOrders();
    }
  }, [refresh, getOrders]);

  const setStatusKlar = async (id) => {
    const res = await doPost('/order/status/set', {
      id: id,
      status: 2,
      statusMsg: '',
    });
    if (res.data.success) {
      setRefresh(true);
    }
  };

  return (
    <>
      <h4 className={'padding-top'}>Ordrar</h4>
      <Table hover striped>
        <thead>
          <tr>
            <td>
              <SortHeader
                header="Leveransdatum"
                currentSort={orderSort}
                setSort={setOrderSort}
                sortBy="leveransdatum"
              />
            </td>
            <td>
              <SortHeader
                header="Ordernr."
                currentSort={orderSort}
                setSort={setOrderSort}
                sortBy="ordernummer"
              />
            </td>
            <td>
              <SortHeader
                header="Avsändare"
                currentSort={orderSort}
                setSort={setOrderSort}
                sortBy="bolag"
              />
            </td>
            <td>Affärsomr.</td>
            <td>Mottagare</td>
            <td style={{ textAlign: 'right' }}>Summa, ex moms</td>
            <td>Status</td>
            <td>Klar för fakt.</td>
          </tr>
        </thead>
        <tbody>
          <>
            {orders.map((data, i) => {
              return (
                <tr key={`tr1${i}`}>
                  <DataRow obj={data} key={i} />
                  <td>
                    <BoxArrowUp
                      className={'click-item'}
                      size={25}
                      color="#66b79e"
                      onClick={() => {
                        setStatusKlar(data.id);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
            {orders.length === 0 && (
              <tr>
                <td colSpan="7">
                  <i>Inga ordrar tillgängliga</i>
                </td>
              </tr>
            )}
          </>
        </tbody>
      </Table>
    </>
  );
};

const DataRow = ({ obj }) => {
  // eslint-disable-next-line no-unused-vars
  return (
    <>
      <td>{util.formatDate(obj.leveransdatum)}</td>
      <td>{obj.ordernummer}</td>
      <td>{util.bolagArr[obj.bolag]}</td>
      <td>{obj.projekt.affarsomrade}</td>
      <td>
        {util.getKontaktNameRemoveDuplicate(obj.projekt.kontakt).trim()},{' '}
        {obj.projekt.kontakt.postort}
      </td>
      <td style={{ textAlign: 'right' }}>
        {util.getOrderTotalBelopp(obj.orderrader)}
      </td>
      <td>{util.orderstatus[obj.status]}</td>
    </>
  );
};

export default Ordrar;
