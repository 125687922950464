import React, { useEffect, useState, useCallback } from 'react';
import { Button, Modal, Form, Col, Container } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import util from 'utils/util';
import { RadeaAlert } from '../common/radea-alert.component';
import DatePicker, { registerLocale } from 'react-datepicker';
import sv from 'date-fns/locale/sv';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { doPost, doGetList } from '../action/services';
import { Spinner } from 'react-bootstrap';
import { formatName } from 'utils/personal';
registerLocale('sv', sv);

const Bestallning = (props) => {
  const { kontakt } = props;
  const [list, setList] = useState([]);
  const [alert, setAlert] = useState({ show: false, msg: '', type: '' });
  const [showForm, setShowForm] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const [formData, setFormData] = useState({
    kontaktId: kontakt.id,
    artikelId: 0,
    antal: 1,
    skickasDatum: new Date(),
    bolagId: 1,
  });

  const [artikelList, setArtikelList] = useState([]);

  const getArtikelList = useCallback(async () => {
    if (artikelList.length === 0) {
      const res = await doGetList(`/artiklar/radonova/all`);
      if (res.data) {
        if (res.data.length === 0) {
          setArtikelList([
            {
              article_id: 0,
              description: 'Inga artiklar kunde laddas från Radonova.',
            },
          ]);
          showAlert('Ingen kontakt med Radonova.', 'danger');
        } else {
          setArtikelList(res.data);
        }
      }
    }
  }, [setArtikelList, artikelList]);

  useEffect(() => {
    getArtikelList();
  }, [getArtikelList]);

  const getBestallningar = useCallback(async () => {
    const res = await doGetList(`/kontakter/bestallningar/get/${kontakt.id}`);
    setList(res.data);
  }, [kontakt]);

  useEffect(() => {
    getBestallningar();
  }, [getBestallningar]);

  const updateFormData = (e) => {
    const { id, value } = e.target;
    let beskrivning = {};
    if (id === 'artikelId') {
      beskrivning = {
        beskrivning: e.target.options[e.target.selectedIndex].text,
      };
    }
    setFormData({
      ...formData,
      ...beskrivning,
      [id]: value,
    });
  };

  const setSkickasDatum = (date) => {
    let temp = {
      kontaktId: formData.kontaktId,
      artikelId: formData.artikelId,
      antal: formData.antal,
      skickasDatum: date,
      bolagId: formData.bolagId,
    };
    setFormData(temp);
  };

  const clearFormData = () => {
    let temp = {
      kontaktId: formData.kontaktId,
      artikelId: 0,
      antal: 1,
      skickasDatum: new Date(),
      bolagId: 1,
    };
    setFormData(temp);
  };

  const validateFormData = () => {
    if (formData.bolagId === 0) {
      showAlert('Du måste välja beställande bolag.', 'danger');
      return false;
    }
    if (formData.artikelId === 0) {
      showAlert('Du måste välja en artikel i listan.', 'danger');
      return false;
    }
    if (formData.antal === 0) {
      showAlert('Du måste välja antal.', 'danger');
      return false;
    }
    if (moment(formData.skickasDatum).isBefore(moment(), 'day')) {
      showAlert(
        'Du har valt ett Skicka-datum som redan har passerats.',
        'danger'
      );
      return false;
    }
    if (kontakt.fornamn === '' && kontakt.efternamn === '') {
      showAlert(
        'Mottagaren verkar inte ha sina namnuppgifter ifyllda.',
        'danger'
      );
      return false;
    }
    if (
      kontakt.gatuadress === '' ||
      kontakt.postort === '' ||
      kontakt.postnummer === ''
    ) {
      showAlert(
        'Mottagaren har inte en fullständig address i systemet.',
        'danger'
      );
      return false;
    }
    return true;
  };

  const submitBestallning = async () => {
    setShowSpinner(true);
    let result = await doPost('/kontakter/bestallningar/create', formData);
    setShowSpinner(false);
    if (result.data.success) {
      showAlert(
        result.data.msg ? result.data.msg : 'Beställningen har registrerats.',
        'success'
      );
      clearFormData();
      setTimeout(() => {
        getBestallningar();
      }, 1800);
    } else {
      showAlert(
        result.data.msg
          ? result.data.msg
          : 'Fel, beställningen har registrerats hos Radonova.',
        'danger'
      );
    }
  };

  const showAlert = (msg, type) => {
    setAlert({ show: true, msg: msg, type: type });
    setTimeout(() => {
      setAlert({
        show: false,
        msg: '',
        type: 'success',
      });
    }, 3000);
  };

  return (
    <>
      <BestallningList list={list} />
      {showSpinner && (
        <div className="spinner-container" style={{ height: 'auto' }}>
          <Spinner animation="border" role="status" />
        </div>
      )}
      {showForm && (
        <Container className="bordered-container row-container aktivitet-container">
          <Form className="atgard-form radea-form">
            <Form.Row>
              <Form.Group as={Col} controlId="bolagId">
                <Form.Label>Beställare</Form.Label>
                <Form.Control
                  as="select"
                  value={formData.bolagId}
                  onChange={(e) => {
                    updateFormData(e);
                  }}
                  custom
                >
                  {util.bolag.map((data, i) => {
                    return (
                      <option key={`bol${i}`} value={data.id}>
                        {data.namn}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="artikelId">
                <Form.Label>Artikel</Form.Label>
                <Form.Control
                  as="select"
                  value={formData.artikelId}
                  onChange={(e) => {
                    updateFormData(e);
                  }}
                  custom
                >
                  <option value={0}>Välj mätpaket</option>
                  {artikelList.map((data, i) => {
                    return (
                      <option key={`bol${i}`} value={data.article_id}>
                        {data.description}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="antal">
                <Form.Label>Antal</Form.Label>
                <Form.Control
                  placeholder="Antal"
                  value={formData.antal}
                  onChange={(e) => {
                    updateFormData(e);
                  }}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="skickasDatum">
                <Form.Label>Skickas datum</Form.Label>
                <div className="input-wrapper">
                  <Form.Control
                    readOnly
                    value={util.getDateObject(formData.skickasDatum, true).str}
                    onChange={(e) => {
                      updateFormData(e);
                    }}
                  />
                  <DatePicker
                    locale="sv"
                    selected={formData.skickasDatum}
                    onChange={(date) => setSkickasDatum(date)}
                    minDate={moment().toDate()}
                  />
                </div>
              </Form.Group>
            </Form.Row>
          </Form>
          <div className="half-area">
            <div className="content">
              <h5>Mottagarens adressuppgifter: </h5>
              <span>
                <strong>Namn:</strong> {formatName(kontakt)}
              </span>
              <span>
                <strong>Företag:</strong> {kontakt.foretagsnamn}
              </span>
              <span>
                <strong>Adress:</strong> {kontakt.gatuadress},{' '}
                {kontakt.postnummer} {kontakt.postort}
              </span>
              <span>
                <strong>Mobiltelefon:</strong> {kontakt.telefon1}{' '}
              </span>
              <span>
                <strong>e-post:</strong> {kontakt.epost}{' '}
              </span>
            </div>
          </div>
        </Container>
      )}
      <div className="button-row right bottom-item">
        {showForm && (
          <>
            <Button
              variant={'link'}
              onClick={() => {
                clearFormData();
                setShowForm(false);
              }}
            >
              Avbryt
            </Button>
            <Button
              onClick={() => {
                if (validateFormData()) {
                  setShowSaveModal(true);
                }
              }}
              disabled={showSpinner}
            >
              {showSpinner ? 'Laddar...' : 'Beställ'}
            </Button>
          </>
        )}
        {!showForm && (
          <Button
            variant={'link'}
            onClick={() => {
              setShowForm(true);
            }}
          >
            Lägg ny beställning
          </Button>
        )}
      </div>
      <RadeaAlert msg={alert.msg} type={alert.type} show={alert.show} />

      <Modal
        show={showSaveModal}
        onHide={() => {
          setShowSaveModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Skicka Beställning </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Mottagare:</h5>
          <div>{formatName(kontakt)}</div>
          <div>{kontakt.gatuadress} </div>
          <div>
            {kontakt.postnummer} {kontakt.postort}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            onClick={() => {
              setShowSaveModal(false);
            }}
          >
            Avbryt
          </Button>
          <Button
            variant="success"
            onClick={() => {
              setShowSaveModal(false);
              submitBestallning();
            }}
          >
            Skicka
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const BestallningList = (props) => {
  const { list } = props;
  return (
    <Table hover striped size="sm" className="dokument-table">
      <thead>
        <tr>
          <th>Beställare</th>
          <th>Antal</th>
          <th>Artikel</th>
          <th>Skickas datum</th>
        </tr>
      </thead>
      <tbody>
        {list.length === 0 && (
          <tr>
            <td colSpan={4}>
              <i>Inga mätpaket beställda</i>
            </td>
          </tr>
        )}
        {list.map((data, i) => {
          return (
            <tr key={`${i}bstlist`}>
              <td>{util.bolagArr[data.bolagId]}</td>
              <td>{data.antal}</td>
              <td>
                {data.artikelId} - {data.beskrivning}
              </td>
              <td>{util.getDateObject(data.skickasDatum, true).str}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default Bestallning;
