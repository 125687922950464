import { useEffect, useState } from 'react';

export interface IScreenSize {
  width: number;
  height: number;
}

export function useScreenSize(): IScreenSize {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    window.addEventListener('resize', onScreenResizeChange);

    return () => {
      window.removeEventListener('resize', onScreenResizeChange);
    };

    function onScreenResizeChange() {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
  }, []);

  return screenSize;
}
