import React, { useState, useCallback, useEffect } from 'react';
import util, { daysAndHours } from 'utils/util';
import { doGetList } from '../action/services';
import { ClockFill } from 'react-bootstrap-icons';
import { Spinner } from 'react-bootstrap';
import { ITidsbokning } from 'types';
import moment from 'moment';
import { hasPassed } from 'components/common/calendar/utils';

export interface IProjectDeltagareProps {
  projektId: Number;
  showPassed?: boolean;
}

const ProjektDeltagare = ({
  projektId,
  showPassed,
}: IProjectDeltagareProps) => {
  const [list, setList] = useState<ITidsbokning[]>([]);
  const [loading, setLoading] = useState(true);
  const getBokningar = useCallback(async () => {
    let newList = await doGetList(`/tidsbokningar/projekt/${projektId}`);
    setList(newList.data);
    setLoading(false);
  }, [projektId]);

  useEffect(() => {
    if (projektId > 0) {
      getBokningar();
    }
  }, [projektId, getBokningar]);

  return (
    <span className="cell-list colored">
      <span>
        Antal bokningar: {list.length === 0 ? 'Hämtar...' : list.length}
      </span>
      {!loading ? (
        list.map((data, i) => {
          const passed = hasPassed(data);
          const isSameDay =
            !data.sluttid || moment(data.sluttid).isSame(data.starttid, 'day');
          const duration = isSameDay
            ? null
            : daysAndHours(data.sluttid, data.starttid);
          return (
            <span
              key={`deltagare${i}`}
              className={`cell-row ${!passed || showPassed ? '' : 'hide'}`}
            >
              <span className="time">
                <ClockFill
                  style={{ paddingRight: '3px' }}
                  color={!passed ? '#669eb7' : '#bbb'}
                />{' '}
                {moment(data.starttid).format(
                  data.heldag ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm'
                )}
                {isSameDay ? null : (
                  <>
                    {' '}
                    -{' '}
                    {moment(data.sluttid).format(
                      data.heldag ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm'
                    )}
                  </>
                )}
              </span>
              <span>
                {data?.personal?.[0]?.bokadPersonal?.fornamn || '{borttagen}'}{' '}
                {data?.personal?.[0]?.bokadPersonal?.efternamn || '{borttagen}'}
                , {util.atgardstypToText(data.typ)}{' '}
                {duration ||
                  (data.heldag ? '(heldag)' : util.getHoursMinutes(data.langd))}
              </span>
            </span>
          );
        })
      ) : (
        <Spinner animation="border" role="status" />
      )}
    </span>
  );
};

export default ProjektDeltagare;
