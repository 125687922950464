import React, { useState, useEffect, useCallback } from 'react';
import { doPost, doGet } from '../action/services';
import {
  Card,
  Button,
  Modal,
  Form,
  Col,
  InputGroup,
  DropdownButton,
  Dropdown,
} from 'react-bootstrap';
import util from 'utils/util';
import { IRadeaAlertProps, RadeaAlert } from '../common/radea-alert.component';
import RadeaEditor from '../common/radea-editor';
import {
  PersonalCard,
  PersonalSelectFormGroup,
} from '../common/personal.component';
import { CaretRightFill, CaretDownFill } from 'react-bootstrap-icons';
import kategori from '../../constants/kategori';
import useKategori from 'utils/hooks/useKategori';
import { IProjekt } from 'types/projekt';
import { IFakturaAdress, IKontaktFull, ThemeVariants } from 'types';

export interface IProjektdetaljerProps {
  projekt: IProjekt;
  kontakt: IKontaktFull;
  flyttaTillKund?: boolean;
  refresh: () => unknown;
  cancel: () => unknown;
}

const Projektdetaljer = ({
  projekt,
  kontakt,
  refresh,
  flyttaTillKund,
  cancel,
}: IProjektdetaljerProps) => {
  const [editMode, setEditMode] = useState(flyttaTillKund);
  const [editShow, setEditShow] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [fakturaadressShow, setFakturaadressShow] = useState(false);
  const [fakturaadressEdit, setFakturaadressEdit] = useState(false);
  const [alert, setAlert] = useState<IRadeaAlertProps>({
    show: false,
    msg: '',
    type: 'info',
  });
  const [affarsomrade] = useKategori(kategori.Affarsomrade, false);

  const getFormData: () => IProjekt = useCallback(() => {
    return {
      id: projekt.id,
      kontaktId: projekt.kontaktId,
      friendlyName: projekt.id === 0 ? '' : flyttaTillKund ? '' : projekt.friendlyName,
      efaktura: false,
      namn: projekt.id === 0 ? '' : flyttaTillKund ? '' : projekt.namn,
      affarsomrade: projekt.id === 0 ? 'Ospec.' : projekt.affarsomrade,
      monteringsanvisning:
        projekt.id === 0
          ? ''
          : projekt.monteringsanvisning === null
          ? ''
          : projekt.monteringsanvisning,
      fakturaPersonnummer1:
        projekt.id === 0
          ? ''
          : projekt.fakturaPersonnummer1 === null
          ? ''
          : projekt.fakturaPersonnummer1,
      fakturaPersonnummer2:
        projekt.id === 0
          ? ''
          : projekt.fakturaPersonnummer2 === null
          ? ''
          : projekt.fakturaPersonnummer2,
      fastighetsbeteckning:
        projekt.id === 0
          ? ''
          : projekt.fastighetsbeteckning === null
          ? ''
          : projekt.fastighetsbeteckning,
      bolag: projekt.id === 0 ? 1 : projekt.bolag === 0 ? 1 : projekt.bolag,
      ansvarig: projekt.id === 0 ? 0 : projekt.ansvarig,
      flyttadTillKund: 1,
    };
  }, [projekt, flyttaTillKund]);

  const [formData, setFormData] = useState(getFormData);

  const getFakturaadressFormData: () => IFakturaAdress = useCallback(() => {
    if (projekt.fakturaadress && projekt.fakturaadress.id) {
      return {
        id: projekt.fakturaadress.id,
        projektId: projekt.id,
        foretagsnamn: projekt.fakturaadress.foretagsnamn,
        adress1: projekt.fakturaadress.adress1,
        adress2: projekt.fakturaadress.adress2,
        postort: projekt.fakturaadress.postort,
        fornamn: projekt.fakturaadress.fornamn,
        efternamn: projekt.fakturaadress.efternamn,
        referens: projekt.fakturaadress.referens,
        epost:
          projekt.fakturaadress.epost === null
            ? ''
            : projekt.fakturaadress.epost,
      };
    } else {
      return {
        id: 0,
        projektId: 0,
        foretagsnamn: '',
        adress1: '',
        adress2: '',
        fornamn: '',
        efternamn: '',
        referens: '',
        epost: '',
        postort: '',
      };
    }
  }, [projekt]);

  const [formData2, setFormData2] = useState<IFakturaAdress>(
    getFakturaadressFormData
  );

  useEffect(() => {
    if (editMode) {
      setFormData(getFormData());
      setFormData2(getFakturaadressFormData());
      setFakturaadressEdit(projekt.fakturaadress ? true : false);
      setEditShow(true);
    } else {
      setEditShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  const handleChange = (e) => {
    let { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleFakturaAdressChange = (e) => {
    let { id, value } = e.target;
    setFormData2({
      ...formData2,
      [id]: value,
    });
  };

  const handleSubmit = async () => {
    let result = await doPost(
      `/projekt/${formData.id === 0 ? 'create' : 'update'}`,
      {
        ...formData,
        fakturaadress: fakturaadressEdit ? formData2 : undefined,
      }
    );
    if (result.data.success) {
      showAlert('Projektet har sparats i systemet.', 'success', () => {
        refresh();
        setEditMode(false);
      });
    } else {
      showAlert(
        'Fel, projektet har inte sparats i systemet, kontakta supporten.',
        'danger'
      );
    }
  };

  const coldcase = async (coldcase) => {
    const res = await doGet(
      `/coldcase/${coldcase ? 'set' : 'remove'}/${formData.id}`
    );
    if (res.data.success) {
      let text = coldcase
        ? 'Projektet är markerat som coldcase.'
        : 'Projektet är inte längre ett coldcase.';
      showAlert(text, 'success', () => {
        refresh();
      });
    } else {
      showAlert(
        'Projektet kunde inte markeras/avmarkeras some coldcase, kontakta supporten.',
        'danger'
      );
    }
  };
  const showAlert = (
    msg: string,
    type: ThemeVariants,
    callback?: () => unknown
  ) => {
    setAlert({ show: true, msg, type });
    setTimeout(() => {
      if (callback) {
        callback();
      }
      setAlert({
        show: false,
        msg: '',
        type: 'success',
      });
    }, 2000);
  };

  return (
    <>
      <RadeaAlert msg={alert.msg} type={alert.type} show={alert.show} />
      <Card className="form-card">
        <Card.Header as="h5">
          {editMode && (
            <span>
              {flyttaTillKund ? 'Mata in' : 'Redigera'} projektdetaljer:
            </span>
          )}
          {!editMode && (
            <span>
              Projektdetaljer -{' '}
              {projekt.coldcase
                ? 'Coldcase'
                : projekt.avslutat
                ? 'Avslutat'
                : 'Pågående'}{' '}
              (Startat:{' '}
              {projekt.createdAt
                ? util.formatDate(projekt.createdAt, false)
                : ''}
              )
            </span>
          )}
        </Card.Header>
        <Card.Body>
          {!editMode && (
            <>
              <div className="card-col">
                <Card.Text>
                  <strong>Affärsområde:</strong> {projekt.affarsomrade}
                </Card.Text>
                <Card.Text>
                  <strong>Projektnamn:</strong> {projekt.friendlyName || '-'}
                </Card.Text>
                <Card.Text>
                  <strong>Projektnummer:</strong> {projekt.id}
                </Card.Text>
                <Card.Text>
                  <strong>Bolag:</strong> {util.bolagArr[projekt.bolag || 0]}
                </Card.Text>
                <Card.Text>
                  <strong>Ansvarig:</strong>{' '}
                  <PersonalCard personal={projekt.ansvarigPersonal || 0} />
                </Card.Text>
                <Card.Text>
                  <strong>Fastighet:</strong>{' '}
                  {projekt.fastighetsbeteckning
                    ? projekt.fastighetsbeteckning
                    : '-'}
                </Card.Text>
                <Card.Text>
                  <strong>Personnummer 1:</strong>{' '}
                  {projekt.fakturaPersonnummer1
                    ? projekt.fakturaPersonnummer1
                    : '-'}
                </Card.Text>
                <Card.Text>
                  <strong>Personnummer 2:</strong>{' '}
                  {projekt.fakturaPersonnummer2
                    ? projekt.fakturaPersonnummer2
                    : '-'}
                </Card.Text>
                <Card.Text>
                  {projekt.fakturaadress !== null && (
                    <Button
                      variant={'link'}
                      className={'padding-none'}
                      onClick={() => {
                        setFakturaadressShow(!fakturaadressShow);
                      }}
                    >
                      Fakturaadress
                      {fakturaadressShow && <CaretDownFill color="#333" />}
                      {!fakturaadressShow && <CaretRightFill color="#333" />}
                    </Button>
                  )}
                  {projekt.fakturaadress === null && (
                    <span style={{ color: '#ccc' }}>
                      Fakturaadress
                      <CaretRightFill color="#ccc" />
                    </span>
                  )}
                </Card.Text>
                {fakturaadressShow && (
                  <>
                    <Card.Text>
                      <strong>Företagsnamn:</strong>{' '}
                      {projekt.fakturaadress?.foretagsnamn === ''
                        ? '-'
                        : projekt.fakturaadress?.foretagsnamn}
                    </Card.Text>
                    <Card.Text>
                      <strong>Namn:</strong> {projekt.fakturaadress?.fornamn}{' '}
                      {projekt.fakturaadress?.efternamn}
                    </Card.Text>
                    <Card.Text>
                      <strong>Adressrad 1:</strong>{' '}
                      {projekt.fakturaadress?.adress1 === ''
                        ? '-'
                        : projekt.fakturaadress?.adress1}
                    </Card.Text>
                    <Card.Text>
                      <strong>Postnummer:</strong>{' '}
                      {projekt.fakturaadress?.adress2 === ''
                        ? '-'
                        : projekt.fakturaadress?.adress2}
                    </Card.Text>
                    <Card.Text>
                      <strong>Postort:</strong>{' '}
                      {projekt.fakturaadress?.postort === ''
                        ? '-'
                        : projekt.fakturaadress?.postort}
                    </Card.Text>
                    <Card.Text>
                      <strong>E-post, faktura:</strong>{' '}
                      {projekt.fakturaadress?.epost === '' ||
                      projekt.fakturaadress?.epost === null
                        ? '-'
                        : projekt.fakturaadress?.epost}
                    </Card.Text>
                    <Card.Text>
                      <strong>Er referens:</strong>{' '}
                      {projekt.fakturaadress?.referens === ''
                        ? '-'
                        : projekt.fakturaadress?.referens}
                    </Card.Text>
                  </>
                )}
              </div>
              <div className="card-col">
                <Card.Text>
                  <strong>Monteringsanvisning:</strong>
                </Card.Text>
                <Card.Text>
                  <RadeaEditor
                    initialText={
                      projekt.monteringsanvisning === null
                        ? ''
                        : projekt.monteringsanvisning
                    }
                    onChange={null}
                    id="besiktningsinformation"
                    readOnly={true}
                  />
                </Card.Text>
              </div>
            </>
          )}
          {editShow && (
            <Form>
              <Form.Row>
                <Form.Group as={Col} controlId="friendlyName">
                  <Form.Label>Projektnamn</Form.Label>
                  <Form.Control
                    placeholder="Ange projektnamn (valfritt)"
                    value={formData.friendlyName}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="bolag">
                  <Form.Label>Bolag</Form.Label>
                  <Form.Control
                    as="select"
                    value={formData.bolag}
                    onChange={handleChange}
                    custom
                  >
                    {util.bolag.map((data, i) => {
                      return (
                        <option key={`bolagopt${i}`} value={data.id}>
                          {data.namn}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} controlId="affarsomrade">
                  <Form.Label>Affärsområde</Form.Label>
                  <Form.Control
                    as="select"
                    value={formData.affarsomrade}
                    onChange={handleChange}
                    custom
                  >
                    {affarsomrade.map((omrade) => {
                      return (
                        <option key={omrade.namn} value={omrade.namn}>
                          {omrade.namn}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="ansvarig">
                  <PersonalSelectFormGroup
                    label={'Ansvarig'}
                    selectedId={formData.ansvarig}
                    aktiva={false}
                    registerSelected={handleChange}
                    roll={'besiktning'}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="fastighetsbeteckning">
                  <Form.Label>Fastighet</Form.Label>
                  <Form.Control
                    placeholder="Fyll i fastighetsbeteckning"
                    value={formData.fastighetsbeteckning}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="fakturaPersonnummer1">
                  <Form.Label>Personnummer 1</Form.Label>
                  <Form.Control
                    placeholder="Fyll i personnummer 1"
                    value={formData.fakturaPersonnummer1}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="fakturaPersonnummer2">
                  <Form.Label>Personnummer 2</Form.Label>
                  <Form.Control
                    placeholder="Fyll i personnummer 2"
                    value={formData.fakturaPersonnummer2}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="monteringsanvisning">
                  <Form.Label>Monteringsanvisning</Form.Label>
                  <RadeaEditor
                    initialText={
                      formData.monteringsanvisning === null
                        ? ''
                        : formData.monteringsanvisning
                    }
                    onChange={handleChange}
                    id="monteringsanvisning"
                    readOnly={false}
                    placeholder="Fyll i monteringsanvisning"
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group
                  as={Col}
                  className="row-direction"
                  style={{ display: 'flex' }}
                >
                  <InputGroup style={{ alignItems: 'center' }}>
                    <Form.Check
                      type="checkbox"
                      defaultChecked={fakturaadressEdit}
                      checked={fakturaadressEdit}
                      label="Lägg till fakturaadress"
                      onChange={(e) => {
                        setFakturaadressEdit(e.target.checked);
                      }}
                    />
                    <FakturaadressCopy
                      kontakt={kontakt}
                      fakturaadressEdit={fakturaadressEdit}
                      onSelect={(previousInvoiceAddress) => {
                        setFakturaadressEdit(true);

                        setFormData2({
                          ...previousInvoiceAddress,
                          projektId: 0,
                          id: 0,
                          createdAt: undefined,
                          updatedAt: undefined,
                        });
                      }}
                    />
                  </InputGroup>
                </Form.Group>
              </Form.Row>
              {fakturaadressEdit && (
                <>
                  <Form.Row>
                    <Form.Group
                      as={Col}
                      controlId="foretagsnamn"
                      className="col-6"
                    >
                      <Form.Label>Företagsnamn</Form.Label>
                      <Form.Control
                        placeholder="Fyll i företagsnamn"
                        value={formData2.foretagsnamn}
                        onChange={handleFakturaAdressChange}
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="fornamn">
                      <Form.Label>Förnamn</Form.Label>
                      <Form.Control
                        placeholder="Fyll i förnamn"
                        value={formData2.fornamn}
                        onChange={handleFakturaAdressChange}
                        autoComplete="off"
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="efternamn">
                      <Form.Label>Efternamn</Form.Label>
                      <Form.Control
                        placeholder="Fyll i efternamn"
                        value={formData2.efternamn}
                        onChange={handleFakturaAdressChange}
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="adress1" className="col-6">
                      <Form.Label>Adressrad 1</Form.Label>
                      <Form.Control
                        placeholder="Fyll i adressrad 1"
                        value={formData2.adress1}
                        onChange={handleFakturaAdressChange}
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="adress2">
                      <Form.Label>Postnummer</Form.Label>
                      <Form.Control
                        placeholder="Fyll i postnummer"
                        value={formData2.adress2}
                        onChange={handleFakturaAdressChange}
                        autoComplete="off"
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="postort">
                      <Form.Label>Postort</Form.Label>
                      <Form.Control
                        placeholder="Fyll i postort"
                        value={formData2.postort}
                        onChange={handleFakturaAdressChange}
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="referens">
                      <Form.Label>Referens</Form.Label>
                      <Form.Control
                        placeholder="Fyll i er referens"
                        value={formData2.referens}
                        onChange={handleFakturaAdressChange}
                        autoComplete="off"
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="epost">
                      <Form.Label>E-post, faktura</Form.Label>
                      <Form.Control
                        placeholder="Fyll i e-post faktura"
                        value={formData2.epost}
                        onChange={handleFakturaAdressChange}
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Form.Row>
                </>
              )}
            </Form>
          )}
        </Card.Body>
        <Card.Footer className="text-right">
          {!editMode && (
            <>
              {projekt.coldcase === 0 && (
                <Button
                  variant="link"
                  onClick={() => {
                    coldcase(true);
                  }}
                >
                  Gör till Coldcase
                </Button>
              )}
              {projekt.coldcase === 1 && (
                <Button
                  variant="link"
                  onClick={() => {
                    coldcase(false);
                  }}
                >
                  Ta bort Coldcase
                </Button>
              )}
              <Button
                variant="primary"
                onClick={() => {
                  setEditMode(true);
                }}
              >
                Redigera
              </Button>
            </>
          )}
          {editMode && (
            <>
              <Button
                variant="success"
                onClick={() => {
                  setShowSaveModal(true);
                }}
              >
                Spara
              </Button>
              <Button
                variant="link"
                onClick={() => {
                  setEditMode(false);
                  if (flyttaTillKund) {
                    cancel();
                  }
                }}
              >
                Avbryt
              </Button>

              <Modal
                show={showSaveModal}
                onHide={() => {
                  setShowSaveModal(false);
                }}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Spara</Modal.Title>
                </Modal.Header>
                <Modal.Body>Spara ändringarna?</Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="link"
                    onClick={() => {
                      setShowSaveModal(false);
                    }}
                  >
                    Stäng
                  </Button>
                  <Button
                    variant="success"
                    onClick={() => {
                      setShowSaveModal(false);
                      handleSubmit();
                    }}
                  >
                    Spara
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </Card.Footer>
      </Card>
    </>
  );
};

interface FakturaadressCopyProps {
  onSelect: (fakturaaddress: IFakturaAdress) => unknown;
  fakturaadressEdit?: boolean;
  kontakt?: IKontaktFull;
}

function FakturaadressCopy({
  kontakt,
  fakturaadressEdit,
  onSelect,
}: FakturaadressCopyProps) {
  const commonProps = {
    variant: 'outline-secondary',
    style: { marginLeft: '1rem' },
    title: 'Kopiera från tidigare',
  };
  const projectsWithFakturaadress =
    kontakt?.projekt?.filter((p) => p?.fakturaadress) || [];

  if (projectsWithFakturaadress.length === 0 || fakturaadressEdit) {
    return null;
  }

  if (projectsWithFakturaadress.length === 1) {
    return (
      <Button
        {...commonProps}
        onClick={() => {
          onSelect(
            projectsWithFakturaadress[0].fakturaadress as IFakturaAdress
          );
        }}
      >
        {commonProps.title}
      </Button>
    );
  }

  return (
    <DropdownButton {...commonProps}>
      {projectsWithFakturaadress.map((p) => (
        <Dropdown.Item
          {...commonProps}
          onClick={() => {
            onSelect(p.fakturaadress as IFakturaAdress);
          }}
        >
          {p.namn === '' ? 'Projekt' : p.namn} {p.id} -{' '}
          {p?.fakturaadress?.adress1} {p?.fakturaadress?.postort},{' '}
          {p?.fakturaadress?.adress2}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
}

export default Projektdetaljer;
