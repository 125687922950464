import React from 'react';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';

const SortHeader = ({ currentSort, sortBy, header, setSort }) => {
  const handleClick = () => {
    if (currentSort.by === sortBy) {
      setSort({ by: sortBy, direction: currentSort.direction === "ASC" ? "DESC" : "ASC" })
    } else {
      setSort({ by: sortBy, direction: "DESC" })
    }
  }
  return (
    <span onClick={handleClick} className={`sort-header`}>
      {header}
      {currentSort.by === sortBy &&
        <>
          {currentSort.direction === "ASC" &&
            <CaretUpFill />
          }
          {currentSort.direction === "DESC" &&
            <CaretDownFill />
          }
        </>
      }
      {currentSort.by !== sortBy &&
        <>
          <CaretDownFill color="#ccc" />
        </>
      }
    </span>
  )
}

export default SortHeader