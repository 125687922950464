export function cleanEscapedName(name: string): string {
  if (!name) {
    return '';
  }
  const escapeSequence = name.match(/^\d+-/);
  if (!escapeSequence) {
    return name;
  }
  return name.substring(escapeSequence[0].length);
}

export function convertFileSize(size) {
  size += '';
  let numberPart = size.match(/\d+/);
  let unitPart = size.match(/[a-z]+/);
  let multiplier = 1;
  if (unitPart) {
    switch (unitPart[0]) {
      case 'kb': {
        multiplier = 1024;
        break;
      }
      case 'mb': {
        multiplier = Math.pow(1024, 2);
        break;
      }
      case 'gb': {
        multiplier = Math.pow(1024, 3);
        break;
      }
      default: {
        multiplier = 1;
        break;
      }
    }
  }

  return parseInt(numberPart[0]) * multiplier;
}

export function formatFileSize(size, decimals = 1) {
  const suffixes = ['KB', 'MB', 'GB'];
  let result = `${size} B`;
  let parsedSize = parseInt(String(size), 10);

  suffixes.some((suffix) => {
    parsedSize /= 1024;
    if (parsedSize < 1) {
      return true;
    }
    result = `${parsedSize.toFixed(decimals)} ${suffix}`;
    return false;
  });

  return result;
}

const imageExtensions = [
  'png',
  'jpg',
  'jpeg',
  'gif',
  'svg',
  'iff',
  'bmp',
  'exif',
  'ico',
  'pbm',
  'pcf',
  'pcx',
  'pdn',
  'pgm',
  'pict',
  'pct',
  'pnm',
  'pns',
  'ppm',
  'psb',
  'psd',
  'pdd',
  'psp',
  'px',
  'pxm',
  'pxr',
  'raw',
  'rgb',
  'tiff',
  'tif',
  'xbm',
  'xcf',
  'xpm',
  'wmf',
  'emf',
  'art',
  'rgb',
  'rgba',
];

export function isImagePath(filePath) {
  return imageExtensions.some((extension) =>
    filePath.endsWith(`.${extension}`)
  );
}

export interface IResacleImageOptions {
  maxSize?: number;
  maxWidth?: number;
  maxHeight?: number;
}

export function rescaleImage(file, options: IResacleImageOptions = {}) {
  return new Promise((resolve, reject) => {
    const { maxSize, maxWidth, maxHeight } = options;
    const supportedFileTypes = ['image/jpeg', 'image/png'];

    if (!supportedFileTypes.includes(file.type)) {
      return resolve(file);
    }

    if (maxSize && file.size < maxSize) {
      return resolve(file);
    }

    if (!maxSize && !maxWidth && !maxHeight) {
      return resolve(file);
    }

    let canvas: HTMLCanvasElement = document.createElement('canvas');
    if (!canvas.toBlob && !(canvas as any).msToBlob) {
      return resolve(file);
    }

    if ((canvas as any).msToBlob && file.type !== 'image/png') {
      // IE 11 will always treat canvas images and png which might result in unexpected behavior for jpeg
      return resolve(file);
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      const image = new Image();
      image.src = event?.target?.result as string;
      image.onload = () => {
        let newWidth = image.width;
        let newHeight = image.height;

        if (maxSize) {
          // This is just an estimate assuming that the resolution is linearly proportional
          // to the file size
          const estimatedScale = maxSize / file.size;
          newWidth = image.width * Math.sqrt(estimatedScale);
          newHeight = image.height * Math.sqrt(estimatedScale);
        } else {
          if (maxWidth && newWidth > maxWidth) {
            newWidth = maxWidth;
            newHeight = newHeight * (newWidth / image.width);
          }
          if (maxHeight && newHeight > maxHeight) {
            newWidth = newWidth * (maxHeight / newHeight);
            newHeight = maxHeight;
          }
        }

        canvas.width = newWidth;
        canvas.height = newHeight;
        const context = canvas.getContext('2d');
        context?.drawImage(image, 0, 0, newWidth, newHeight);
        if ((canvas as any).msToBlob) {
          const blob = (canvas as any).msToBlob();
          return done(blob); // msToBlob will always output a blob with type image/png
        }
        canvas.toBlob(done, file.type, 1);
      };

      image.onerror = (error) => {
        console.error(error);
      };
    };

    reader.onerror = (error) => {
      console.error(error);
    };

    reader.readAsDataURL(file);

    function done(blob) {
      if (!blob) {
        return reject();
      }

      blob['name'] = file.name;
      blob['lastModifiedDate'] = new Date();
      resolve(blob);
    }
  });
}

export function shortenFileName(name, maxLength) {
  if (name.length < maxLength) {
    return name;
  }

  const lastPart = name.substring(name.length - 5, name.length);
  const separator = '...';
  const firstPart = name.substring(
    0,
    Math.max(maxLength, lastPart.length + separator.length + 1) -
      lastPart.length -
      separator.length
  );
  return `${firstPart}${separator}${lastPart}`;
}
