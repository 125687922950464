import { useState, useMemo, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { buildPath, fromQuery } from 'utils/path';

export function useQuery(): URLSearchParams {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export function useQueryParameter<T>(
  key: string,
  defaultValue: any,
  number?: boolean,
  autoUpdate?: boolean
): [
  T extends number ? number : string,
  React.Dispatch<React.SetStateAction<T extends number ? number : string>>
] {
  const query = useQuery();
  const history = useHistory();
  const p = fromQuery<T>(query, key, defaultValue, number);
  const [value, setValue] = useState<T extends number ? number : string>(p);

  useEffect(() => {
    setValue(p);
  }, [p]);

  return [
    value,
    (action: React.SetStateAction<T extends number ? number : string>) => {
      const newValue = typeof action === 'function' ? action(value) : action;
      setValue(newValue);

      if (!autoUpdate) {
        return;
      }
      history.replace(
        buildPath(window.location.pathname)
          .query(query)
          .query({ [key]: String(newValue) })
          .get()
      );
    },
  ];
}
