import React from 'react';
import { Table } from 'react-bootstrap';
import util from 'utils/util';
import { ArrowRightCircleFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { resolvePath } from 'utils/path';
import paths from 'constants/paths';

const ListProjekt = (props) => {
  const { list } = props;

  return (
    <div className="list-wrapper">
      <Table hover striped>
        <thead>
          <tr>
            <td>Skapad</td>
            <td>Bokad</td>
            <td>Affärsomr.</td>
            <td>Namn</td>
            <td>Adress</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {list.map((data, i) => {
            return <DataTable obj={data} key={i} />;
          })}
          {list.length === 0 && (
            <tr>
              <td colSpan={6}>
                <i>Ingen data tillänglig...</i>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

const DataTable = (props) => {
  const { obj } = props;
  // eslint-disable-next-line no-unused-vars
  return (
    <tr>
      <td>{util.formatDate(obj.createdAt)}</td>
      <td>
        {obj.tidsbokningar.length > 0
          ? util.formatDate(obj.tidsbokningar[0].starttid, true)
          : '-'}
      </td>
      <td>{obj.affarsomrade}</td>
      <td>{util.getKontaktNameRemoveDuplicate(obj.kontakt)}</td>
      <td>
        {obj.kontakt.gatuadress}
        <br />
        {obj.kontakt.postnummer} {obj.kontakt.postort}
      </td>
      <td>
        <Link to={resolvePath(paths.VIEW_CONTACT, obj.kontaktId)}>
          <ArrowRightCircleFill
            color="gray"
            size={24}
            style={{ cursor: 'pointer' }}
          />
        </Link>
      </td>
    </tr>
  );
};

export default ListProjekt;
