import React, { useState, useEffect } from 'react';
import { doPost } from '../action/services';
import { Card, Button, Modal, Form, Col } from 'react-bootstrap';
import { IRadeaAlertProps, RadeaAlert } from '../common/radea-alert.component';
import useKategori from '../../utils/hooks/useKategori';
import kategori from 'constants/kategori';
import util from 'utils/util';
import { formatName } from 'utils/personal';
import { IKontaktFull } from 'types/kontakt';

export interface IKontaktUppgifterProps {
  kontakt: IKontaktFull;
  refresh: () => unknown;
}

const Kontaktuppgifter = ({ kontakt, refresh }: IKontaktUppgifterProps) => {
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState<IKontaktFull>({});
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [alert, setAlert] = useState<IRadeaAlertProps>({
    show: false,
    msg: '',
    type: 'info',
  });
  const [kundkategorier] = useKategori(kategori.Kundkategori, false);
  const [validation, setValidation] = useState({ valid: true, msg: '' });

  //const [originalKontakt, setKontakt] = useState(kontakt);
  useEffect(() => {
    setFormData({
      ...kontakt,
    });
  }, [kontakt]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleSubmit = async () => {
    let result = await doPost('/kontakter/update', formData);
    if (result.data.success) {
      showAlert('Kontakten har sparats i systemet.', 'success');
      refresh();
    } else {
      showAlert(
        'Fel, kontakten har inte sparats i systemet, kontakta supporten.',
        'danger'
      );
    }
  };

  const showAlert = (msg, type) => {
    setAlert({ show: true, msg: msg, type: type });
    setTimeout(() => {
      setAlert({
        show: false,
        msg: '',
        type: 'success',
      });
    }, 1800);
  };

  const validate = () => {
    setValidation({ valid: true, msg: '' });
    if (
      formData.efternamn === '' &&
      formData.fornamn === '' &&
      formData.foretagsnamn === '' &&
      formData.gatuadress === '' &&
      formData.postnummer === '' &&
      formData.postort === '' &&
      formData.telefon1 === '' &&
      formData.telefon2 === '' &&
      formData.epost === ''
    ) {
      setValidation({
        valid: false,
        msg: 'Minst ett värde i formuläret måste fyllas i för att man ska kunna spara.',
      });
    } else {
      if (formData.epost !== '' && !util.validateEmail(formData.epost)) {
        setValidation({
          valid: false,
          msg: 'Formuläret kan inte sparas för E-posten är felinmatad.',
        });
      } else if (
        formData.telefon1 !== '' &&
        (formData?.telefon1?.length || 0) < 7
      ) {
        setValidation({ valid: false, msg: 'Mobilnumret är för kort.' });
      } else if (
        formData.telefon2 !== '' &&
        (formData?.telefon2?.length || 0) < 6
      ) {
        setValidation({ valid: false, msg: 'Fast telefon är för kort.' });
      } else if (
        formData.postnummer !== '' &&
        formData.postnummer?.replace(/ /g, '').length !== 5
      ) {
        setValidation({
          valid: false,
          msg: 'Postnumret måste vara fem siffror.',
        });
      }
    }
  };

  return (
    <>
      <RadeaAlert msg={alert.msg} type={alert.type} show={alert.show} />
      <Card className="form-card">
        <Card.Header as="h5">
          {editMode && <span>Redigera kontaktuppgifter:</span>}
          {!editMode && (
            <span>
              Kontakt: {formatName(kontakt)}
              {kontakt.foretagsnamn ? `, ${kontakt.foretagsnamn}` : ''}
            </span>
          )}
        </Card.Header>
        <Card.Body>
          {!editMode && (
            <>
              <div className="card-col">
                <Card.Text>
                  <strong>Företagsnamn:</strong>{' '}
                  {kontakt.foretagsnamn ? kontakt.foretagsnamn : '-'}
                </Card.Text>
                <Card.Text>
                  <strong>Källa / Förvaltare:</strong>{' '}
                  {kontakt.forvaltare ? kontakt.forvaltare : '-'}
                </Card.Text>
                <Card.Text>
                  <strong>Adress:</strong> {kontakt.gatuadress},{' '}
                  {kontakt.postnummer} {kontakt.postort}
                </Card.Text>
                <Card.Text>
                  <strong>Telefon, mobil:</strong>
                  {kontakt.telefon1 && (
                    <>
                      &nbsp;
                      <a href={`tel:${kontakt.telefon1}`}>{kontakt.telefon1}</a>
                    </>
                  )}
                </Card.Text>
                <Card.Text>
                  <strong>Telefon, fast:</strong>
                  {kontakt.telefon2 && (
                    <>
                      &nbsp;
                      <a href={`tel:${kontakt.telefon2}`}>{kontakt.telefon2}</a>
                    </>
                  )}
                </Card.Text>
              </div>
              <div className="card-col">
                <Card.Text>
                  <strong>Kundkategori:</strong> {kontakt.kategori}
                </Card.Text>
                <Card.Text>
                  <strong>Referenskund:</strong>{' '}
                  {kontakt.referenskund ? 'Ja' : 'Nej'}
                </Card.Text>
                <Card.Text>
                  <strong>E-post:</strong>{' '}
                  <a
                    href={`mailto:${kontakt.epost}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {kontakt.epost}
                  </a>
                </Card.Text>
              </div>
            </>
          )}
          {editMode && (
            <Form>
              <Form.Row>
                <Form.Group as={Col} controlId="fornamn">
                  <Form.Label>Förnamn</Form.Label>
                  <Form.Control
                    placeholder="Fyll i förnamn"
                    defaultValue={formData.fornamn}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="efternamn">
                  <Form.Label>Efternamn</Form.Label>
                  <Form.Control
                    placeholder="Fyll i efternamn"
                    defaultValue={formData.efternamn}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="forvaltare">
                  <Form.Label>Källa / Förvaltare</Form.Label>
                  <Form.Control
                    placeholder="Fyll i källa / förvaltare"
                    defaultValue={formData.forvaltare}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="kategori">
                  <Form.Label>Kundkategori</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={handleChange}
                    value={formData.kategori}
                    custom
                  >
                    {kundkategorier.map((kategori) => (
                      <option value={kategori.namn}>{kategori.namn}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} xs={6} controlId="foretagsnamn">
                  <Form.Label>Företagsnamn</Form.Label>
                  <Form.Control
                    placeholder="Fyll i företagsnamn"
                    defaultValue={formData.foretagsnamn}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} xs={6} controlId="gatuadress">
                  <Form.Label>Gatuadress</Form.Label>
                  <Form.Control
                    placeholder="Fyll i gatuadress"
                    defaultValue={formData.gatuadress}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="postnummer">
                  <Form.Label>Postnummer</Form.Label>
                  <Form.Control
                    placeholder="Fyll i postnummer"
                    defaultValue={formData.postnummer}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="postort">
                  <Form.Label>Postort</Form.Label>
                  <Form.Control
                    placeholder="Fyll i postort"
                    defaultValue={formData.postort}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="telefon1">
                  <Form.Label>Telefon, mobil</Form.Label>
                  <Form.Control
                    placeholder="Fyll i mobilnummer"
                    defaultValue={formData.telefon1}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="telefon2">
                  <Form.Label>Telefon, fast</Form.Label>
                  <Form.Control
                    placeholder="Fyll i fast telefon"
                    defaultValue={formData.telefon2}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="epost">
                  <Form.Label>E-Post</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Fyll i e-post"
                    defaultValue={formData.epost}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="referenskund">
                  <Form.Label>Referenskund</Form.Label>
                  <Form.Control
                    as="select"
                    value={formData.referenskund}
                    onChange={handleChange}
                    custom
                  >
                    <option value={0}>Nej</option>
                    <option value={1}>Ja</option>
                  </Form.Control>
                </Form.Group>
              </Form.Row>
            </Form>
          )}
        </Card.Body>
        <Card.Footer className="text-right">
          {!editMode && (
            <Button
              variant="primary"
              onClick={() => {
                setEditMode(true);
              }}
            >
              Redigera
            </Button>
          )}
          {editMode && (
            <>
              <Button
                variant="primary"
                onClick={() => {
                  validate();
                  setShowSaveModal(true);
                }}
              >
                Spara
              </Button>
              <Button
                variant="link"
                onClick={() => {
                  setEditMode(false);
                }}
              >
                Avbryt
              </Button>

              <Modal
                show={showSaveModal}
                onHide={() => {
                  setShowSaveModal(false);
                }}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Spara {formatName(formData)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {validation.valid && <>Spara ändringarna?</>}
                  {!validation.valid && <>{validation.msg}</>}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="link"
                    onClick={() => {
                      setShowSaveModal(false);
                    }}
                  >
                    Stäng
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      setShowSaveModal(false);
                      handleSubmit();
                    }}
                  >
                    Spara
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </Card.Footer>
      </Card>
    </>
  );
};

export default Kontaktuppgifter;
