import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import util from 'utils/util';
import { ArrowRightCircleFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import AktivitetModal from './aktivitetmodal';
import { Form } from 'react-bootstrap';
import { PersonalSelectFormGroup } from '../common/personal.component';
import { resolvePath } from 'utils/path';
import paths from 'constants/paths';

const ListBesiktningar = ({ list, filter }) => {
  const [showAktivitetModal, setShowAktivitetModal] = useState(false);
  const [aktivitetList, setAktivitetList] = useState([]);
  const [personal, setPersonal] = useState(0);
  const [level, setLevel] = useState(0);
  useEffect(() => {
    if (aktivitetList.length > 0) {
      setShowAktivitetModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aktivitetList]);

  useEffect(() => {
    let options = {};
    if (level > 0) {
      options.kundlevel = level;
    }
    if (personal > 0) {
      options.ansvarig = personal;
    }
    filter(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level, personal]);

  const registerSelectedPersonal = (e) => {
    setPersonal(e.target.value);
  };

  const levelSelect = (e) => {
    setLevel(e.target.value);
  };

  return (
    <div className="list-wrapper">
      <span className="wrapper">
        <span className="half-area column no-border">
          <PersonalSelectFormGroup
            roll={['besiktning', 'admin', 'projektbesiktning', 'affärsAdmin']}
            registerSelected={registerSelectedPersonal}
            label="Personal"
            strong={true}
          />
        </span>
        <span className="half-area column no-border">
          <Form.Label>
            <strong>Kundlevel</strong>
          </Form.Label>
          <Form.Control as="select" value={level} onChange={levelSelect} custom>
            <option value={0}>Välj level</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
          </Form.Control>
        </span>
      </span>

      <Table hover striped>
        <thead>
          <tr>
            <td>Skapad</td>
            <td>Affärsomr.</td>
            <td>Ansvarig</td>
            <td>Level</td>
            <td>Kontakt</td>
            <td>Aktivitet</td>
            <td>Bokad</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {list.map((data, i) => {
            return (
              <DataTable
                obj={data}
                key={i}
                setAktivitetList={setAktivitetList}
              />
            );
          })}
          {list.length === 0 && (
            <tr>
              <td colSpan={6}>
                <i>Ingen data tillänglig...</i>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <AktivitetModal
        show={showAktivitetModal}
        onHide={() => {
          setShowAktivitetModal(false);
          setAktivitetList([]);
        }}
        list={aktivitetList}
      />
    </div>
  );
};

const DataTable = ({ obj, setAktivitetList }) => {
  // eslint-disable-next-line no-unused-vars
  return (
    <tr>
      <td className="date">{util.formatDate(obj.createdAt)}</td>
      <td>{obj.affarsomrade}</td>
      <td>{util.getPersonalName(obj.ansvarigPersonal, true)}</td>
      <td>{obj.kundlevel}</td>
      <td>
        {util.getKontaktNameRemoveDuplicate(obj.kontakt)}, {obj.kontakt.postort}
        <br />
        {obj.kontakt.telefon1 && (
          <>
            <a href={`tel:${obj.kontakt.telefon1}`}>{obj.kontakt.telefon1}</a>
            <br />
          </>
        )}
        <a
          href={`mailto:${obj.kontakt.epost}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {obj.kontakt.epost}
        </a>
      </td>
      <td>
        {obj.aktiviteter.length > 0 && (
          <Button
            variant="link"
            className="btn-small"
            onClick={() => {
              setAktivitetList(obj.aktiviteter);
            }}
          >
            {obj.aktiviteter.length}&nbsp;aktivitet
            {obj.aktiviteter.length > 1 ? 'er' : ''}{' '}
          </Button>
        )}
      </td>
      <td>
        {obj.tidsbokningar.length > 0
          ? util.formatDate(obj.tidsbokningar[0].starttid, true)
          : '-'}
      </td>
      <td>
        <Link to={resolvePath(paths.VIEW_CONTACT, obj.kontaktId)}>
          <ArrowRightCircleFill
            color="gray"
            size={24}
            className={'click-item'}
          />
        </Link>
      </td>
    </tr>
  );
};

export default ListBesiktningar;
