import { useEffect, useCallback, useContext, useReducer } from 'react';
import { doPost, doGetList } from '../../action/services';
import { Button, Modal, Form, Container } from 'react-bootstrap';
import { Envelope } from 'react-bootstrap-icons';
import util from '../../../utils/util';
import { RadeaAlert } from '../../common/radea-alert.component';
import OrderbekraftelseModal from '../components/orderbekraftelsemodal.component';
import { GlobalStateContext } from 'global.context';
import { OrderbekraftelseList } from './orderbekraftelse-list';
import { OrderArtikelForm } from './order-artikel-form';
import { HusarbeteForm } from './husarbete-form';
import { OrderbekraftelseForm } from './orderbekraftelse-form';
import { useNavigationBlock } from 'utils/hooks/useNavigationBlock';
import { IOrder } from 'types/order';
import reducer, { initState } from './reducer';

const Orderbekraftelse = (props) => {
  const { projekt, kontakt } = props;
  const { personalList } = useContext(GlobalStateContext);
  const [
    {
      list,
      orderrader,
      orderChanges,
      raderChanges,
      rotavdrag,
      status,
      formData,
      formData3,
      alert,
      husarbeteChanges,
      showSaveModal,
      showSendModal,
      showChanges,
      showForm,
    },
    dispatch,
  ] = useReducer(reducer, { projekt }, initState);

  const [blockNavigation, unblockNavigation] = useNavigationBlock(
    'Är du säker på att du vill lämna sidan? Inga ändringar som gjorts kommer att sparas.',
    () => {
      dispatch({
        type: 'SHOW_CHANGES',
        payload: true,
      });
    }
  );

  const eventUpdateStatus = (e) => {
    dispatch({
      type: 'SET_STATUS',
      payload: e.target.value,
    });
  };

  useEffect(() => {
    if (formData3.rotavdrag >= 0) {
      dispatch({
        type: 'SET_ROTAVDRAG',
        payload: formData3.rotavdrag,
      });
    }
  }, [formData3.rotavdrag]);

  const getOrderbekraftelser = useCallback(async () => {
    const res = await doGetList(`/order/projekt/${projekt.id}`);
    dispatch({
      type: 'LOAD_ORDERBEKRAFTELSER',
      payload: res.data,
    });
  }, [projekt.id]);

  useEffect(() => {
    getOrderbekraftelser();
  }, [projekt.id, getOrderbekraftelser]);

  const createOrderbekraftelse = async () => {
    let data = {
      ...{ projektId: projekt.id },
      ...formData,
      ...formData3,
      ...{ orderrader: orderrader },
      ...{ status: status },
    };
    delete data?.skickatdatum;

    let result = await doPost('/order/createupdate', data);
    if (result.data && result.data.success) {
      showAlert('Orderbekräftelsen har sparats.', 'success');
      getOrderbekraftelser();
      dispatch({ type: 'CLEAR' });
      unblockNavigation();
    } else {
      showAlert(
        `Fel, orderbekräftelsen kunde inte sparas, kontakta supporten.`,
        'danger'
      );
    }
  };

  const sendOrder = async (data) => {
    let result = await doPost(`/order/skicka`, data);
    if (result.data && result.data.success) {
      showAlert(
        `${
          result.data.msg ? result.data.msg : 'Orderbekräftelsen har skickats.'
        }`,
        'success'
      );
      getOrderbekraftelser();
    } else if (result.data) {
      showAlert(
        `${
          result.data.msg
            ? result.data.msg
            : 'Fel, orderbekräftelsen kunde inte skickas, kontakta supporten.'
        }`,
        'danger'
      );
    } else {
      showAlert(
        'Fel, orderbekräftelsen kunde inte skickas, kontakta supporten.',
        'danger'
      );
    }
  };

  const editOrderbekraftelse = (data: IOrder, i: number) => {
    blockNavigation();

    dispatch({ type: 'CLEAR' });
    dispatch({
      type: 'SELECT_ORDERBEKRAFTELSE',
      payload: {
        order: data,
        personalList,
      },
    });

    dispatch({ type: 'CALCULATE_HUSARBETE', payload: data.orderrader || [] });
  };

  const showAlert = (msg, type) => {
    dispatch({
      type: 'ALERT',
      payload: {
        show: true,
        msg: msg,
        type: type,
      },
    });
    setTimeout(() => {
      dispatch({
        type: 'ALERT',
        payload: {
          show: false,
          msg: '',
          type: 'success',
        },
      });
    }, 2800);
  };

  return (
    <>
      <OrderbekraftelseList
        list={list}
        editOrderbekraftelse={editOrderbekraftelse}
      />
      {showForm && (
        <>
          <div>
            {formData.id === 0 && (
              <h4 className="border-top">
                {' '}
                <strong>Skapa ny order</strong>
              </h4>
            )}
            {(formData.id || 0) > 0 && (
              <h4 className="border-top">
                {' '}
                <strong>Order {formData.ordernummer}</strong>{' '}
                {formData.skickatdatum
                  ? `(Skickad ${formData.skickatdatum})`
                  : ''}
              </h4>
            )}
            <div>
              <Form.Label>
                <strong>Status</strong>
              </Form.Label>
              <Form.Control
                as="select"
                value={status}
                onChange={eventUpdateStatus}
                custom
              >
                {util.orderstatus.map((data, i) => {
                  return (
                    <option key={`status${i}`} value={i}>
                      {data}
                    </option>
                  );
                })}
              </Form.Control>
            </div>
          </div>
          <Container className="bordered-container row-container ">
            <Form className="orderbekraftelse-form radea-form">
              <OrderbekraftelseForm
                data={formData}
                onChange={(field, value, type) => {
                  dispatch({
                    type: 'ORDER_CHANGE',
                    payload: { field, value, type },
                  });
                }}
                changes={showChanges ? orderChanges : undefined}
              />
              <OrderArtikelForm
                rows={orderrader}
                rotavdrag={rotavdrag}
                changes={showChanges ? raderChanges : []}
                onChange={(rows) => {
                  dispatch({ type: 'CALCULATE_HUSARBETE', payload: rows });
                  dispatch({
                    type: 'ROW_CHANGE',
                    payload: rows,
                  });
                }}
              />
              <HusarbeteForm
                data={formData3}
                onChange={(field, value, type) => {
                  dispatch({
                    type: 'HUSARBETE_CHANGE',
                    payload: {
                      field,
                      value,
                      type,
                    },
                  });
                }}
                changes={showChanges ? husarbeteChanges : undefined}
              />
            </Form>
          </Container>
        </>
      )}
      <div className="button-row right bottom-item">
        {showForm && formData.ordernummer > 0 && (
          <>
            <a
              className="btn btn-link"
              role="button"
              href={`/radea/order/pdf/${formData.ordernummer}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Visa PDF
            </a>
            <Button
              variant={'link'}
              onClick={() => {
                dispatch({
                  type: 'SHOW_SEND_MODAL',
                  payload: true,
                });
              }}
            >
              <Envelope size="20" style={{ margin: '0 4px 2px 0' }} />
              Skicka order
            </Button>
            <OrderbekraftelseModal
              show={showSendModal}
              onHide={() => {
                dispatch({
                  type: 'SHOW_SEND_MODAL',
                  payload: false,
                });
              }}
              ordernr={formData.ordernummer}
              validEmail={util.validateEmail(kontakt.epost)}
              onSubmit={sendOrder}
            />
          </>
        )}
        {showForm && (
          <>
            <Button
              variant={'link'}
              onClick={() => {
                dispatch({ type: 'CLEAR' });
                dispatch({
                  type: 'SHOW_FORM',
                  payload: false,
                });
              }}
            >
              Avbryt
            </Button>
            <Button
              onClick={() => {
                dispatch({
                  type: 'SHOW_SAVE_MODAL',
                  payload: true,
                });
              }}
            >
              Spara
            </Button>
          </>
        )}
        {!showForm && (
          <Button
            variant={'link'}
            onClick={() => {
              dispatch({
                type: 'SHOW_FORM',
                payload: true,
              });
            }}
          >
            Skapa orderbekräftelse
          </Button>
        )}
      </div>

      <RadeaAlert msg={alert.msg} type={alert.type} show={alert.show} />

      <Modal
        show={showSaveModal}
        onHide={() => {
          dispatch({
            type: 'SHOW_SAVE_MODAL',
            payload: false,
          });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Skapa orderbekräftelse</Modal.Title>
        </Modal.Header>
        <Modal.Body>Är du säker?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            onClick={() => {
              dispatch({
                type: 'SHOW_SAVE_MODAL',
                payload: false,
              });
            }}
          >
            Avbryt
          </Button>
          <Button
            variant="success"
            onClick={() => {
              dispatch({
                type: 'SHOW_SAVE_MODAL',
                payload: false,
              });
              createOrderbekraftelse();
            }}
          >
            Spara
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Orderbekraftelse;
