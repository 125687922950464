import { useHistory } from 'react-router-dom';
import { ArrowLeftCircleFill } from 'react-bootstrap-icons';
import paths from 'constants/paths';
import { useQuery } from '../../hooks/useQuery';
import { getBackUrl } from 'utils/path';

export interface IBackButtonProps {
  useBackUrl?: boolean;
}

const BackButton = ({ useBackUrl = false }: IBackButtonProps) => {
  const query = useQuery();
  const history = useHistory();

  return (
    <div className="back-button">
      <ArrowLeftCircleFill
        onClick={() => {
          const backUrl = getBackUrl(query);
          if (useBackUrl && backUrl) {
            history.push(backUrl);
            return;
          }
          history.push(paths.START);
        }}
        color="gray"
        size={40}
        style={{ cursor: 'pointer' }}
      />
    </div>
  );
};

export default BackButton;
