import { useState } from 'react';
import { Nav } from 'react-bootstrap';
import BackButton from '../common/back-button.component';
import Scheman from './scheman.component';
import PrivatBokning from './privat-bokning.component';
import GeneralEvents from './general-events';
import { RollKoll } from 'components/common/roll.component';

const STATE_SCHEMA = 0,
  STATE_PERSONLIG = 1,
  STATE_GENERAL = 2;

const SchemaMeny = () => {
  const [active, setActive] = useState(STATE_SCHEMA);
  return (
    <>
      <BackButton />
      <h2>Scheman och bokningar</h2>
      <Nav className="margin-top" variant="tabs" defaultActiveKey={active}>
        <Nav.Item>
          <Nav.Link
            eventKey={STATE_SCHEMA}
            onClick={(e) => {
              setActive(STATE_SCHEMA);
            }}
          >
            Scheman
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey={STATE_PERSONLIG}
            onClick={(e) => {
              setActive(STATE_PERSONLIG);
            }}
          >
            Privat bokning
          </Nav.Link>
        </Nav.Item>
        <RollKoll role={['admin', 'affärsAdmin']}>
          <Nav.Item>
            <Nav.Link
              eventKey={STATE_GENERAL}
              onClick={(e) => {
                setActive(STATE_GENERAL);
              }}
            >
              Allmän bokning
            </Nav.Link>
          </Nav.Item>
        </RollKoll>
      </Nav>
      {active === STATE_SCHEMA && (
        <>
          <Scheman />
        </>
      )}
      {active === STATE_PERSONLIG && (
        <>
          <PrivatBokning />
        </>
      )}
      {active === STATE_GENERAL && (
        <>
          <GeneralEvents />
        </>
      )}
    </>
  );
};

export default SchemaMeny;
