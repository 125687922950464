import { useState, useEffect } from 'react';
import { getAxiosInstance } from 'components/action/services';
import { IKontakt } from 'types/kontakt';
const axios = getAxiosInstance();

export interface IProjektShort {
  id: number;
  createdAt?: string;
  updatedAt?: string;
  namn?: string;
  fastighetsbeteckning?: string;
  kontakt?: IKontakt;
  ort?: string;
}

export function useMyProjekts(
  projektId: number | string
): IProjektShort[] | null {
  const [projekt, setProjekt] = useState(null);

  useEffect(() => {
    axios.get(`/personal/me/projekt/${projektId}`).then((res) => {
      setProjekt(res.data);
    });
  }, [projektId]);

  return projekt;
}
