import { useState } from 'react';
import { Table } from 'react-bootstrap';
import { cleanEscapedName, isImagePath } from 'utils/file';
import styled from 'styled-components';
import { IBilaga } from 'types';
import { Carousel, Modal, Image } from 'react-bootstrap';

const BilagaLink = styled.a`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;

export interface ISimpleGalleryProps {
  files: IBilaga[];
  formatSrc?: (bilaga: IBilaga) => string;
  linkWidth?: string;
}

export function SimpleGallery({
  files,
  formatSrc = (file) => `/radea/bilaga/${file.path}`,
}: ISimpleGalleryProps) {
  const [current, setCurrent] = useState<number | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const imageFiles = files.filter((f) => isImagePath(f.path));
  const currentIndex = imageFiles.findIndex((f) => f.id === current);

  return (
    <>
      <Modal
        dialogClassName="responsive"
        centered
        size="lg"
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        onExited={() => {
          setCurrent(null);
        }}
      >
        <Carousel
          activeIndex={currentIndex || 0}
          controls={imageFiles.length > 1}
          onSelect={(index) => {
            setCurrent(imageFiles[index].id);
          }}
        >
          {imageFiles.map((f) => (
            <Carousel.Item key={f.id}>
              <Image src={formatSrc(f)} fluid />
            </Carousel.Item>
          ))}
        </Carousel>
      </Modal>
      <Table hover striped size="sm" style={{ tableLayout: 'fixed' }}>
        <tbody>
          {files.length === 0 && (
            <tr>
              <td>
                <i>Inga dokument</i>
              </td>
            </tr>
          )}
          {files.map((bilaga) => {
            const src = formatSrc(bilaga);

            return (
              <tr>
                <td>
                  <BilagaLink
                    target="_blank"
                    rel="noopener noreferrer"
                    href={src}
                    onClick={(e) => {
                      if (!isImagePath(bilaga.path)) {
                        return;
                      }
                      e.preventDefault();
                      setShowModal(true);
                      setCurrent(bilaga.id);
                    }}
                  >
                    {cleanEscapedName(bilaga.originalName)}
                  </BilagaLink>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

export default SimpleGallery;
