import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Card, Button, Form, Col, Modal } from 'react-bootstrap';
import { doPost, doGetList, doGet } from '../action/services';
import { Table } from 'react-bootstrap';
import { Pencil } from 'react-bootstrap-icons';
import { IRadeaAlertProps, RadeaAlert } from '../common/radea-alert.component';
import { formatName } from 'utils/personal';
import { IPersonal, RoleType } from 'types/personal';
import { ThemeVariants } from 'types';
import { getAxiosInstance } from '../action/services';
import { hasRole } from 'utils/auth';
import { GlobalStateContext } from 'global.context';
import { personalSchema } from 'utils/personal';
const axios = getAxiosInstance();

const PersonalAdmin = () => {
  const [personalList, setPersonalList] = useState<IPersonal[]>([]);
  const [editMode, setEditMode] = useState(false);
  const [visaInaktiva, setVisaInaktiva] = useState(false);
  const [personalEditId, setPersonalEditId] = useState(0);
  const [refresh, setRefresh] = useState(true);

  const getPersonalList = useCallback(async () => {
    const res = await doGetList(`/personal/all`);
    setPersonalList(res.data);
    setRefresh(false);
  }, [setPersonalList]);

  const skapaNy = () => {
    setPersonalEditId(0);
    setEditMode(true);
  };

  useEffect(() => {
    if (refresh) {
      getPersonalList();
    }
  }, [refresh, getPersonalList]);

  return (
    <>
      {!editMode && (
        <div className="list-wrapper">
          <div className="container">
            <span className="button-row right">
              <Button
                variant="link"
                onClick={() => {
                  skapaNy();
                }}
              >
                Skapa ny
              </Button>
            </span>
            <span className="button-row right">
              <Form.Check
                type="checkbox"
                checked={visaInaktiva}
                onChange={(e) => {
                  setVisaInaktiva(e.target.checked);
                }}
              />
              <Form.Label>Visa inaktiva</Form.Label>
            </span>
            <Table hover striped>
              <thead>
                <tr>
                  <td>Namn</td>
                  <td>E-post</td>
                  <td>Roller</td>
                  <td>Aktiv</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {personalList.map((data, i) => {
                  return (
                    <React.Fragment key={i}>
                      {(data.aktiv || (!data.aktiv && visaInaktiva)) && (
                        <DataRow
                          obj={data}
                          key={i}
                          setEditPersonal={(id) => {
                            setPersonalEditId(id);
                            setEditMode(true);
                          }}
                          avbryt={() => {
                            setEditMode(false);
                          }}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      )}
      {editMode && (
        <PersonalForm
          personalId={personalEditId}
          avbryt={() => {
            setEditMode(false);
          }}
          refresh={(id) => {
            setRefresh(true);
            if (id) {
              setPersonalEditId(id);
            }
          }}
        />
      )}
    </>
  );
};

const DataRow = (props) => {
  const { obj, setEditPersonal } = props;
  return (
    <tr>
      <td>{formatName(obj)}</td>
      <td>{obj.epost}</td>
      <td>
        {obj.roller.map((data, i) => {
          return <span key={`tut${i}`}>{data.typ}</span>;
        })}
      </td>
      <td>{obj.aktiv ? 'Aktiv' : 'Ej aktiv'}</td>
      <td>
        <span>
          <Pencil
            className="click-item blue"
            onClick={() => {
              setEditPersonal(obj.id);
            }}
          />
        </span>
      </td>
    </tr>
  );
};

export interface IPersonalFormData {
  id: number;
  fornamn?: string;
  efternamn?: string;
  login?: string;
  epost?: string;
  password?: string;
  passwordagain?: string;
  aktiv?: boolean;
  roller?: RoleType[];
}

const PersonalForm = (props) => {
  const { personalId, avbryt, refresh } = props;
  const [renderForm, setRenderForm] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const { session } = useContext(GlobalStateContext);

  const getEmptyForm = (): IPersonalFormData => {
    return {
      id: personalId,
    };
  };

  const [formData, setFormData] = useState<IPersonalFormData>(getEmptyForm());

  const getPersonal = useCallback(async () => {
    if (personalId > 0) {
      let result = await doGet(`/personal/${personalId}`);
      if (result.data) {
        setFormData({
          id: result.data.id,
          fornamn: result.data.fornamn,
          efternamn: result.data.efternamn,
          login: result.data.login,
          epost: result.data.epost,
          aktiv: result.data.aktiv,
          roller: result.data.roller.map((r) => r.typ),
        });
        setRenderForm(true);
      }
    } else {
      setRenderForm(true);
    }
  }, [personalId]);

  const setAktiv = async (aktivera: boolean) => {
    let result = await axios.put(
      `/personal/${aktivera ? 'aktivera' : 'inaktivera'}/${personalId}`
    );
    if (result.data.success) {
      showAlert(
        `Användaren är nu ${aktivera ? 'aktiv' : 'inaktiv'}.`,
        'success',
        () => {
          formData.aktiv = aktivera;
          refresh();
        }
      );
    } else {
      showAlert(`Ett fel uppstod, var god kontakta supporten.`, 'danger');
    }
  };

  useEffect(() => {
    getPersonal();
  }, [getPersonal]);

  const handleChange = (e) => {
    let { id, value } = e.target;
    setFormData({
      id: personalId,
      fornamn: id === 'fornamn' ? value : formData.fornamn,
      efternamn: id === 'efternamn' ? value : formData.efternamn,
      login: id === 'login' ? value.trim() : formData.login,
      epost: id === 'epost' ? value.trim() : formData.epost,
      password: id === 'password' ? value : formData.password,
      passwordagain: id === 'passwordagain' ? value : formData.passwordagain,
      aktiv: formData.aktiv,
      roller: id === 'roller' ? value : formData.roller,
    });
  };

  const createUpdatePersonal = async () => {
    const data = { ...formData };

    if (!hasRole('admin', session)) {
      delete data.roller;
    }

    let result = await doPost(
      `/personal/${personalId === 0 ? 'create' : 'update'}`,
      data
    );
    if (result.data && result.data.success) {
      showAlert('Användaren är uppdaterad.', 'success');
      if (result.data.id) {
        refresh(result.data.id);
      } else {
        refresh();
      }
    } else if (result.data && result.data.msg) {
      showAlert(result.data.msg, 'danger');
    } else {
      showAlert(
        'Fel på kommunikationen med servern, kontakta supporten.',
        'danger'
      );
    }
  };

  const [alert, setAlert] = useState<IRadeaAlertProps>({
    show: false,
    msg: '',
    type: 'info',
  });

  const showAlert = (
    msg: string,
    type: ThemeVariants,
    callback?: () => unknown
  ) => {
    setAlert({ show: true, msg: msg, type: type });
    setTimeout(() => {
      if (callback) {
        callback();
      }
      setAlert({
        show: false,
        msg: '',
        type: 'success',
      });
    }, 2000);
  };

  const regularRoles: RoleType[] = [
    'besiktning',
    'projektbesiktning',
    'montör',
    'håltagare',
    'elektriker',
  ];
  const systemRoles: (RoleType | null)[] = [
    'admin',
    'affärsAdmin',
    null,
    null,
    null,
  ];

  return (
    <>
      <Card className="form-card">
        <RadeaAlert msg={alert.msg} type={alert.type} show={alert.show} />
        <Modal
          show={showSaveModal}
          onHide={() => {
            setShowSaveModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {formData.id > 0 && <>Spara ändringar</>}{' '}
              {formData.id === 0 && <>Skapa ny användare</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Är du säker?</h5>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="link"
              onClick={() => {
                setShowSaveModal(false);
              }}
            >
              Avbryt
            </Button>
            <Button
              variant="success"
              onClick={() => {
                setShowSaveModal(false);
                createUpdatePersonal();
              }}
            >
              Spara
            </Button>
          </Modal.Footer>
        </Modal>
        {personalId > 0 && (
          <Card.Header as="h5">Redigera användare </Card.Header>
        )}
        {personalId === 0 && (
          <Card.Header as="h5">Skapa ny användare </Card.Header>
        )}
        <Card.Body>
          {renderForm && (
            <Form>
              <strong>
                Användaruppgifter {!formData.aktiv && <>(Inaktiv användare)</>}
              </strong>
              <Form.Row style={{ marginTop: '10px' }}>
                <Form.Group as={Col} controlId="fornamn">
                  <Form.Label>Förnamn</Form.Label>
                  <Form.Control
                    placeholder="Fyll i förnamn"
                    value={formData.fornamn}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="efternamn">
                  <Form.Label>Efternamn</Form.Label>
                  <Form.Control
                    placeholder="Fyll i efternamn"
                    value={formData.efternamn}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="login">
                  <Form.Label>Användarnamn</Form.Label>
                  <Form.Control
                    placeholder="Fyll i användarnamn"
                    value={formData.login}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="epost">
                  <Form.Label>E-post</Form.Label>
                  <Form.Control
                    placeholder="Fyll i epost"
                    value={formData.epost}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </Form.Row>
              <strong>Roller</strong>
              <Form.Row style={{ marginTop: '10px' }}>
                {regularRoles.map((r) => (
                  <Form.Group
                    as={Col}
                    className="row-direction"
                    style={{ display: 'flex' }}
                    controlId={`roll-${r}`}
                  >
                    <Form.Check
                      type="checkbox"
                      checked={(formData?.roller || []).includes(r)}
                      onChange={(e) => {
                        const newValue = e.target.checked
                          ? (formData?.roller || []).concat(r)
                          : (formData?.roller || []).filter(
                              (existing) => existing !== r
                            );

                        handleChange({
                          target: {
                            id: 'roller',
                            value: newValue,
                          },
                        });
                      }}
                    />
                    <Form.Label>{r}</Form.Label>
                  </Form.Group>
                ))}
              </Form.Row>
              <strong>Systemroll</strong>
              <Form.Row style={{ marginTop: '10px' }}>
                {systemRoles.map((r) =>
                  r === null ? (
                    <Form.Group as={Col} />
                  ) : (
                    <Form.Group
                      as={Col}
                      className="row-direction"
                      style={{ display: 'flex' }}
                      controlId={`roll-${r}`}
                    >
                      <Form.Check
                        type="checkbox"
                        checked={(formData?.roller || []).includes(r)}
                        onChange={(e) => {
                          const newValue = e.target.checked
                            ? (formData?.roller || []).concat(r)
                            : (formData?.roller || []).filter(
                                (existing) => existing !== r
                              );

                          handleChange({
                            target: {
                              id: 'roller',
                              value: newValue,
                            },
                          });
                        }}
                      />
                      <Form.Label>{r}</Form.Label>
                    </Form.Group>
                  )
                )}
              </Form.Row>
              <strong>Ändra lösenord</strong>
              <Form.Row style={{ marginTop: '10px' }}>
                <Form.Group as={Col} controlId="password">
                  <Form.Label>Lösenord</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Fyll i lösenord"
                    value={formData.password}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                  {personalId > 0 && (
                    <Form.Text className="text-muted">
                      Lämna lösenordsfälten tomma för att behålla samma
                      lösenord.
                    </Form.Text>
                  )}
                </Form.Group>
                <Form.Group as={Col} controlId="passwordagain">
                  <Form.Label>Repetera lösenord</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Fyll i lösenord igen"
                    value={formData.passwordagain}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          )}
        </Card.Body>
        <Card.Footer className="text-right">
          <Button
            variant="link"
            onClick={() => {
              avbryt();
              setFormData(getEmptyForm());
              setRenderForm(false);
            }}
          >
            Tillbaka
          </Button>
          {renderForm && (
            <>
              {formData.aktiv && personalId > 0 && (
                <Button
                  variant="link"
                  onClick={() => {
                    setAktiv(false);
                  }}
                >
                  Inaktivera användare
                </Button>
              )}
              {!formData.aktiv && personalId > 0 && (
                <Button
                  variant="link"
                  onClick={() => {
                    setAktiv(true);
                  }}
                >
                  Aktivera användare
                </Button>
              )}
            </>
          )}
          <Button
            variant="primary"
            onClick={() => {
              const { error } = personalSchema.validate(formData);
              if (!error) {
                return setShowSaveModal(true);
              }

              showAlert(error.message, 'danger');
            }}
          >
            Spara
          </Button>
        </Card.Footer>
      </Card>
    </>
  );
};
export default PersonalAdmin;
