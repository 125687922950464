import { useEffect, useState } from 'react';
import { Form, Col } from 'react-bootstrap';
import { PlusCircle, Trash, ArrowClockwise } from 'react-bootstrap-icons';
import { IOrderRow, IArtikel } from 'types';
import { ArtiklarSelectFormGroup } from '../../common/artiklar.component';
import { Orderrader } from './orderrader';
import { RowChange } from './reducer';

export interface IOrderArtikelFormProps {
  rows: IOrderRow[];
  onChange: (rows: IOrderRow[]) => unknown;
  rotavdrag: number;
  changes: RowChange[];
}

const emptyRow: IOrderRow = {
  antal: 0,
  artikelId: 0,
  artikelnummer: '',
  pris: 0,
  beskrivning: '',
  createdAt: '',
  updatedAt: '',
  extrainformation: '',
  husarbete: false,
  id: -1,
  orderbekraftelseId: -1,
};

export const OrderArtikelForm = ({
  rows,
  onChange,
  rotavdrag,
  changes,
}: IOrderArtikelFormProps) => {
  const [index, setIndex] = useState<null | number>(null);
  const [formData, setArtikel] = useState<IOrderRow>(emptyRow);
  const [artikelValid, setArtikelValid] = useState(false);
  const [editArtikelMode, setEditArtikelMode] = useState(false);

  const selectArtikel = (data: IOrderRow, i: number) => {
    setIndex(i);
    setArtikel(data);
    setEditArtikelMode(true);
  };

  const deleteArtikel = () => {
    if (index === null || index < 0) {
      return;
    }
    onChange(
      rows
        .slice(0, index)
        .concat(rows.slice(index + 1, 1))
        .concat(rows.slice(index + 1))
    );
    setArtikel(emptyRow);
    setIndex(null);
    setEditArtikelMode(false);
  };

  const addArtikel = () => {
    onChange(rows.concat(formData));
    setEditArtikelMode(false);
    setArtikel(emptyRow);
    setIndex(null);
  };

  const editArtikel = () => {
    if (index === null || index < 0) {
      return;
    }
    onChange(
      rows
        .slice(0, index)
        .concat(formData)
        .concat(rows.slice(index + 1, 1))
        .concat(rows.slice(index + 1))
    );
    setArtikel(emptyRow);
    setIndex(null);
    setEditArtikelMode(false);
  };

  const eventUpdateFormData = (e) => {
    updateFormData(e.target.id, e.target.value);
  };

  const updateFormData = (key: keyof IOrderRow, value: any) => {
    setArtikel({
      ...formData,
      [key]: value,
    });
  };

  const registerArtikel = (artikel: IArtikel) => {
    if (artikel) {
      setArtikel({
        ...formData,
        artikelId: artikel.id,
        artikelnummer: artikel.artikelnummer,
        beskrivning: artikel.beskrivning,
        extrainformation: '',
        antal: 1,
        pris: artikel.pris,
        husarbete: !!formData?.husarbete,
      });
    } else {
      setArtikel({
        artikelId: 0,
        artikelnummer: '',
        beskrivning: '',
        extrainformation: '',
        antal: 0,
        pris: 0,
        husarbete: false,
      });
    }
  };

  useEffect(() => {
    if ((formData?.artikelId ?? 0) > 0 && !artikelValid) {
      setArtikelValid(true);
    } else if ((formData?.artikelId ?? 0) === 0 && artikelValid) {
      setArtikelValid(false);
    }
  }, [formData.artikelId, artikelValid]);

  return (
    <>
      <Orderrader
        list={rows}
        editArtikel={selectArtikel}
        rotavdrag={rotavdrag}
        changes={changes}
      />
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>Artikel</Form.Label>
          <ArtiklarSelectFormGroup
            registerSelected={registerArtikel}
            typ={1}
            selectedId={formData.artikelId}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="husarbete" className="col-1">
          <Form.Label>Husarb.</Form.Label>
          <Form.Check
            type="checkbox"
            value={String(formData.husarbete)}
            checked={formData.husarbete}
            onChange={(e) => {
              updateFormData(
                'husarbete',
                e.target.value === 'false' ? true : false
              );
            }}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="beskrivning">
          <Form.Label>Beskrivning</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Beskrivning (max 50 tecken i Fortnox)"
            value={formData.beskrivning}
            onChange={eventUpdateFormData}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="extrainformation">
          <Form.Label>Extrainfo</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Extrainformation"
            value={formData.extrainformation}
            onChange={eventUpdateFormData}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="antal" className={'col-1'}>
          <Form.Label>Antal</Form.Label>
          <Form.Control
            placeholder="Antal"
            value={formData.antal}
            onChange={eventUpdateFormData}
            autoComplete="off"
          />
        </Form.Group>
        <div className={'form-row-col add-button-container col-1'}>
          <Form.Group as={Col} controlId="pris">
            <Form.Label>Pris</Form.Label>
            <Form.Control
              placeholder="Pris"
              value={formData.pris}
              onChange={eventUpdateFormData}
              autoComplete="off"
            />
          </Form.Group>
          {artikelValid && (
            <div className={'button-row bottom fill-width'}>
              <Trash
                style={{ marginBottom: '4px' }}
                size={25}
                className={'click-item gray'}
                onClick={deleteArtikel}
              />
              {editArtikelMode && (
                <ArrowClockwise
                  className={'add-button valid'}
                  size={36}
                  onClick={() => {
                    editArtikel();
                  }}
                />
              )}
              {!editArtikelMode && (
                <PlusCircle
                  className={'add-button valid'}
                  size={36}
                  onClick={() => {
                    addArtikel();
                  }}
                />
              )}
            </div>
          )}
          {!artikelValid && <PlusCircle className={'add-button'} size={36} />}
        </div>
      </Form.Row>
    </>
  );
};
