import React, { useState } from 'react';
import BackButton from '../common/back-button.component';
import { Container, Nav } from 'react-bootstrap';
import Kategori from './kategori';
import Artikel from './artiklar';
import MallText from './mall-text.component';
import PersonalAdmin from './personaladmin';
import { RollKoll } from '../common/roll.component';

const STATE_ARTIKLAR = 0,
  STATE_TEXTER = 1,
  STATE_KATEGORIER = 2,
  STATE_ANVANDARE = 3;
const AdminStart = (props) => {
  const [active, setActive] = useState(STATE_ANVANDARE);
  return (
    <>
      <Container>
        <BackButton />
        <RollKoll role={['admin']} msg={true}>
          <h2>Administration</h2>
        </RollKoll>
      </Container>
      <RollKoll role={['admin']} msg={true}>
        <Nav variant="tabs" defaultActiveKey={active}>
          <Nav.Item>
            <Nav.Link
              eventKey={STATE_ANVANDARE}
              onClick={(e) => {
                setActive(STATE_ANVANDARE);
              }}
            >
              Personal
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey={STATE_ARTIKLAR}
              onClick={(e) => {
                setActive(STATE_ARTIKLAR);
              }}
            >
              Artiklar
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey={STATE_TEXTER}
              onClick={(e) => {
                setActive(STATE_TEXTER);
              }}
            >
              Texter
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey={STATE_KATEGORIER}
              onClick={(e) => {
                setActive(STATE_KATEGORIER);
              }}
            >
              Kategorier
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Container className={'tab-container'}>
          {active === STATE_ANVANDARE && (
            <>
              <PersonalAdmin />
            </>
          )}
          {active === STATE_ARTIKLAR && (
            <>
              <Artikel />
            </>
          )}
          {active === STATE_TEXTER && (
            <>
              <MallText />
            </>
          )}
          {active === STATE_KATEGORIER && (
            <>
              <Kategori />
            </>
          )}
        </Container>
      </RollKoll>
    </>
  );
};

export default AdminStart;
