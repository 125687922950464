import React, { useEffect, useState, useContext } from 'react';
import { Card, Button, Form, Col, Modal } from 'react-bootstrap';
import { doPost, doGet } from '../action/services';
import { RadeaAlert } from '../common/radea-alert.component';
import { GlobalStateContext } from '../../global.context';
import { useHistory } from 'react-router-dom';
import { personalSchema } from 'utils/personal';

const MinaUppgifter = (props) => {
  const [renderForm, setRenderForm] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const { session } = useContext(GlobalStateContext);
  let history = useHistory();

  const [formData, setFormData] = useState({
    id: 0,
    fornamn: '',
    efternamn: '',
    login: '',
    epost: '',
  });

  useEffect(() => {
    getData();

    async function getData() {
      if (session.id > 0) {
        let result = await doGet(`/personal/${session.id}`);
        if (result.data) {
          setFormData({
            id: result.data.id,
            fornamn: result.data.fornamn,
            efternamn: result.data.efternamn || undefined,
            login: result.data.login,
            epost: result.data.epost || undefined,
          });
          setRenderForm(true);
        }
      } else {
        setRenderForm(true);
      }
    }
  }, [session.id]);

  const handleChange = (e) => {
    let { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const updatePersonal = async () => {
    let result = await doPost(`/personal/update`, formData);
    if (result.data && result.data.success) {
      showAlert('Dina uppgifter är uppdaterade.', 'success');
    } else if (result.data && result.data.msg) {
      showAlert(result.data.msg, 'danger');
    } else {
      showAlert(
        'Fel på kommunikationen med servern, kontakta supporten.',
        'danger'
      );
    }
  };

  const [alert, setAlert] = useState({ show: false, msg: '', type: '' });

  const showAlert = (msg, type, callback) => {
    setAlert({ show: true, msg: msg, type: type });
    setTimeout(() => {
      if (callback) {
        callback();
      }
      setAlert({
        show: false,
        msg: '',
        type: 'success',
      });
    }, 2000);
  };

  return (
    <>
      <Card className="form-card">
        <RadeaAlert msg={alert.msg} type={alert.type} show={alert.show} />
        <Modal
          show={showSaveModal}
          onHide={() => {
            setShowSaveModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {formData.id > 0 && <>Spara ändringar</>}{' '}
              {formData.id === 0 && <>Skapa ny användare</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Är du säker?</h5>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="link"
              onClick={() => {
                setShowSaveModal(false);
              }}
            >
              Avbryt
            </Button>
            <Button
              variant="success"
              onClick={() => {
                setShowSaveModal(false);
                updatePersonal();
              }}
            >
              Spara
            </Button>
          </Modal.Footer>
        </Modal>
        <Card.Header as="h5">Redigera uppgifter </Card.Header>
        <Card.Body>
          {renderForm && (
            <Form>
              <strong>Mina användaruppgifter</strong>
              <Form.Row style={{ marginTop: '10px' }}>
                <Form.Group as={Col} controlId="fornamn">
                  <Form.Label>Förnamn</Form.Label>
                  <Form.Control
                    placeholder="Fyll i förnamn"
                    value={formData.fornamn}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="efternamn">
                  <Form.Label>Efternamn</Form.Label>
                  <Form.Control
                    placeholder="Fyll i efternamn"
                    value={formData.efternamn}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="login">
                  <Form.Label>Användarnamn</Form.Label>
                  <Form.Control
                    placeholder="Fyll i användarnamn"
                    value={formData.login}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="epost">
                  <Form.Label>E-post</Form.Label>
                  <Form.Control
                    placeholder="Fyll i epost"
                    value={formData.epost}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </Form.Row>
              <strong>Ändra lösenord</strong>
              <Form.Row style={{ marginTop: '10px' }}>
                <Form.Group as={Col} controlId="password">
                  <Form.Label>Lösenord</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Fyll i lösenord"
                    value={formData.password}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                  <Form.Text className="text-muted">
                    Lämna lösenordsfälten tomma när du sparar för att behålla
                    samma lösenord.
                  </Form.Text>
                </Form.Group>
                <Form.Group as={Col} controlId="passwordagain">
                  <Form.Label>Repetera lösenord</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Fyll i lösenord igen"
                    value={formData.passwordagain}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          )}
        </Card.Body>
        <Card.Footer className="text-right">
          <Button
            variant="link"
            onClick={() => {
              history.goBack();
            }}
          >
            Tillbaka
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              const { error } = personalSchema.validate(formData);
              if (!error) {
                setShowSaveModal(true);
                return false;
              }
              showAlert(error.message, 'danger');
            }}
          >
            Spara
          </Button>
        </Card.Footer>
      </Card>
    </>
  );
};

export default MinaUppgifter;
