import React, { useState, useEffect, useCallback, useRef } from 'react';
import { IRadeaAlertProps, RadeaAlert } from '../common/radea-alert.component';
import { doPost } from '../action/services';
import { Search as SearchIcon } from 'react-bootstrap-icons';
import { IKontaktSearchResult } from 'types/kontakt';

export interface ISearchProps {
  handler: (searchResults: IKontaktSearchResult[], query: string) => unknown;
  clear: () => unknown;
  className?: string;
  delay?: number;
}

export function Search({
  handler,
  clear,
  className,
  delay = 500,
}: ISearchProps) {
  const requestIndex = useRef(0);
  const lastResolvedRequest = useRef(0);
  const lastKeystroke = useRef<null | number>(null);
  const typingTimeout = useRef(0);

  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [alert, setAlert] = useState<IRadeaAlertProps>({
    show: false,
    msg: '',
    type: 'info',
  });

  const getSearchResult = useCallback(async () => {
    setLoading(true);
    let result = await doPost(`/kontakter/search`, { query: query });
    setLoading(false);
    return result;
  }, [query]);

  const handleSearchResult = useCallback(async () => {
    if (query.length <= 1) {
      clear();
      return;
    }

    const now = new Date().getTime();
    if (lastKeystroke.current !== null && now - lastKeystroke.current < delay) {
      clearTimeout(typingTimeout.current);
    }

    lastKeystroke.current = now;
    typingTimeout.current = window.setTimeout(async () => {
      try {
        requestIndex.current = requestIndex.current + 1;
        const currentRequestIndex = requestIndex.current;
        let result = await getSearchResult();
        if (lastResolvedRequest.current > currentRequestIndex) {
          return;
        }
        lastResolvedRequest.current = currentRequestIndex;
        handler(result.data, query);
      } catch (err) {
        showAlert(
          'Något är fel med sökfunktionen, kontakta supporten.',
          'danger'
        );
      }
    }, delay);
  }, [query, getSearchResult, handler, clear, delay]);

  useEffect(() => {
    if (!loading && reload) {
      setReload(false);
      handleSearchResult();
    }
  }, [loading, reload, handleSearchResult]);

  useEffect(() => {
    setReload(true);
  }, [query]);

  const handleOnInputChange = (e) => {
    let q = e.target.value;
    setQuery(q);
  };

  const showAlert = (msg, type) => {
    setAlert({ show: true, msg: msg, type: type });
    setTimeout(() => {
      setAlert({
        show: false,
        msg: '',
        type: 'success',
      });
    }, 1800);
  };

  const classes = ['search-label'];

  if (className) {
    classes.push(className);
  }
  return (
    <>
      <RadeaAlert msg={alert.msg} type={alert.type} show={alert.show} />
      <label className={classes.join(' ')} htmlFor="search-input">
        <SearchIcon size="22" />
        <input
          type="text"
          value={query}
          id="search-input"
          placeholder="Sök"
          onChange={handleOnInputChange}
          autoComplete="off"
        />
      </label>
    </>
  );
}

export default Search;
