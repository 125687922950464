import React from 'react';
import { Table } from 'react-bootstrap';

const ListArtiklar = (props) => {
  const {
    list, // artikellista
    toggle, // toggle aktiv func
    showEdit, // show edit artikel modal
    formData, // setformdata func
  } = props;

  return (
    <>
      <Table hover striped size="sm">
        <thead>
          <tr>
            <td>Artikelnummer</td>
            <td>Standardpris</td>
            <td>Artikelbeskrivning</td>
            <td>Konto</td>
            <td>Aktiv</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {list.map((data, i) => {
            return (
              <tr key={data.id}>
                <td>
                  <p
                    style={{ cursor: 'pointer' }}
                    title="Redigera artikeln"
                    onClick={() => {
                      formData(data);
                      showEdit(true);
                    }}
                  >
                    {data.artikelnummer}
                  </p>
                </td>
                <td>{data.pris}</td>
                <td>{data.beskrivning}</td>
                <td>{data.konto} </td>

                <td>
                  <p
                    style={{ cursor: 'pointer' }}
                    title="Ändra status på artikeln"
                    onClick={() => {
                      toggle(data);
                    }}
                  >
                    {data.aktiv ? 'Ja' : 'Nej'}
                  </p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default ListArtiklar;
