import React, { useState, useEffect } from 'react';
import { doGetList } from '../action/services';
import { Form } from 'react-bootstrap';

export const MalltexterSelectFormGroup = props => {
  const { small, registerMalltext, typ } = props;
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState(-1);

  useEffect(() => {
    const getMalltexter = async () => {
      let endpoint;
      if (typ && typ > 0) {
        endpoint = `/malltexter/get/${typ}`;
      } else {
        endpoint = `/malltexter/all`;
      }
      const res = await doGetList(endpoint);
      setList(res.data);
    }
    getMalltexter();
  }, [setList, typ]);

  const malltextSelect = (e) => {
    setSelected(e.target.value);
    if (registerMalltext && e.target.value >= 0) {
      registerMalltext(list[e.target.value].text);
    }
  }

  return (
    <>
      <Form.Control
        as="select"
        size={small ? "sm" : ""}
        value={selected}
        onChange={(e) => { malltextSelect(e) }}
        custom>
        <option value={-1}>Välj malltext</option>
        {list.map((data, i) => {
          return <option key={i} value={i} >{data.namn}</option>
        })}
      </Form.Control>
    </>
  )
}

export default { MalltexterSelectFormGroup }