import React, { useState, useContext } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { RadeaAlert } from '../common/radea-alert.component';
import { doPost } from '../action/services';
import { GlobalStateContext } from '../../global.context';
import { useHistory } from 'react-router-dom';
import paths, { public_paths } from 'constants/paths';

const Login = (props) => {
  let history = useHistory();
  const [alert, setAlert] = useState({ show: false, msg: '', type: '' });
  const [formData, setFormData] = useState({
    login: '',
    password: '',
  });
  const { loggedIn, setLoggedIn } = useContext(GlobalStateContext);

  const validateFormData = () => {
    if (formData.login === '') {
      showAlert('Du måste fylla i användarnamn.', 'danger');
      return false;
    }
    if (formData.password === '') {
      showAlert('Det saknas ett lösenord.', 'danger');
      return false;
    }
    return true;
  };

  const submitLogin = async () => {
    let result = await doPost('/auth/login', formData, true);
    if (result.data.success) {
      showAlert(result.data.msg, 'success', () => {
        if (loggedIn) {
          return;
        }
        setLoggedIn(result.data.session);

        let redirectPath =
          sessionStorage.getItem('current-path') || paths.START;
        if (public_paths.includes(redirectPath)) {
          redirectPath = paths.START;
        }
        history.replace(redirectPath);
        sessionStorage.setItem('current-path', '');
      });
      setFormData({
        login: '',
        password: '',
      });
    } else {
      showAlert(result.data.msg, 'danger');
    }
  };

  const showAlert = (msg, type, next) => {
    setAlert({ show: true, msg: msg, type: type });
    setTimeout(() => {
      setAlert({
        show: false,
        msg: '',
        type: 'success',
      });
      if (next) {
        next();
      }
    }, 1200);
  };

  const updateFormData = (e) => {
    const { id, value } = e.target;
    setFormData({
      login: id === 'login' ? value : formData.login,
      password: id === 'password' ? value : formData.password,
    });
  };
  const enterKeySubmit = (e) => {
    if (e.charCode === 13) {
      submitLogin();
    }
  };

  return (
    <>
      <Container className="login-container">
        <RadeaAlert msg={alert.msg} type={alert.type} show={alert.show} />
        <Form className="radea-form">
          <h3>Logga in på Radea CRM</h3>
          <Form.Group controlId="login">
            <Form.Label>Användarnamn</Form.Label>
            <Form.Control
              type="text"
              placeholder="Fyll i användarnamn"
              onChange={(e) => {
                updateFormData(e);
              }}
            />
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>Lösenord</Form.Label>
            <Form.Control
              type="password"
              placeholder="Fyll i lösenord"
              onChange={(e) => {
                updateFormData(e);
              }}
              onKeyPress={enterKeySubmit}
            />
            <Form.Text className="text-muted">
              Om du saknar användarnamn och lösenord, kontakta{' '}
              <a href="mailto:support@radea.se">support@radea.se</a>
            </Form.Text>
          </Form.Group>
          <div className="button-row right" style={{ margin: '25px 0 0' }}>
            <Button
              onClick={() => {
                if (validateFormData()) {
                  submitLogin();
                }
              }}
              variant="primary"
            >
              Logga in
            </Button>
          </div>
        </Form>
      </Container>
    </>
  );
};

export default Login;
