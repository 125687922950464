import React, { useState, useContext, useEffect, useCallback } from 'react';
import { RadeaAlert } from '../common/radea-alert.component';
import { doGet } from '../action/services';
import { GlobalStateContext } from '../../global.context';

const Logout = () => {
  const { setLoggedOut } = useContext(GlobalStateContext);
  const [alert, setAlert] = useState({ show: false, msg: '', type: '' });
  const [logout, setLogout] = useState(false);

  const showAlert = (msg, type, next) => {
    setAlert({ show: true, msg: msg, type: type });
    setTimeout(() => {
      setAlert({
        show: false,
        msg: '',
        type: 'success',
      });
      if (next) {
        next();
      }
    }, 1800);
  };

  const doLogout = useCallback(async () => {
    if (!logout) {
      let result = await doGet('/auth/logout', true);
      if (result.data.success) {
        setLoggedOut(result.data.session);
        showAlert(result.data.msg, 'success', () => {
          window.location.href = '/login';
        });
      } else {
        showAlert(result.data.msg, 'danger');
      }
      setLogout(true);
      sessionStorage.clear();
    }
  }, [setLoggedOut, logout]);

  useEffect(() => {
    doLogout();
  }, [logout, doLogout]);

  return <RadeaAlert msg={alert.msg} type={alert.type} show={alert.show} />;
};

export default Logout;
