import React, { useState, useCallback, useEffect } from 'react';
import { Button, Container, Form, Col, Table, Modal } from 'react-bootstrap';
import { doPost, doGetList, doGet } from '../action/services';
import { Pencil, Trash } from 'react-bootstrap-icons';
import { RadeaAlert } from '../common/radea-alert.component';


const MallText = (props) => {

  const [list, setList] = useState([]);
  const [alert, setAlert] = useState({ show: false, msg: "", type: "" });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteTextId, setDeleteTextId] = useState(0);
  const [formData, setFormData] = useState({
    id: 0,
    namn: "",
    text: "",
    typ: 2
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value
    })
  }

  const getTexter = useCallback(async () => {
    const res = await doGetList(`/malltexter/all/`);
    setList(res.data);
  }, []);

  const createText = async () => {
    const res = await doPost(`/malltexter/${formData.id === 0 ? "create" : "update"}`, formData);
    if (res.data && res.data.success) {
      showAlert('Texten har sparats.', 'success');
      getTexter();
      setFormData({
        id: 0,
        namn: "",
        text: "",
        typ: 2
      });
    } else {
      showAlert('Fel, texten kunde inte sparas, kontakta supporten.', 'danger');
    }
  }

  const deleteText = async () => {
    const res = await doGet(`/malltexter/delete/${deleteTextId}`);
    if (res.data && res.data.success) {
      showAlert('Texten har tagits bort.', 'success');
      getTexter();
    } else {
      showAlert('Fel, texten kunde inte tas bort, kontakta supporten.', 'danger');
    }
  }

  useEffect(() => {
    getTexter();
  }, [getTexter])

  const editText = (data) => {
    setFormData(data);
  }

  const clearForm = () => {
    setFormData({
      id: 0,
      namn: "",
      text: "",
      typ: 2
    })
  }

  const showAlert = (msg, type) => {
    setAlert({ show: true, msg: msg, type: type });
    setTimeout(() => {
      setAlert({
        show: false,
        msg: '',
        type: 'success'
      });
    }, 2000);
  }

  return (
    <Container>
      <RadeaAlert msg={alert.msg} type={alert.type} show={alert.show} />
      <Form>
        <strong>Mata in malltext</strong>
        <Form.Row style={{ "marginTop": "10px" }}>
          <Form.Group as={Col} controlId="namn" >
            <Form.Label>Textnamn</Form.Label>
            <Form.Control placeholder="Fyll i textnamn" value={formData.namn} onChange={handleChange} autoComplete="off" />
          </Form.Group>
          <Form.Group as={Col} controlId="text" >
            <Form.Label>Text</Form.Label>
            <Form.Control as="textarea" rows="8" placeholder="Fyll i malltext" value={formData.text} onChange={handleChange} />
          </Form.Group>
        </Form.Row>
      </Form>
      <div className="button-row right">
        {formData.id === 0 &&
          <Button onClick={() => { createText(); }}>Lägg till</Button>
        }
        {formData.id > 0 &&
          <>
            <Button variant="link" onClick={() => { clearForm() }}>
              Avbryt
            </Button>
            <Button onClick={() => { createText(); }}>Spara</Button>
          </>
        }
      </div>

      <MallTextList list={list} editText={editText} deleteText={(id) => { setShowDeleteModal(true); setDeleteTextId(id) }} />

      <Modal show={showDeleteModal} onHide={() => { setShowDeleteModal(false); setDeleteTextId(0) }}>
        <Modal.Header closeButton>
          <Modal.Title>Ta bort texten</Modal.Title>
        </Modal.Header>
        <Modal.Body>Är du säker?</Modal.Body>
        <Modal.Footer>
          <Button variant="link" onClick={() => { setShowDeleteModal(false); setDeleteTextId(0) }}>
            Avbryt
          </Button>
          <Button variant="danger" onClick={(id) => { setShowDeleteModal(false); deleteText(); }}>
            Ta bort
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

const MallTextList = props => {
  const { list, editText, deleteText } = props;
  return (
    <Table hover striped size="sm" className="dokument-table margin-top">
      <thead>
        <tr>
          <th>Textnamn</th>
          <th>Text</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {list.length === 0 &&
          <tr>
            <td colSpan={4}><i>Inga texter finns inmatade</i></td>
          </tr>
        }
        {list.map((data, i) => {
          return (
            <tr key={`${i}bstlist`}>
              <td >
                {data.namn}
              </td>
              <td >
                {data.text}
              </td>
              <td className="button-row"><Pencil size={20} className={"click-item blue space-around"} onClick={() => { editText(data, i) }} />
                <Trash size={20} className={"click-item space-around"} onClick={() => { deleteText(data.id) }} /></td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default MallText;