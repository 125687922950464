import React, { useState, useEffect, useCallback, useContext } from 'react';
import util from 'utils/util';
import { Trash } from 'react-bootstrap-icons';
import { doPost, doGetList } from '../action/services';
import { Table, Button, Modal } from 'react-bootstrap';
import { IRadeaAlertProps, RadeaAlert } from '../common/radea-alert.component';
import { GlobalStateContext } from '../../global.context';
import { ITidsbokning } from 'types';

const ManageGeneralEvents = (props) => {
  const { refreshList } = props;
  const [list, setList] = useState<ITidsbokning[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState<ITidsbokning | null>(null);
  const [alert, setAlert] = useState<IRadeaAlertProps>({
    show: false,
    msg: '',
    type: 'info',
  });
  const { session } = useContext(GlobalStateContext);

  const getTidsbokningar = useCallback(async () => {
    if (session.id) {
      const res = await doGetList(`/tidsbokningar/general`);
      setList(res.data);
    }
  }, [session.id]);

  const deleteTidsbokning = async () => {
    let result = await doPost('/tidsbokningar/delete', { id: deleteItem?.id });
    if (result.data.success) {
      showAlert('Bokningen är borttagen ur systemet.', 'success');
      setTimeout(() => {
        getTidsbokningar();
      }, 1800);
    } else {
      showAlert(
        'Fel, bokningen kunde inte tas bort, kontakta supporten.',
        'danger'
      );
    }
  };

  const showAlert = (msg, type) => {
    setAlert({ show: true, msg: msg, type: type });
    setTimeout(() => {
      setAlert({
        show: false,
        msg: '',
        type: 'success',
      });
    }, 2000);
  };

  useEffect(() => {
    getTidsbokningar();
  }, [refreshList, getTidsbokningar]);

  return (
    <>
      {list.length > 0 && (
        <>
          <Table hover striped size="sm" className="margin-top">
            <thead>
              <tr>
                <td>Info</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {list.map((data, i) => {
                return (
                  <tr key={`bp${i}`}>
                    <td>
                      <div>
                        <strong>
                          {!data.info || data.info === ''
                            ? 'ospec.'
                            : data.info}
                        </strong>
                      </div>
                      {util.formatDate(data.starttid, true)} -{' '}
                      {util.getEndDate(data.starttid, data.langd)} (
                      {util.getHoursMinutes(data.langd)})
                    </td>
                    <td>
                      <Trash
                        size={20}
                        className={'click-item gray'}
                        onClick={() => {
                          setShowDeleteModal(true);
                          setDeleteItem(data);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <RadeaAlert msg={alert.msg} type={alert.type} show={alert.show} />
          <Modal
            show={showDeleteModal}
            onHide={() => {
              setShowDeleteModal(false);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Avboka {deleteItem?.info}</Modal.Title>
            </Modal.Header>
            <Modal.Body>Är du säker?</Modal.Body>
            <Modal.Footer>
              <Button
                variant="link"
                onClick={() => {
                  setShowDeleteModal(false);
                }}
              >
                Stäng
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  setShowDeleteModal(false);
                  deleteTidsbokning();
                }}
              >
                Ta bort
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
      {list.length === 0 && (
        <span className="margin-top">
          <i>Inga inbokade åtgärder tillgängliga</i>
        </span>
      )}
    </>
  );
};

export default ManageGeneralEvents;
