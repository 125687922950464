import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import BackButton from '../common/back-button.component';
import { RadeaAlert } from '../common/radea-alert.component';
import { Button, Form, Col, Modal } from 'react-bootstrap';
import { doPost } from '../action/services';
import { RollKoll } from '../common/roll.component';
import useKategori from '../../utils/hooks/useKategori';
import kategori from 'constants/kategori';
import util from 'utils/util';
import paths from 'constants/paths';
import { resolvePath } from 'utils/path';
import { formatName } from 'utils/personal';

const SkapaKontakt = (props) => {
  const [kundkategorier] = useKategori(kategori.Kundkategori, false);
  let history = useHistory();

  const [formData, setFormData] = useState({
    forvaltare: '',
    foretagsnamn: '',
    fornamn: '',
    gatuadress: '',
    postnummer: '',
    kategori: 'Ospec.',
    efternamn: '',
    postort: '',
    telefon1: '',
    telefon2: '',
    epost: '',
    kontakttyp: 1,
  });

  const [showSaveModal, setShowSaveModal] = useState(false);
  const [validation, setValidation] = useState({ valid: false, msg: '' });
  const [alert, setAlert] = useState({ show: false, msg: '', type: '' });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value.trim(),
    });
  };

  const handleSubmit = async () => {
    let result = await doPost('/kontakter/create', formData);
    if (result.data.success) {
      showAlert('Kontakten har sparats i systemet.', 'success');
      setTimeout(() => {
        history.push(resolvePath(paths.VIEW_CONTACT, result.data.id));
      }, 1800);
    } else {
      showAlert(
        'Fel, kontakten har inte sparats i systemet, kontakta supporten.',
        'danger'
      );
    }
  };

  const showAlert = (msg, type) => {
    setAlert({ show: true, msg: msg, type: type });
    setTimeout(() => {
      setAlert({
        show: false,
        msg: '',
        type: 'success',
      });
    }, 1800);
  };

  const validate = () => {
    setValidation({ valid: true, msg: '' });
    if (
      formData.efternamn === '' &&
      formData.fornamn === '' &&
      formData.foretagsnamn === '' &&
      formData.gatuadress === '' &&
      formData.postnummer === '' &&
      formData.postort === '' &&
      formData.telefon1 === '' &&
      formData.telefon2 === '' &&
      formData.epost === ''
    ) {
      setValidation({
        valid: false,
        msg: 'Minst ett värde i formuläret måste fyllas i för att man ska kunna spara.',
      });
    } else {
      if (formData.epost !== '' && !util.validateEmail(formData.epost)) {
        setValidation({
          valid: false,
          msg: 'Formuläret kan inte sparas för E-posten är felinmatad.',
        });
      } else if (formData.telefon1 !== '' && formData.telefon1.length < 7) {
        setValidation({ valid: false, msg: 'Mobilnumret är för kort.' });
      } else if (formData.telefon2 !== '' && formData.telefon2.length < 6) {
        setValidation({ valid: false, msg: 'Fast telefon är för kort.' });
      } else if (
        formData.postnummer !== '' &&
        formData.postnummer.replace(/ /g, '').length !== 5
      ) {
        setValidation({
          valid: false,
          msg: 'Postnumret måste vara fem siffror.',
        });
      }
    }
  };

  return (
    <div>
      <RadeaAlert msg={alert.msg} type={alert.type} show={alert.show} />
      <BackButton />
      <RollKoll role={['admin', 'affärsAdmin']} msg={true}>
        <Form>
          <h2>Skapa ny kontakt</h2>
          <Form.Row>
            <Form.Group controlId="forvaltare" as={Col}>
              <Form.Label>Källa / Förvaltare</Form.Label>
              <Form.Control
                defaultValue={formData.forvaltare}
                placeholder="Fyll i källa / förvaltare"
                onChange={handleChange}
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group controlId="kategori" as={Col}>
              <Form.Label>Kundkategori</Form.Label>
              <Form.Control
                as="select"
                defaultValue={formData.kategori}
                onChange={handleChange}
                custom
              >
                {kundkategorier.map((kategori) => {
                  return <option value={kategori.namn}>{kategori.namn}</option>;
                })}
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group controlId="foretagsnamn" as={Col} xs={6}>
              <Form.Label>Företagsnamn</Form.Label>
              <Form.Control
                defaultValue={formData.foretagsnamn}
                placeholder="Fyll i företagsnamn"
                onChange={handleChange}
                autoComplete="off"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group controlId="fornamn" as={Col}>
              <Form.Label>Förnamn</Form.Label>
              <Form.Control
                defaultValue={formData.fornamn}
                placeholder="Fyll i förnamn"
                onChange={handleChange}
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group controlId="efternamn" as={Col}>
              <Form.Label>Efternamn</Form.Label>
              <Form.Control
                defaultValue={formData.efternamn}
                placeholder="Fyll i efternamn"
                onChange={handleChange}
                autoComplete="off"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group controlId="gatuadress" as={Col} xs={6}>
              <Form.Label>Gatuadress</Form.Label>
              <Form.Control
                defaultValue={formData.gatuadress}
                placeholder="Fyll i gatuadress"
                onChange={handleChange}
                autoComplete="off"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group controlId="postnummer" as={Col}>
              <Form.Label>Postnummer</Form.Label>
              <Form.Control
                defaultValue={formData.postnummer}
                placeholder="Fyll i postnummer"
                onChange={handleChange}
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group controlId="postort" as={Col}>
              <Form.Label>Postort</Form.Label>
              <Form.Control
                defaultValue={formData.postort}
                placeholder="Fyll i postort"
                onChange={handleChange}
                autoComplete="off"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group controlId="telefon1" as={Col}>
              <Form.Label>Telefon, mobil</Form.Label>
              <Form.Control
                defaultValue={formData.telefon1}
                placeholder="Fyll i mobilnummer"
                onChange={handleChange}
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group controlId="telefon2" as={Col}>
              <Form.Label>Telefon, fast</Form.Label>
              <Form.Control
                defaultValue={formData.telefon2}
                placeholder="Fyll i telefonnummer"
                onChange={handleChange}
                autoComplete="off"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group controlId="epost" as={Col} xs={6}>
              <Form.Label>E-postadress</Form.Label>
              <Form.Control
                defaultValue={formData.epost}
                placeholder="Fyll i e-post"
                onChange={handleChange}
                autoComplete="off"
              />
            </Form.Group>
          </Form.Row>
          <Form.Group className="text-right">
            <Button
              variant="primary"
              onClick={() => {
                validate();
                setShowSaveModal(true);
              }}
            >
              Skapa kontakt
            </Button>

            <Modal
              show={showSaveModal}
              onHide={() => {
                setShowSaveModal(false);
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Skapa ny kontakt: {formatName(formData, 'namnlös')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {validation.valid && <>Vill du spara?</>}
                {!validation.valid && <>{validation.msg}</>}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="link"
                  onClick={() => {
                    setShowSaveModal(false);
                  }}
                >
                  Stäng
                </Button>
                {validation.valid && (
                  <Button
                    variant="success"
                    onClick={() => {
                      setShowSaveModal(false);
                      handleSubmit();
                    }}
                  >
                    Spara
                  </Button>
                )}
              </Modal.Footer>
            </Modal>
          </Form.Group>
        </Form>
      </RollKoll>
    </div>
  );
};

export default SkapaKontakt;
