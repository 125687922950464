import { Reducer } from 'react';
import { FieldType, IOrder, IOrderRow, ThemeVariants } from 'types';
import { IHusarbeteData } from './husarbete-form';
import util from 'utils/util';
import { getPersonalById } from 'components/common/personal.component';
import moment from 'moment';

export type OrderChanges = {
  [key in keyof IOrder]?: any;
};

export type HusarbeteChanges = {
  [key in keyof IHusarbeteData]?: any;
};

export type RowDataChange = {
  [key in keyof IOrderRow]?: any;
};

export type RowChange = {
  type: 'added' | 'modified' | 'deleted';
  id?: number;
  index: number;
  fields: RowDataChange;
  data: IOrderRow;
};

export interface IOrderbekraftelseState {
  projekt: any;
  list: IOrder[];
  raderChanges: RowChange[];
  originalOrderrader: IOrderRow[];
  orderrader: IOrderRow[];
  rotavdrag: number;
  status: number;
  originalOrder: null | IOrder;
  orderChanges: OrderChanges;
  formData: IOrder;
  formData3: IHusarbeteData;
  originalHusarbete: null | IHusarbeteData;
  husarbeteChanges: HusarbeteChanges;
  showSaveModal: boolean;
  showSendModal: boolean;
  showForm: boolean;
  showChanges: boolean;
  alert: { show: boolean; msg: string; type: ThemeVariants };
}

export function initState({ projekt }): IOrderbekraftelseState {
  return {
    projekt,
    list: [],
    raderChanges: [],
    originalOrderrader: [],
    orderrader: [],
    orderChanges: {},
    rotavdrag: 0,
    status: 0,
    originalOrder: null,
    formData: {
      id: 0,
      bolag: projekt.bolag ? projekt.bolag : 1,
      orderdatum: new Date(),
      leveransdatum: new Date(),
      varReferens: projekt.ansvarig ? projekt.ansvarig : '',
      varReferensStr: util.getPersonalName(projekt.ansvarigPersonal),
      orderbeskrivning: '',
      efaktura: false,
      fastighet: projekt.fastighetsbeteckning,
      betalningsvillkor: '10',
      ordernummer: 0,
      orgnummer: '',
      skickatdatum: '',
      status: 0,
    },
    originalHusarbete: null,
    husarbeteChanges: {},
    formData3: {
      husarbeteBelopp: 0,
      rotavdrag: 0,
      materialBelopp: 0,
      ovrigtBelopp: 0,
      antalTimmarHusarbete: 0,
      fordelning:
        projekt.fakturaPersonnummer1.length > 0
          ? projekt.fakturaPersonnummer2.length > 0
            ? 50
            : 100
          : 100,
      personnummer1: projekt.fakturaPersonnummer1
        ? projekt.fakturaPersonnummer1
        : '',
      personnummer2: projekt.fakturaPersonnummer2
        ? projekt.fakturaPersonnummer2
        : '',
    },
    alert: { show: false, msg: '', type: 'info' },
    showChanges: false,
    showSaveModal: false,
    showSendModal: false,
    showForm: false,
  };
}

type LoadAction = {
  type: 'LOAD_ORDERBEKRAFTELSER';
  payload: IOrder[];
};

type StatusAction = {
  type: 'SET_STATUS';
  payload: number;
};

type RotavdragAction = {
  type: 'SET_ROTAVDRAG';
  payload: number;
};

type ClearAction = {
  type: 'CLEAR';
};

type CalculateHusarbeteAction = {
  type: 'CALCULATE_HUSARBETE';
  payload: IOrderRow[];
};

type SelectOrderbekraftelseAction = {
  type: 'SELECT_ORDERBEKRAFTELSE';
  payload: {
    order: IOrder;
    personalList: any;
  };
};

type AlertAction = {
  type: 'ALERT';
  payload: {
    msg: string;
    type: ThemeVariants;
    show: boolean;
  };
};

type RowChangeAction = {
  type: 'ROW_CHANGE';
  payload: IOrderRow[];
};

type HusarbeteChangeAction = {
  type: 'HUSARBETE_CHANGE';
  payload: {
    field: keyof IHusarbeteData;
    value: any;
    type?: FieldType;
  };
};

type OrderChangeAction = {
  type: 'ORDER_CHANGE';
  payload: {
    field: keyof IOrder;
    value: any;
    type?: FieldType;
  };
};

type ShowFormAction = {
  type: 'SHOW_FORM';
  payload: boolean;
};

type ShowSaveModalAction = {
  type: 'SHOW_SAVE_MODAL';
  payload: boolean;
};

type ShowSendModalAction = {
  type: 'SHOW_SEND_MODAL';
  payload: boolean;
};

type ShowChangesAction = {
  type: 'SHOW_CHANGES';
  payload: boolean;
};

type OrderAction =
  | LoadAction
  | StatusAction
  | RotavdragAction
  | ClearAction
  | CalculateHusarbeteAction
  | SelectOrderbekraftelseAction
  | AlertAction
  | HusarbeteChangeAction
  | OrderChangeAction
  | RowChangeAction
  | ShowSaveModalAction
  | ShowFormAction
  | ShowSendModalAction
  | ShowChangesAction;

const reducer: Reducer<IOrderbekraftelseState, OrderAction> = (
  prevState,
  action
): IOrderbekraftelseState => {
  switch (action.type) {
    case 'LOAD_ORDERBEKRAFTELSER': {
      return {
        ...prevState,
        list: action.payload,
      };
    }
    case 'SET_STATUS': {
      return {
        ...prevState,
        status: action.payload,
      };
    }
    case 'CLEAR': {
      const initialState = initState({ projekt: prevState.projekt });
      return {
        ...prevState,
        showForm: false,
        status: 0,
        orderrader: [],
        formData: initialState.formData,
        formData3: initialState.formData3,
        orderChanges: {},
        husarbeteChanges: {},
        raderChanges: [],
        showChanges: false,
      };
    }
    case 'CALCULATE_HUSARBETE': {
      let husarbeteTotal = 0;

      for (let row of action.payload) {
        if (row.husarbete === true) {
          husarbeteTotal += Math.round(row.antal * row.pris * 1.25);
        }
      }
      return {
        ...prevState,
        formData3: {
          ...prevState.formData3,
          husarbeteBelopp: husarbeteTotal,
          rotavdrag: Math.round(husarbeteTotal * 0.3),
        },
        rotavdrag: Math.round(husarbeteTotal * 0.3),
      };
    }
    case 'SELECT_ORDERBEKRAFTELSE': {
      const { order, personalList } = action.payload;

      let formData3: IHusarbeteData = {
        husarbeteBelopp: order.husarbeteBelopp || 0,
        rotavdrag: order.rotavdrag || 0,
        materialBelopp: order.materialBelopp || 0,
        ovrigtBelopp: order.ovrigtBelopp || 0,
        fordelning: order.fordelning || 0,
        antalTimmarHusarbete: order.antalTimmarHusarbete || 0,
        personnummer1: prevState.projekt.fakturaPersonnummer1
          ? prevState.projekt.fakturaPersonnummer1
          : '',
        personnummer2: prevState.projekt.fakturaPersonnummer2
          ? prevState.projekt.fakturaPersonnummer2
          : '',
      };

      const loadedOrder: IOrder = {
        bolag: order.bolag,
        skickatdatum: order.skickatdatum
          ? order.skickatdatum.split('T')[0]
          : '',
        orderdatum: moment(order.orderdatum).toDate(),
        leveransdatum: moment(order.leveransdatum).toDate(),
        varReferens: order.varReferens,
        varReferensStr: util.getPersonalName(
          getPersonalById(order.varReferens, personalList)
        ),
        orderbeskrivning: order.orderbeskrivning,
        efaktura: order.efaktura,
        fastighet: prevState.projekt.fastighetsbeteckning,
        betalningsvillkor: order.betalningsvillkor,
        orgnummer: order.orgnummer,
        ordernummer: order.ordernummer,
        id: order.id,
        status: order.status,
      };

      return {
        ...prevState,
        formData3,
        originalHusarbete: formData3,
        status: order.status,
        orderrader: order.orderrader || [],
        originalOrderrader: order.orderrader || [],
        formData: loadedOrder,
        originalOrder: loadedOrder,
        showForm: true,
      };
    }
    case 'ALERT': {
      return {
        ...prevState,
        alert: action.payload,
      };
    }
    case 'ROW_CHANGE': {
      const changes: RowChange[] = [];
      const numberValues: Array<keyof IOrderRow> = ['antal', 'pris'];

      prevState.originalOrderrader.forEach((row, index) => {
        const matchingRowIndex = action.payload.findIndex(
          (r) => r.id === row.id
        );
        if (matchingRowIndex === -1) {
          changes.push({
            type: 'deleted',
            id: row.id,
            index,
            fields: {},
            data: row,
          });
          return;
        }
        const matchingRow = action.payload[matchingRowIndex];

        const changedKeys = Object.keys(matchingRow).filter(
          (key) =>
            !compareValues(
              matchingRow[key],
              row[key],
              numberValues.includes(key as keyof IOrderRow)
                ? 'number'
                : undefined
            )
        ) as Array<keyof IOrderRow>;

        if (changedKeys.length > 0) {
          changes.push({
            type: 'modified',
            id: row.id,
            index: matchingRowIndex,
            data: matchingRow,
            fields: changedKeys.reduce((prev, curr) => {
              prev[curr as string] = matchingRow[curr];
              return prev;
            }, {} as IOrderRow),
          });
        }
      });

      action.payload.forEach((row, index) => {
        if (row?.id === -1) {
          changes.push({
            type: 'added',
            index,
            id: -1,
            data: row,
            fields: {},
          });
        }
      });

      return {
        ...prevState,
        orderrader: action.payload,
        raderChanges: changes,
      };
    }
    case 'ORDER_CHANGE': {
      const { field, value, type } = action.payload;
      const orderChanges = { ...prevState.orderChanges };

      if (!compareValues(prevState.originalOrder?.[field], value, type)) {
        orderChanges[field] = value;
      } else {
        delete orderChanges[field];
      }

      return {
        ...prevState,
        formData: {
          ...prevState.formData,
          [field]: value,
        },
        orderChanges,
      };
    }
    case 'HUSARBETE_CHANGE': {
      const { field, value, type } = action.payload;
      const husarbeteChanges = { ...prevState.husarbeteChanges };

      if (!compareValues(prevState.originalHusarbete?.[field], value, type)) {
        husarbeteChanges[field] = value;
      } else {
        delete husarbeteChanges[field];
      }

      return {
        ...prevState,
        formData3: {
          ...prevState.formData3,
          [field]: value,
        },
        husarbeteChanges,
      };
    }
    case 'SHOW_FORM': {
      return {
        ...prevState,
        showForm: action.payload,
      };
    }
    case 'SHOW_SAVE_MODAL': {
      return {
        ...prevState,
        showSaveModal: action.payload,
      };
    }
    case 'SHOW_SEND_MODAL': {
      return {
        ...prevState,
        showSendModal: action.payload,
      };
    }
    case 'SET_ROTAVDRAG': {
      return {
        ...prevState,
        rotavdrag: action.payload,
      };
    }
    case 'SHOW_CHANGES': {
      return {
        ...prevState,
        showChanges: action.payload,
      };
    }
  }
  return prevState;
};

function compareValues(valA: any, valB: any, type?: FieldType) {
  switch (type) {
    case 'date': {
      return new Date(valA).getTime() === new Date(valB).getTime();
    }
    case 'number':
    case 'select': {
      return String(valA) === String(valB);
    }
    default: {
      return valA === valB;
    }
  }
}

export default reducer;
