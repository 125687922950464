import { ReactNode, useContext } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import util, { distanceInCalendarDays } from 'utils/util';
import moment, { Moment, MomentInput } from 'moment-timezone';
import 'moment/locale/sv';
import styled from 'styled-components';
import { hasPassed, isPartOfWeek, isRangeEvent } from './utils';
import { ITidsbokning } from 'types/tidsbokning';
import { CalendarContext } from './calendar-context';
import { OverlayChildren } from 'react-bootstrap/esm/Overlay';
import { formatName } from 'utils/personal';
import { extraWidth } from 'utils/tidsbokning';

const RangeTooltip = styled(Tooltip)`
  .tooltip-inner {
    text-align: left;
    max-width: 380px;

    .time {
      font-size: 12px;
    }
  }
`;

export interface IRadeaEventProps {
  data: ITidsbokning;
  rangeEvents?: ITidsbokning[];
  newEvent?: any;
  hoverEvent?: any;
  validateSlot?: any;
  currentDate?: Moment;
  week?: MomentInput[];
  allConflicts: { [key: string]: ITidsbokning[] };
}

export function RadeaEvent({
  data,
  newEvent, //newEvent är ett inmatat men ej sparat event (visas i annan färg),
  hoverEvent, //hoverEvent är event som visar var i kalendern det kommer skapas newEvent när man klickar.
  validateSlot,
  rangeEvents,
  currentDate,
  week,
  allConflicts,
}: IRadeaEventProps) {
  const { personal } = useContext(CalendarContext);
  let starttid = moment(data.starttid);

  let passed = hasPassed(
    data,
    (week?.length || 5) > 1 ? undefined : currentDate
  );
  let startSlot =
    (starttid.get('hours') - 7) * 2 + starttid.get('minutes') / 30;
  let size = data.langd / 30;
  let valid = true;

  const rangeEvent = isRangeEvent(data);

  if (newEvent) {
    valid = !passed && validateSlot(starttid, data.langd, rangeEvent);
  } else {
    let eventEndSlot = size + startSlot;
    size = eventEndSlot > 23 ? size - (eventEndSlot - 23) : size;
  }
  const rangeEventsOffset = rangeEvent ? 0 : (rangeEvents?.length || 0) * 18;

  let top = rangeEventsOffset + 25 + startSlot * 18;
  let height = Math.max(18, size * 18);
  let width = 100;
  let left = 0;

  const conflicts = allConflicts[data.id || 'undefined'] || [];

  const conflictIndex = conflicts.findIndex((c) => c.id === data.id);

  const ew = extraWidth(data, conflicts, allConflicts);

  let rangeBars: ReactNode = null;

  if (rangeEvent) {
    const index = rangeEvents?.findIndex((e) => e.id === data.id) ?? -1;
    top = 25 + (index === -1 ? (rangeEvents?.length || 0) * 18 : index * 18);
    height = 18;

    const adjustedStartDate = moment.max(
      moment(data.starttid).startOf('day'),
      moment(currentDate).startOf('isoWeek')
    );
    const adjustedEndDate = moment.min(
      moment(data.sluttid).startOf('day'),
      moment(currentDate).startOf('isoWeek').add(4, 'days')
    );

    const days = distanceInCalendarDays(adjustedEndDate, adjustedStartDate);
    width = 100 + Math.min(100 * days, ((week?.length || 1) - 1) * 100);
    const passedDays = distanceInCalendarDays(moment(), adjustedStartDate);

    const inactive = ((1 + passedDays) / (1 + days)) * 100;

    rangeBars = (
      <>
        <div
          className="inactive-bar"
          style={{ width: `calc(1px + ${inactive}%)` }}
        />
        <div
          className="active-bar"
          style={{
            width: `calc(${100 - inactive}%)`,
            left: `calc(1px + ${inactive}%)`,
          }}
        />
      </>
    );
  } else if (conflicts.length > 1) {
    width = 100 / conflicts.length;
    left = width * conflictIndex - ew;
    width += ew;
  }

  const startTimeIsCurrentWeek = isPartOfWeek(currentDate, starttid, week);
  const endTimeIsCurrentWeek = data.sluttid
    ? isPartOfWeek(currentDate, data.sluttid, week)
    : true;

  const isOtherPersonalEvent =
    data.personal?.[0]?.bokadPersonal?.id &&
    data.personal?.[0]?.bokadPersonal?.id !== personal?.id;

  const eventClasses: string[] = ['event'];

  if (passed) {
    eventClasses.push('passed');
  }

  if (newEvent) {
    eventClasses.push(valid ? 'new-event' : 'not-valid');
  }

  if (data.langd === 30) {
    eventClasses.push('small');
  }

  if (rangeEvent) {
    eventClasses.push('range-event');
  }

  if (data.general) {
    eventClasses.push('general-event');
  }

  if (isOtherPersonalEvent) {
    eventClasses.push('other-personal');
  }

  let content = (
    <span
      className={eventClasses.join(' ')}
      style={{ top: top, height: height, width: `${width}%`, left: `${left}%` }}
    >
      <div className={`start ${startTimeIsCurrentWeek ? '' : ' caret'}`}>
        <span className="time">
          {getTimeFormat(starttid, startTimeIsCurrentWeek)}
          {data.ort && data.typ > 0 && ` ${data.ort}`}
        </span>
        {isOtherPersonalEvent ? (
          <span className="person">
            {formatName(data.personal?.[0]?.bokadPersonal)}
          </span>
        ) : null}
        <span className="data">
          {!data.info || data.info === ''
            ? util.atgardstypToText(data.typ)
            : util.shortenText(data.info, 18)}{' '}
          {data.heldag
            ? '(heldag)'
            : rangeEvent
            ? ''
            : util.getHoursMinutes(data.langd)}
        </span>
      </div>
      <div className={`end ${endTimeIsCurrentWeek ? '' : ' caret'}`}>
        {rangeEvent && (
          <span className="endtime">
            {getTimeFormat(data.sluttid, endTimeIsCurrentWeek)}
          </span>
        )}
      </div>
    </span>
  );

  if (hoverEvent) {
    content = (
      <span
        className={`event hover-event ${passed ? 'not-valid' : ''} small ${
          rangeEvent ? 'range-event' : ''
        }`}
        style={{
          top: top,
          height: height,
          width: `min(${width}%)`,
          left: `${left}%`,
        }}
      >
        {rangeBars}
        <span className="time">{moment(data.starttid).format('HH:mm')}:</span>
        <span>Ny</span>
      </span>
    );
  }

  const isDifferentDays = !starttid.isSame(data.sluttid, 'day');
  let tooltip: OverlayChildren | null = null;

  if (!newEvent) {
    tooltip = (
      <RangeTooltip id="range-event-tooltip">
        <div>
          <strong>
            {!data.info || data.info === ''
              ? util.atgardstypToText(data.typ)
              : data.info}
          </strong>
        </div>
        {isOtherPersonalEvent ? (
          <div>{formatName(data.personal?.[0]?.bokadPersonal)}</div>
        ) : null}
        <div>{data.ort && data.typ > 0 && ` ${data.ort}`}</div>
        <div className="time">
          <em>
            {isDifferentDays && 'Från '}
            {getTimeFormat(starttid, false)}
          </em>
        </div>
        {isDifferentDays && (
          <div className="time">
            <em>Till {getTimeFormat(data.sluttid, false)}</em>
          </div>
        )}
      </RangeTooltip>
    );
  }

  if (tooltip) {
    return (
      <OverlayTrigger
        placement="top"
        overlay={tooltip}
        trigger={rangeEvent ? ['hover', 'focus'] : 'click'}
        rootClose
      >
        {content}
      </OverlayTrigger>
    );
  }

  return content;

  function getTimeFormat(input: MomentInput, isCurrentWeek: boolean) {
    if (!input) {
      return '';
    }
    const date = moment(input);
    let format = 'HH:mm';

    if (isCurrentWeek && data.heldag) {
      return '';
    }

    if (isCurrentWeek && !data.heldag) {
      format = 'HH:mm';
    }

    if (data.heldag && !isCurrentWeek) {
      format = 'dddd LL';
    }

    if (!data.heldag && !isCurrentWeek) {
      format = 'dddd LL HH:mm';
    }

    return date.format(format);
  }
}

export default RadeaEvent;
