import { useCallback, useRef } from 'react';
import { confirm } from 'utils/ui';
import { UnregisterCallback } from 'history';
import { useHistory } from 'react-router-dom';

export function useNavigationBlock(
  message: string,
  onNavigationCancel?: () => unknown
) {
  const history = useHistory();
  const unregisterFunction = useRef<UnregisterCallback>(() => null);

  const blockNavigation = useCallback(() => {
    if (unregisterFunction.current) {
      unregisterFunction.current();
    }
    let unblock = history.block(({ pathname }) => {
      confirm(message).then((result) => {
        if (result) {
          unblock();
          history.push(pathname);
          return;
        }
        if (typeof onNavigationCancel === 'function') {
          onNavigationCancel();
        }
      });

      return false;
    });

    unregisterFunction.current = unblock;
    return unblock;
  }, [message, history, onNavigationCancel]);

  return [blockNavigation, unregisterFunction.current];
}
