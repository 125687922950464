import { BACK_PARAM } from 'constants/paths';

export function resolvePath(
  path: string,
  ...substitutes: Array<number | string>
): string {
  const params = path.match(/:[^/]+/g);
  if (params === null) {
    return path;
  }

  let resolved = path;
  for (let i = 0; i < params.length; i++) {
    const param = params[i];
    const substitute = substitutes[i];
    if (!substitute) {
      continue;
    }

    resolved = resolved.replace(new RegExp(param), String(substitute));
  }

  return resolved;
}

class PathBuilder {
  private path: string = '';
  private params: URLSearchParams = new URLSearchParams();

  constructor(path: string, substitutes?: Array<number | string>) {
    this.path = resolvePath(path, ...(substitutes || []));
  }

  public toString() {
    return `${this.path}?${this.params}`;
  }

  public get() {
    return `${this.path}?${this.params}`;
  }

  public query(params: string | URLSearchParams | Record<string, string>) {
    const oldParams = this.params;
    const searchParams = new URLSearchParams(params);

    searchParams.forEach((value, key) => {
      oldParams.set(key, value);
    });

    return this;
  }

  public backUrl(url?: string) {
    if (url) {
      this.params.set(BACK_PARAM, url);
    }

    return this;
  }
}

export function buildPath(
  path: string,
  ...substitutes: Array<number | string>
) {
  return new PathBuilder(path, substitutes);
}

export function formatBackParam(url?: string, delimiter?: boolean): string {
  if (!url) {
    return '';
  }

  return `${delimiter ? '?' : ''}${BACK_PARAM}=${url}`;
}

export function getBackUrl(params: URLSearchParams) {
  if (!params.get(BACK_PARAM)) {
    return '';
  }

  return decodeURI(params.get(BACK_PARAM) || '');
}

export function fromQuery<T>(
  params: URLSearchParams,
  key: string,
  defaultValue: T extends number ? number : string,
  number?: boolean
): T extends number ? number : string {
  const value = params.get(key);

  if (!value) {
    return defaultValue;
  }

  if (!number) {
    return value as T extends number ? number : string;
  }
  const parsed = parseInt(value, 10);

  if (isNaN(parsed)) {
    return defaultValue;
  }

  return parsed as T extends number ? number : string;
}
