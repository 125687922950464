const paths = {
  ROOT: '/',
  START: '/start',
  CREATE_CONTACT: '/skapa-kontakt',
  VIEW_CONTACT: '/visa-kontakt/:id',
  ADMIN_START: '/admin-start',
  MY_PAGE: '/mina-uppgifter',
  SCHUEDULES: '/scheman',
  ORDER: '/order-faktura',
  SELF_INSPECTION: '/egenkontroll',
  LOGIN: '/login',
  LOGOUT: '/logout',
};

export const BACK_PARAM = 'from';

export const public_paths = [paths.LOGIN, paths.LOGOUT];

export default paths;
