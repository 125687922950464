import { useState, useEffect } from 'react';
import { getPersonal } from 'components/action/services';
import { IPersonal } from 'types';

const PERSONAL_LIST = 'personalList';

function getCachedPersonalList() {
  let parsed = [];
  try {
    parsed = JSON.parse(sessionStorage.getItem(PERSONAL_LIST) || '');
  } catch (error) {
    return [];
  }
  return parsed;
}

export function usePersonalList() {
  const [personalList, setPersonalList] = useState<IPersonal[]>(
    getCachedPersonalList()
  );

  useEffect(() => {
    getPersonal().then((updatedList) => {
      setPersonalList(updatedList);
      sessionStorage.setItem(PERSONAL_LIST, JSON.stringify(updatedList));
    });
  }, []);

  return personalList;
}
