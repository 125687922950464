import React, { useState, ReactNode } from 'react';
import ReactDom from 'react-dom';
import { Modal, Button } from 'react-bootstrap';
import { ThemeVariants } from 'types';
import { ConfirmationField } from 'components/common/confirmation-field';

export interface IConfirmOptions
  extends Omit<IConfirmDialogOptions, 'onDismissed' | 'message'> {}

export function confirm(
  message: ReactNode,
  options: IConfirmOptions = {}
): Promise<boolean> {
  const container = document.createElement('div');
  document.body.appendChild(container);

  return new Promise((resolve) => {
    function close(confirmed: boolean) {
      ReactDom.unmountComponentAtNode(container);
      resolve(confirmed);
      container?.parentElement?.removeChild(container);
    }

    ReactDom.render(
      <ConfirmDialog message={message} onDismissed={close} {...options} />,
      container
    );
  });
}

export interface IConfirmDialogOptions {
  message: ReactNode;
  onDismissed: (confirm: boolean) => unknown;
  confirmationCode?: string;
  confirmText?: string;
  cancelText?: string;
  title?: string;
  variant?: ThemeVariants;
  onRenderContent?: (
    defaultRenderer: (props: IConfirmDialogOptions) => React.ReactElement<any>,
    props: IConfirmDialogOptions
  ) => React.ReactElement<any>;
}

function ConfirmDialog(props: IConfirmDialogOptions) {
  const [hasConfirmedDeletion, setHasConfirmedDeletion] = useState(
    !props.confirmationCode
  );
  const { onDismissed, title, onRenderContent } = props;

  function defaultRenderer({
    message,
    onDismissed,
    cancelText,
    variant,
    confirmText,
    confirmationCode,
  }: IConfirmDialogOptions) {
    return (
      <>
        <Modal.Body>
          {message}
          {confirmationCode && (
            <ConfirmationField
              code={confirmationCode}
              onChange={(confirmed) => setHasConfirmedDeletion(confirmed)}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => {
              onDismissed(false);
            }}
          >
            {cancelText || 'Avbryt'}
          </Button>
          <Button
            disabled={!hasConfirmedDeletion}
            variant={variant || 'primary'}
            onClick={() => {
              onDismissed(true);
            }}
          >
            {confirmText || 'OK'}
          </Button>
        </Modal.Footer>
      </>
    );
  }

  return (
    <Modal
      show
      onHide={() => {
        onDismissed(false);
      }}
    >
      <Modal.Header closeButton>
        {title && <Modal.Title>{title}</Modal.Title>}
      </Modal.Header>
      {onRenderContent
        ? onRenderContent(defaultRenderer, props)
        : defaultRenderer(props)}
    </Modal>
  );
}
