export const kategori = {
  'Ospec.': 0,
  Kundkategori: 1,
  Affarsomrade: 2,
  Aktivitetstyp: 3,
};

export const kategoriTranslation = {
  0: 'Ospec.',
  1: 'Kundkategori',
  2: 'Affärsområde',
  3: 'Aktivitetstyp',
};

export default kategori;
