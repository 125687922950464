import { ReactNode, useState } from 'react';
import Projektdetaljer from './projektdetaljer.component';
import VisaBesiktning from './visa-besiktning.component';
import Tidsbokningar from './tidsbokningar.component';
import Nav from 'react-bootstrap/Nav';
import { Container } from 'react-bootstrap';
import Aktiviteter from './aktiviteter.component';
import { EgenkontrollList } from 'components/egenkontroll/list';
import Bestallning from '../kontakt/bestallning.component';
import Orderbekraftelse from './orderbekraftelse.component/index';
import SkapaTidsbokning from './skapatidsbokning.component';
import { IKontaktFull } from 'types';
import { useQueryParameter } from 'hooks/useQuery';
import useAktiviteter from './hooks/useAktiviteter';
import Badge from 'react-bootstrap/Badge';

enum ActiveView {
  projekt = 'projekt',
  matpaket = 'matpaket',
  order = 'order',
  aktiviteter = 'aktiviteter',
  besiktning = 'besiktning',
  egenkontroll = 'egenkontroll',
}

export interface IVisaProjektProps {
  refresh: () => unknown;
  cancel: () => unknown;
  flyttaTillKund?: boolean;
  kontakt?: null | IKontaktFull;
  projekt: any;
}

const VisaProjekt = ({
  projekt,
  kontakt,
  refresh,
  flyttaTillKund,
  cancel,
}: IVisaProjektProps) => {
  const [active, setActive] = useQueryParameter<ActiveView>(
    'view',
    ActiveView.projekt,
    false,
    true
  );

  const [aktiviteter] = useAktiviteter(projekt.id);

  const [refreshBookingList, setRefreshBookingList] = useState(0);
  if (!kontakt) {
    return null;
  }

  const navLinks = [
    {
      key: ActiveView.projekt,
      text: 'Projektbokningar',
    },
    {
      key: ActiveView.matpaket,
      text: 'Beställ mätpaket',
    },
    {
      key: ActiveView.order,
      text: 'Orderbekräftelse',
    },
    {
      key: ActiveView.aktiviteter,
      text: <>
        Aktiviteter {(aktiviteter || []).length !== 0 && <Badge variant="primary">{(aktiviteter || []).length}</Badge>}
      </>
    },
    {
      key: ActiveView.besiktning,
      text: 'Besiktning',
    },
    {
      key: ActiveView.egenkontroll,
      text: 'Egenkontroll',
    },
  ];

  let content: ReactNode = null;

  switch (active) {
    case ActiveView.projekt: {
      content = (
        <>
          <h5>Inbokade åtgärder</h5>
          <Tidsbokningar
            projektId={projekt.id}
            isBesiktning={false}
            refreshList={refreshBookingList}
          />
          <SkapaTidsbokning
            atgardstyper={[3, 4, 5, 6]}
            projektId={projekt.id}
            ort={projekt.ort}
            refreshList={() => {
              setRefreshBookingList(refreshBookingList + 1);
            }}
          />
        </>
      );
      break;
    }
    case ActiveView.matpaket: {
      content = (
        <>
          <h5>Beställ mätpaket</h5>
          <Bestallning kontakt={kontakt} />
        </>
      );
      break;
    }
    case ActiveView.order: {
      content = (
        <>
          <h5>Orderbekräftelse</h5>
          <Orderbekraftelse projekt={projekt} kontakt={kontakt} />
        </>
      );
      break;
    }
    case ActiveView.aktiviteter: {
      content = (
        <>
          <h5>Aktiviteter</h5>
          <Aktiviteter projektId={projekt.id} />
        </>
      );
      break;
    }
    case ActiveView.besiktning: {
      content = (
        <>
          <h5>Besiktning</h5>
          <VisaBesiktning projekt={projekt} readOnly={true} />
        </>
      );
      break;
    }
    case ActiveView.egenkontroll: {
      content = (
        <>
          <h5>Egenkontroll</h5>
          <EgenkontrollList projektId={projekt.id} />
        </>
      );
      break;
    }
  }

  return (
    <>
      <Projektdetaljer
        kontakt={kontakt}
        projekt={projekt}
        refresh={refresh}
        flyttaTillKund={flyttaTillKund}
        cancel={cancel}
      />
      {!flyttaTillKund && (
        <>
          <Nav variant="tabs" defaultActiveKey={active}>
            {navLinks.map((link) => (
              <Nav.Item key={link.key}>
                <Nav.Link
                  eventKey={link.key}
                  onClick={() => setActive(link.key)}
                >
                  {link.text}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <Container className={'tab-container'}>{content}</Container>
        </>
      )}
    </>
  );
};

export default VisaProjekt;

//<Link className="nav-link" to={"/lista-kontakter"}>Kontakter</Link>
