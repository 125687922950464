import React, { useState, useEffect } from 'react';
import { doGetList } from '../action/services';
import { Form } from 'react-bootstrap';

export const ArtiklarSelectFormGroup = props => {
  const { selectedId, label, small, registerSelected, strong, typ } = props;
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState(selectedId);

  useEffect(() => {
    const getArtiklar = async () => {
      let endpoint = `/artiklar/all`;
      if (typ && typ > 0) {
        endpoint = `/artiklar/get/${typ}`;
      }
      const res = await doGetList(endpoint);
      setList(res.data);
    }
    getArtiklar();
  }, [setList, typ]);

  useEffect(() => {
    setSelected(selectedId);
  }, [selectedId]);

  const artikelSelect = (e) => {
    setSelected(e.target.value);
    if (registerSelected) {
      registerSelected(getArtikel(e.target.value));
    }
  }

  const getArtikel = (id) => {
    for (const artikel of list) {
      if (artikel.id === parseInt(id)) {
        return artikel;
      }
    }
  }

  return (
    <>
      {label && label !== "" &&
        < Form.Label > {strong && <strong>{label}</strong>}{!strong && <>{label}</>}</Form.Label>
      }
      <Form.Control
        as="select"
        size={small ? "sm" : ""}
        value={selected}
        onChange={artikelSelect}
        custom>
        <option>Välj artikel</option>
        {list.filter((item) => { return item.aktiv; }).map((data, i) => {
          return <option key={i} value={data.id} >{data.artikelnummer} - {data.beskrivning}</option>
        })}
      </Form.Control>
    </>
  )
}
export default { ArtiklarSelectFormGroup }