import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import util from 'utils/util';
import useTidsbokningar from '../hooks/useTidsbokningar';
import useMalltexter from 'utils/hooks/useMalltexter';

const BokningsbekraftelseModal = ({
  show,
  onHide,
  projektId,
  isBesiktning,
  onSubmit,
}) => {
  const [tidsbokningar, refreshTidsbokningar] = useTidsbokningar({
    projektId,
    isBesiktning,
  });
  const [malltexter, lookupMalltext] = useMalltexter(2);
  const [tidsbokning, setTidsbokning] = useState('');
  const [bokningMessage, setBokningMessage] = useState('');
  const [message, setMessage] = useState('');
  const [template, setTemplate] = useState('');

  useEffect(() => {
    if (show) {
      refreshTidsbokningar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (!template && malltexter.length !== 0) {
      setTemplate(malltexter[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [malltexter.length]);

  useEffect(() => {
    if (tidsbokningar.length !== 0) {
      setTidsbokning(tidsbokningar[0].id);
      setBokningMessage(getBokningsText(tidsbokningar[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tidsbokningar]);

  useEffect(() => {
    if (template) {
      setMessage(lookupMalltext(template).text);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  useEffect(() => {
    if (tidsbokning) {
      const bokning = tidsbokningar.find((b) => b.id === tidsbokning);
      if (bokning) {
        setBokningMessage(getBokningsText(bokning));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tidsbokning]);

  const getBokningsText = (bokning) => {
    return `Bekräftar att ${bokning.personal[0].bokadPersonal.fornamn} ${
      bokning.personal[0].bokadPersonal.efternamn
    } utför besiktning hos dig ${util.formatDate(bokning.starttid, true)}.`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const sendBokningsbekraftelse = async () => {
    onSubmit({ data: `${bokningMessage}\n\n${message}`, projektId: projektId });
    onHide();
  };

  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Skicka bokningsbekräftelse via e-post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Tidsbokning</Form.Label>
            <Form.Control
              as="select"
              size="sm"
              value={tidsbokning}
              onChange={(e) => {
                setTidsbokning(parseInt(e.target.value, 10));
              }}
              custom
            >
              {tidsbokningar.map((data, i) => {
                const personal = (data.personal[0] || {}).bokadPersonal || {};
                const text = `${util.atgardstypToText(data.typ)}, ${
                  personal.fornamn
                } ${personal.efternamn}, ${util.formatDate(
                  data.starttid,
                  true
                )}`;
                return (
                  <option key={data.id} value={data.id}>
                    {text}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Bokningstext</Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              placeholder=""
              value={bokningMessage}
              onChange={(e) => setBokningMessage(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Mall</Form.Label>
            <Form.Control
              as="select"
              size="sm"
              value={template}
              onChange={(e) => {
                setTemplate(e.target.value);
              }}
              custom
            >
              {malltexter.map((data, i) => {
                return (
                  <option key={data.id} value={data.id}>
                    {data.namn}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Meddelande</Form.Label>
            <Form.Control
              as="textarea"
              rows="8"
              placeholder=""
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" onClick={onHide}>
            Avbryt
          </Button>
          <Button variant="primary" onClick={() => sendBokningsbekraftelse()}>
            Skicka
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BokningsbekraftelseModal;
