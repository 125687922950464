import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import useMalltexter from 'utils/hooks/useMalltexter';

const OrderbekraftelseModal = ({
  show,
  onHide,
  ordernr,
  onSubmit,
  validEmail,
}) => {
  const [malltexter, lookupMalltext] = useMalltexter(2);
  const [message, setMessage] = useState('');
  const [template, setTemplate] = useState('');

  useEffect(() => {
    if (!template && malltexter.length !== 0) {
      setTemplate(malltexter[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [malltexter.length]);

  useEffect(() => {
    if (template) {
      setMessage(lookupMalltext(template).text);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  const sendOrderbekraftelse = async () => {
    onSubmit({ message: message, ordernr: ordernr });
    onHide();
  };

  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Skicka orderbekräftelse via e-post.</Modal.Title>
        </Modal.Header>
        {validEmail && (
          <Modal.Body>
            <p>Orderbekräftelse PDF bifogas automatiskt i mailet.</p>
            <Form.Group>
              <Form.Label>Mall</Form.Label>
              <Form.Control
                as="select"
                size="sm"
                value={template}
                onChange={(e) => {
                  setTemplate(e.target.value);
                }}
                custom
              >
                {malltexter.map((data, i) => {
                  return (
                    <option key={data.id} value={data.id}>
                      {data.namn}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Meddelande</Form.Label>
              <Form.Control
                as="textarea"
                rows="8"
                placeholder=""
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
        )}
        {!validEmail && (
          <Modal.Body>
            <p>Fel: Kundens e-post saknas i systemet.</p>
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button variant="link" onClick={onHide}>
            Avbryt
          </Button>
          {validEmail && (
            <Button variant="primary" onClick={() => sendOrderbekraftelse()}>
              Skicka
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OrderbekraftelseModal;
