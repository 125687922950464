import React, { useState, useEffect } from 'react';
import { Table, Form, Col } from 'react-bootstrap';
import { doPost, doGetList } from '../action/services';
import { RadeaAlert } from '../common/radea-alert.component';
import { PlusCircleFill } from 'react-bootstrap-icons';
import { kategoriTranslation } from 'constants/kategori';

const Kategori = () => {
  const [formData, setFormData] = useState({
    namn: '',
    kategori: 0,
    id: '',
    aktiv: true,
  });

  //const [deleteItem, setDeleteItem] = useState({ id: "" });

  const [namn, setNamn] = useState('');

  const [list, setList] = useState([]);
  //const [showSaveModal, setShowSaveModal] = useState(false);
  const [alert, setAlert] = useState({ show: false, msg: '', type: '' });

  const validateFormData = () => {
    if (formData.namn === '') {
      showAlert('Du måste ge kategorin en benämning.', 'danger');
      return false;
    }
    return true;
  };

  const handleChange = (e) => {
    formData[e.target.id] = e.target.value.trim();
    setFormData(formData);
    if (e.target.id === 'namn') {
      setNamn(e.target.value);
    }
  };

  const toggleAktiv = async (data) => {
    data.aktiv = data.aktiv ? false : true;
    let result = await doPost('/kategori/update', data);
    if (result.data.success) {
      setAlert({
        show: true,
        msg: 'Kategorin uppdaterades i systemet.',
        type: 'success',
      });
      setTimeout(() => {
        setAlert({
          show: false,
          msg: '',
          type: 'success',
        });
      }, 1800);
      getKategorier();
    } else {
      setAlert({
        show: true,
        msg: 'Kategorin kunde inte uppdateras i systemet, kontakta supporten.',
        type: 'danger',
      });
      setTimeout(() => {
        setAlert({
          show: false,
          msg: '',
          type: 'success',
        });
      }, 1800);
      //getKategorier();
      //setNamn('');
    }
  };

  const getKategorier = async () => {
    const res = await doGetList(`/kategori/all`);
    setList(res.data);
  };
  useEffect(() => {
    getKategorier();
  }, [setList]);

  const showAlert = (msg, type) => {
    setAlert({ show: true, msg: msg, type: type });
    setTimeout(() => {
      setAlert({
        show: false,
        msg: '',
        type: 'success',
      });
    }, 2000);
  };

  const clearFormData = () => {
    let temp = {
      id: '',
      namn: '',
      kategori: 0,
      aktiv: true,
    };
    setFormData(temp);
  };

  const handleSubmit = async () => {
    let result = await doPost('/kategori/create', formData);
    if (!result.data.success) {
      setAlert({
        show: true,
        msg: 'Kategorin kunde inte sparas i systemet, kontakta supporten.',
        type: 'danger',
      });
      setTimeout(() => {
        setAlert({
          show: false,
          msg: '',
          type: 'success',
        });
      }, 1800);
    } else {
      clearFormData();
      getKategorier();
      setNamn('');
    }
  };

  return (
    <>
      <RadeaAlert msg={alert.msg} type={alert.type} show={alert.show} />
      <Form>
        <Form.Row>
          <Form.Group as={Col} controlId="namn">
            <Form.Label>Benämning</Form.Label>
            <Form.Control
              placeholder="Fyll i en benämning på kategorin"
              value={namn}
              onChange={handleChange}
              autoComplete="off"
            />
          </Form.Group>
          <Form.Group as={Col} controlId="kategori">
            <Form.Label>Kategori</Form.Label>
            <Form.Control
              as="select"
              placeholder="Kategori"
              onChange={handleChange}
              custom
            >
              {Object.keys(kategoriTranslation).map((k) => (
                <option value={k}>{kategoriTranslation[k]}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} controlId="aktiv">
            <Form.Label>Aktiv</Form.Label>
            <Form.Control
              as="select"
              placeholder="Aktiv"
              onChange={handleChange}
              custom
            >
              <option value="1">Ja</option>
              <option value="0">Nej</option>
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} className="d-flex align-items-center">
            <PlusCircleFill
              size={30}
              onClick={() => {
                if (validateFormData()) handleSubmit();
              }}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row></Form.Row>
      </Form>
      <Table hover striped size="sm">
        <thead>
          <tr>
            <td>Benämning</td>
            <td>Kategori</td>
            <td>Aktiv</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {list.map((data, i) => {
            return (
              <tr key={data.id}>
                <td>{data.namn}</td>
                <td>{kategoriTranslation[data.kategori]}</td>
                <td>
                  <p
                    style={{ cursor: 'pointer' }}
                    title="Ändra status på kategorin"
                    onClick={() => {
                      toggleAktiv(data);
                    }}
                  >
                    {data.aktiv ? 'Ja' : 'Nej'}
                  </p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};
export default Kategori;
