import { useState, useEffect, useCallback } from 'react';
import util, { atgardstypToText } from 'utils/util';
import { Trash } from 'react-bootstrap-icons';
import { doGetList, getAxiosInstance } from '../action/services';
import { Table } from 'react-bootstrap';
import {
  IRadeaAlertProps,
  RadeaAlert,
  WarningMessage,
} from '../common/radea-alert.component';
import { ITidsbokning } from 'types';
import { formatName } from 'utils/personal';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import { confirm } from 'utils/ui';

const axios = getAxiosInstance();

export interface ITidsbokningarProps {
  projektId: number;
  isBesiktning: boolean;
  readOnly?: boolean;
  refreshList?: number;
}

const Tidsbokningar = ({
  projektId,
  isBesiktning,
  readOnly,
  refreshList,
}: ITidsbokningarProps) => {
  const [list, setList] = useState<ITidsbokning[]>([]);
  const [alert, setAlert] = useState<IRadeaAlertProps>({
    show: false,
    msg: '',
    type: 'info',
  });
  const [ingenBesiktning, setIngenBesiktning] = useState(true);

  const getTidsbokningar = useCallback(async () => {
    const res = await doGetList(
      `/tidsbokningar/${isBesiktning ? 'besiktning' : 'projekt'}/${projektId}`
    );
    setList(res.data);
  }, [projektId, isBesiktning]);

  const deleteTidsbokning = async (deleteItem) => {
    const shouldDelete = await confirm('Är du säker?', {
      title: `Avboka ${atgardstypToText(deleteItem?.typ) || ''}`,
      confirmText: 'Avboka',
      variant: 'danger',
    });
    if (!shouldDelete) {
      return;
    }
    let result = await axios.post('/tidsbokningar/delete', {
      id: deleteItem?.id,
    });
    if (result.data.success) {
      showAlert('Bokningen är borttagen ur systemet.', 'success');
      setTimeout(() => {
        getTidsbokningar();
      }, 1800);
    } else {
      showAlert(
        'Fel, bokningen kunde inte tas bort, kontakta supporten.',
        'danger'
      );
    }
  };

  const updateTidsbokning = async (
    id: number | undefined,
    visibleToCustomer: boolean
  ) => {
    if (!id) {
      return;
    }
    const action = visibleToCustomer ? 'visa' : 'dölja';
    const confirmText = visibleToCustomer ? 'Visa' : 'Dölj';
    const shouldUpdate = await confirm(
      `Är du säker på att du vill ${action} bokningen?`,
      {
        title: `${confirmText} bokning`,
        confirmText,
      }
    );
    if (!shouldUpdate) {
      return;
    }
    let result = await axios.put(`/tidsbokningar/${id}`, {
      visibleToCustomer,
    });
    if (result.data.success) {
      showAlert(
        `Bokningen ${visibleToCustomer ? 'visas' : 'döljs'} nu för kunden`,
        'success'
      );
      getTidsbokningar();
    } else {
      showAlert(
        'Fel, bokningen kunde inte uppdateras, kontakta supporten.',
        'danger'
      );
    }
  };

  const showAlert = (msg, type) => {
    setAlert({ show: true, msg: msg, type: type });
    setTimeout(() => {
      setAlert({
        show: false,
        msg: '',
        type: 'success',
      });
    }, 2000);
  };

  useEffect(() => {
    getTidsbokningar();
  }, [getTidsbokningar, projektId]);

  useEffect(() => {
    getTidsbokningar();
  }, [refreshList, getTidsbokningar]);

  const containsBesiktning = useCallback(() => {
    return list.some((item) => {
      return item.typ === 3 || item.typ === 1;
    });
  }, [list]);

  useEffect(() => {
    setIngenBesiktning(!containsBesiktning());
  }, [list, containsBesiktning]);

  return (
    <>
      {list.length > 0 && (
        <>
          <Table hover striped size="sm">
            <thead>
              <tr>
                <td>Åtgärd</td>
                <td>Utförare</td>
                <td>Datum</td>
                <td>Synlig för kund</td>
                {!readOnly && <td></td>}
              </tr>
            </thead>
            <tbody>
              {list.map((data, i) => {
                return (
                  <tr key={`bp${i}`}>
                    <td>{atgardstypToText(data.typ)}</td>
                    <td>
                      {data?.personal?.map((pData, ii) => {
                        return (
                          <span key={`bp2${ii}`}>
                            {formatName(pData.bokadPersonal)}
                          </span>
                        );
                      })}
                    </td>
                    <td>
                      <DateRange bokning={data} />
                    </td>
                    <td>
                      {readOnly ? (
                        data?.visibleToCustomer ? (
                          'Ja'
                        ) : (
                          'Nej'
                        )
                      ) : (
                        <Form.Check
                          checked={data?.visibleToCustomer}
                          onChange={(e) => {
                            updateTidsbokning(data?.id, e.target.checked);
                          }}
                        />
                      )}
                    </td>
                    {!readOnly && (
                      <td>
                        <Trash
                          size={20}
                          className={'click-item gray'}
                          onClick={() => {
                            deleteTidsbokning(data);
                          }}
                        />
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <WarningMessage
            msg="Ingen besiktning inbokad"
            show={ingenBesiktning}
          />
          <RadeaAlert msg={alert.msg} type={alert.type} show={alert.show} />
        </>
      )}
      {list.length === 0 && (
        <span>
          <i>Inga inbokade åtgärder tillgängliga</i>
        </span>
      )}
    </>
  );
};

function DateRange({ bokning }: { bokning: ITidsbokning }) {
  const starttid = moment(bokning.starttid);
  const sluttid = bokning.sluttid
    ? moment(bokning.sluttid)
    : moment(starttid).add(bokning.langd, 'minutes');

  if (bokning.heldag) {
    return <>{starttid.format('YYYY-MM-DD')} - heldag</>;
  }

  const isSameDay = starttid.isSame(sluttid, 'day');

  return (
    <>
      {starttid.format('YYYY-MM-DD HH:mm')} -{' '}
      {sluttid.format(isSameDay ? 'HH:mm' : 'YYYY-MM-DD HH:mm')}
      {isSameDay && ` (${util.getHoursMinutes(bokning.langd)})`}
    </>
  );
}

export default Tidsbokningar;
