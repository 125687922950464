import { useState, useEffect } from 'react';
import { doGetList } from 'components/action/services';

export default function useTidsbokningar({ projektId, isBesiktning }) {
  const [tidsbokningar, setTidsbokningar] = useState([]);

  useEffect(() => {
    doGetList(
      `/tidsbokningar/${isBesiktning ? 'besiktning' : 'projekt'}/${projektId}`
    ).then((res) => {
      setTidsbokningar(res.data);
    });
  }, [projektId, isBesiktning]);

  return [tidsbokningar, () => {
    doGetList(
      `/tidsbokningar/${isBesiktning ? 'besiktning' : 'projekt'}/${projektId}`
    ).then((res) => {
      setTidsbokningar(res.data);
    });
  }];
}
