import React, { useState, useEffect, useCallback, ReactNode } from 'react';
import { Form, Col, Modal, Button } from 'react-bootstrap';
import { doPost, doGetList } from '../action/services';
import ListArtiklar from './list-artiklar.component';
import { IRadeaAlertProps, RadeaAlert } from '../common/radea-alert.component';
import { PlusCircleFill } from 'react-bootstrap-icons';
import { ArtikelSyncDialog } from './artikel-sync-dialog';
import { ThemeVariants } from 'types';

const Artikel = () => {
  const [alert, setAlert] = useState<IRadeaAlertProps>({
    show: false,
    msg: '',
    type: 'info',
  });
  const [showEditModal, setShowEditModal] = useState(false);
  const [showSyncDialog, setShowSyncDialog] = useState(false);
  const [artikelList, setArtikelList] = useState([]);

  const [formData, setFormData] = useState({
    artikelnummer: '',
    konto: '',
    id: '',
    namn: '',
    beskrivning: '',
    pris: 0,
    moms: 0,
    typ: 1,
    aktiv: true,
  });

  const getArtikelList = useCallback(async () => {
    const res = await doGetList(`/artiklar/all`);
    setArtikelList(res.data);
  }, [setArtikelList]);

  useEffect(() => {
    getArtikelList();
  }, [getArtikelList]);

  const handleChange = (e) => {
    let key = e.target.id;
    let value = e.target.value.trim();
    setFormData({
      id: formData.id,
      artikelnummer: key === 'artikelnummer' ? value : formData.artikelnummer,
      konto: key === 'konto' ? value : formData.konto,
      namn: key === 'namn' ? value : formData.namn,
      beskrivning: key === 'beskrivning' ? value : formData.beskrivning,
      pris: key === 'pris' ? value : formData.pris,
      moms: key === 'moms' ? value : formData.moms,
      typ: key === 'typ' ? value : formData.typ,
      aktiv: key === 'aktiv' ? value : formData.aktiv,
    });
  };

  const validateFormData = () => {
    return true;
  };

  const updateArtikel = async (data) => {
    let result = await doPost('/artiklar/update', data);
    const failedFortnoxUpdates = result.data.fortnoxUpdates.filter(
      (update) => !update.success
    );
    if (result.data.success) {
      showAlert(
        <>
          <span>Artikeln uppdaterades i systemet.</span>
          <FortnoxErrors errors={failedFortnoxUpdates} />
        </>,
        failedFortnoxUpdates.length > 0 ? 'warning' : 'success',
        failedFortnoxUpdates.length > 0,
        () => {
          setAlert(() => ({ ...alert, show: false }));
          getArtikelList();
        }
      );
    } else {
      showAlert(
        'Artikeln kunde inte uppdateras i systemet, kontakta supporten.',
        'danger'
      );
    }
  };

  const toggleAktiv = async (data) => {
    data.aktiv = data.aktiv ? false : true;
    let result = await doPost('/artiklar/update', data);
    const failedFortnoxUpdates = result.data.fortnoxUpdates.filter(
      (update) => !update.success
    );
    if (result.data.success) {
      showAlert(
        <>
          <span>Artikeln uppdaterades i systemet</span>
          <FortnoxErrors errors={failedFortnoxUpdates} />
        </>,
        failedFortnoxUpdates.length > 0 ? 'warning' : 'success',
        failedFortnoxUpdates.length > 0,
        () => {
          getArtikelList();
          setAlert(() => ({ ...alert, show: false }));
        }
      );
    } else {
      showAlert(
        'Artikeln kunde inte uppdateras i systemet, kontakta supporten.',
        'danger'
      );
    }
  };

  const handleSubmit = async () => {
    let result = await doPost('/artiklar/create', formData);
    const failedFortnoxUpdates = result.data.fortnoxUpdates.filter(
      (update) => !update.success
    );
    if (!result.data.success) {
      showAlert(
        'Artikeln kunde inte sparas i systemet, kontakta supporten.',
        'danger'
      );
    } else {
      showAlert(
        <>
          <span>Artikeln har sparats i systemet.</span>
          <FortnoxErrors errors={failedFortnoxUpdates} />
        </>,
        failedFortnoxUpdates.length > 0 ? 'warning' : 'success',
        failedFortnoxUpdates.length > 0,
        () => {
          setAlert(() => ({ ...alert, show: false }));
          clearFormData();
          getArtikelList();
        }
      );
    }
  };

  const clearFormData = () => {
    let temp = {
      artikelnummer: '',
      konto: '',
      namn: '',
      id: '',
      beskrivning: '',
      pris: 0,
      moms: 0,
      typ: 1,
      aktiv: true,
    };
    setFormData(temp);
  };

  const showAlert = (
    msg: ReactNode,
    type: ThemeVariants,
    skipAutoClose?: boolean,
    callback?: () => unknown
  ) => {
    setAlert({
      show: true,
      msg: msg,
      type: type,
      onDismiss: skipAutoClose ? callback : undefined,
    });

    if (skipAutoClose) {
      return;
    }
    setTimeout(() => {
      if (callback) {
        callback();
      }
      setAlert({
        show: false,
        msg: '',
        type: 'success',
      });
    }, 2000);
  };

  return (
    <>
      <RadeaAlert
        msg={alert.msg}
        type={alert.type}
        show={alert.show}
        fixedWidth={false}
        onDismiss={alert.onDismiss}
        showCloseButton={!!alert.onDismiss}
      />
      <Form>
        <Form.Row>
          <Form.Group as={Col} controlId="artikelnummer">
            <Form.Label>Artikelnummer</Form.Label>
            <Form.Control
              placeholder="Fyll i ett artikelnummer"
              onChange={handleChange}
              autoComplete="off"
            />
          </Form.Group>
          <Form.Group as={Col} controlId="pris">
            <Form.Label>Standardpris</Form.Label>
            <Form.Control
              placeholder="Standardpris"
              onChange={handleChange}
              autoComplete="off"
            />
          </Form.Group>
          <Col xs={6}>
            <Form.Group controlId="beskrivning">
              <Form.Label>Artikelbeskrivning</Form.Label>
              <Form.Control
                placeholder="Artikelbeskrivning"
                onChange={handleChange}
                autoComplete="off"
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="konto">
            <Form.Label>Konto</Form.Label>
            <Form.Control
              placeholder="Ange konto"
              onChange={handleChange}
              autoComplete="off"
            />
          </Form.Group>
          <Form.Group as={Col} controlId="moms">
            <Form.Label>Momssats</Form.Label>
            <Form.Control
              as="select"
              placeholder="Momssats"
              onChange={handleChange}
              custom
            >
              <option value="0">0</option>
              <option value="25">25</option>
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} controlId="aktiv">
            <Form.Label>Aktiv (inaktiv artikel visas ej i listor)</Form.Label>
            <Form.Control
              as="select"
              placeholder="Aktiv"
              onChange={handleChange}
              custom
            >
              <option value="true">Ja</option>
              <option value="false">Nej</option>
            </Form.Control>
          </Form.Group>
          <Form.Group
            as={Col}
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              padding: '3px 10px',
            }}
          >
            <PlusCircleFill
              size={40}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (validateFormData()) handleSubmit();
              }}
            />
          </Form.Group>
        </Form.Row>
      </Form>
      <Button
        onClick={() => {
          setShowSyncDialog(true);
        }}
      >
        Full synkronisering
      </Button>

      <ListArtiklar
        list={artikelList}
        toggle={toggleAktiv}
        showEdit={setShowEditModal}
        formData={setFormData}
      />

      <ArtikelSyncDialog
        show={showSyncDialog}
        onHide={() => setShowSyncDialog(false)}
      />
      <Modal
        show={showEditModal}
        onHide={() => {
          setShowEditModal(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Redigera artikel </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Row>
              <Form.Group as={Col} controlId="artikelnummer">
                <Form.Label>Artikelnummer</Form.Label>
                <Form.Control
                  defaultValue={formData.artikelnummer}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </Form.Group>
              <Form.Group as={Col} controlId="pris">
                <Form.Label>Standardpris</Form.Label>
                <Form.Control
                  defaultValue={formData.pris}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </Form.Group>
              <Col xs={6}>
                <Form.Group controlId="beskrivning">
                  <Form.Label>Artikelbeskrivning</Form.Label>
                  <Form.Control
                    defaultValue={formData.beskrivning}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="konto">
                <Form.Label>Konto</Form.Label>
                <Form.Control
                  defaultValue={formData.konto}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </Form.Group>
              <Form.Group as={Col} controlId="moms">
                <Form.Label>Momssats</Form.Label>
                <Form.Control
                  as="select"
                  defaultValue={formData.moms}
                  onChange={handleChange}
                  custom
                >
                  <option value="0">0</option>
                  <option value="25">25</option>
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} controlId="aktiv">
                <Form.Label>Aktiv</Form.Label>
                <Form.Control
                  as="select"
                  defaultValue={String(formData.aktiv)}
                  onChange={handleChange}
                  custom
                >
                  <option value="true">Ja</option>
                  <option value="false">Nej</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            onClick={() => {
              getArtikelList();
              setShowEditModal(false);
            }}
          >
            Avbryt
          </Button>
          <Button
            variant="success"
            onClick={() => {
              setShowEditModal(false);
              updateArtikel(formData);
            }}
          >
            Spara
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

interface FortnoxError {
  success: false;
  error: string;
  bolag: string;
}

interface IFortnoxErrorsProps {
  errors: FortnoxError[];
}

function FortnoxErrors({ errors }: IFortnoxErrorsProps) {
  if (!errors || errors.length === 0) {
    return null;
  }

  return (
    <div style={{ fontSize: '16px' }}>
      <p>Artikeln kunde inte uppdateras i Fortnox för några bolag:</p>
      <ul>
        {errors.map((e) => (
          <li>
            {e.bolag} - {e.error}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Artikel;
