import { createContext } from 'react';

import { IPersonal, IRoll } from 'types';

export interface ISessionData {
  authenticated: boolean;
  id: number;
  login: string;
  namn: string;
  roller: IRoll[];
}

export interface IGlobalContext {
  loggedIn: boolean;
  session: ISessionData;
  initialized: boolean;
  personalList: IPersonal[];
  setLoggedIn?: (session: any) => unknown;
  setLoggedOut?: () => unknown;
}

export const GlobalStateContext = createContext<IGlobalContext>({
  loggedIn: false,
  session: {
    authenticated: false,
    id: 0,
    login: '',
    namn: '',
    roller: [],
  },
  initialized: false,
  personalList: [],
});
