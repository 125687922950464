import { useState, useEffect } from 'react';
import { doGetList } from '../../components/action/services';

const cache = {};

export default function useMalltexter(typ) {
  const [malltexter, setMalltexter] = useState(cache);

  useEffect(() => {
    if (cache[typ]) {
      return;
    }
    doGetList(`/malltexter/get/${typ}`).then((response) => {
      cache[typ] = response.data;
      setMalltexter((kategorier) => ({
        ...kategorier,
        [typ]: response.data,
      }));
    });
  }, [typ]);

  const malltextValues = malltexter[typ] || [];

  const lookup = (identifier, reverse = false) => {
    const result = malltextValues.find((k) => {
      if (reverse) {
        return k.namn === identifier;
      }
      return parseInt(k.id, 10) === parseInt(identifier, 10);
    });
    if (!result) {
      return null;
    }

    return result;
  };

  return [malltextValues, lookup];
}
