import { Reducer } from 'react';
import { IFortnoxArticle } from 'types';

export type SyncState =
  | 'loadingPreview'
  | 'notStarted'
  | 'active'
  | 'done'
  | 'error';

export interface ISyncOperations {
  create: IFortnoxArticle[];
  remove: IFortnoxArticle[];
  update: IFortnoxArticle[];
}

export interface IUpdatePreview {
  [key: string]: ISyncOperations;
}

export interface IArticleSyncDialogState {
  generalError: string;
  updatePreview: IUpdatePreview;
  removeArticles: boolean;
  syncState: SyncState;
  syncProgress: number;
  syncMessage: string;
  syncErrors: string[];
}

export function initState(): IArticleSyncDialogState {
  return {
    generalError: '',
    updatePreview: {},
    removeArticles: false,
    syncState: 'loadingPreview',
    syncProgress: 0,
    syncMessage: '',
    syncErrors: [],
  };
}

type LoadStartAction = {
  type: 'LOAD_UPDATE_PREVIEW_START';
};

type LoadDoneAction = {
  type: 'LOAD_UPDATE_PREVIEW_DONE';
  payload: IUpdatePreview;
};

type LoadErrorAction = {
  type: 'LOAD_UPDATE_PREVIEW_ERROR';
  payload: string;
};

type SetRemoveArticlesAction = {
  type: 'SET_REMOVE_ARTICLES';
  payload: boolean;
};

type SyncStartAction = {
  type: 'SYNC_START';
};

type SyncProgressAction = {
  type: 'SYNC_PROGRESS';
  payload: {
    progress?: number;
    message?: string;
    error?: string;
  };
};

type SyncDoneAction = {
  type: 'SYNC_DONE';
};

type Action =
  | LoadStartAction
  | LoadDoneAction
  | LoadErrorAction
  | SetRemoveArticlesAction
  | SyncStartAction
  | SyncProgressAction
  | SyncDoneAction;

const reducer: Reducer<IArticleSyncDialogState, Action> = (
  prevState,
  action
): IArticleSyncDialogState => {
  switch (action.type) {
    case 'LOAD_UPDATE_PREVIEW_START': {
      return {
        ...prevState,
        syncState: 'loadingPreview',
        updatePreview: {},
      };
    }
    case 'LOAD_UPDATE_PREVIEW_DONE': {
      return {
        ...prevState,
        updatePreview: action.payload,
        syncState: 'notStarted',
      };
    }
    case 'LOAD_UPDATE_PREVIEW_ERROR': {
      return {
        ...prevState,
        generalError: action.payload,
        syncState: 'error',
      };
    }
    case 'SET_REMOVE_ARTICLES': {
      return {
        ...prevState,
        removeArticles: action.payload,
      };
    }
    case 'SYNC_START': {
      return {
        ...prevState,
        syncState: 'active',
        syncMessage: '',
        syncErrors: [],
      };
    }
    case 'SYNC_PROGRESS': {
      return {
        ...prevState,
        syncErrors: prevState.syncErrors.concat(action.payload.error || []),
        syncMessage: action.payload.message ?? prevState.syncMessage,
        syncProgress: action.payload.progress ?? prevState.syncProgress,
      };
    }
    case 'SYNC_DONE': {
      return {
        ...prevState,
        syncState: 'done',
        syncProgress: 0,
        syncMessage: '',
      };
    }
  }

  return prevState;
};

export default reducer;
