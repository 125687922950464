import React, { useContext, useEffect, useState } from 'react';
import {
  Calendar2Check,
  Gear,
  Envelope,
  BoxArrowRight as LogoutIcon,
  Person,
  PersonPlus,
  ExclamationTriangleFill,
} from 'react-bootstrap-icons';
import logo from '../../image/radea.png';
import { GlobalStateContext } from '../../global.context';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { RollKoll } from './roll.component';
import paths from 'constants/paths';

const RadeaNav = () => {
  let history = useHistory();
  const [namn, setNamn] = useState('');
  const [test, setTest] = useState(false);

  const { session } = useContext(GlobalStateContext);

  useEffect(() => {
    if (!session.authenticated) {
      return;
    }
    if (namn === '') {
      setNamn(session.namn);
      setTest(session.test);
    }
  }, [setNamn, setTest, namn, session]);

  return (
    <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark">
      <Container>
        <Navbar.Brand
          className="click-item"
          onClick={() => {
            history.push(paths.START);
          }}
        >
          <img src={logo} alt="logo" />
        </Navbar.Brand>
        {session.authenticated && (
          <>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                <Link to={paths.SCHUEDULES} className="nav-link">
                  <Calendar2Check size={19} className={'pb4'} />
                  Scheman
                </Link>
                <RollKoll role={['admin', 'affärsAdmin']}>
                  <Link to={paths.ORDER} className="nav-link">
                    <Envelope size={20} className={'pb4'} /> Order/Faktura
                  </Link>
                  <Link to={paths.CREATE_CONTACT} className="nav-link">
                    <PersonPlus size={22} />
                    Skapa kontakt
                  </Link>
                </RollKoll>
              </Nav>
              <Nav className="dropdown-nav">
                <span className="dropdown-icon">
                  <Person size={20} color="rgba(255, 255, 255, 0.6)" />
                </span>
                <NavDropdown title={namn} id="collapsible-nav-dropdown">
                  <RollKoll role={['admin']}>
                    <Link to={paths.ADMIN_START} className="dropdown-item">
                      <Gear /> Administration
                    </Link>
                  </RollKoll>
                  <Link to={paths.MY_PAGE} className="dropdown-item">
                    <Person /> Mina uppgifter
                  </Link>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href={paths.LOGOUT}>
                    <LogoutIcon /> Logga ut
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
            {test && (
              <span
                className="test-span"
                title="Servern är i testläge. Kopplar mot testversioner av Fortnox och Radonova. E-postmeddelanden går till testperson."
              >
                <ExclamationTriangleFill
                  size="16"
                  style={{ margin: '2px' }}
                  color="yellow"
                />
                <span>Server i testläge</span>
              </span>
            )}
          </>
        )}
      </Container>
    </Navbar>
  );
};

export default RadeaNav;
