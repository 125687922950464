import { useEffect, useState, useRef } from 'react';

export function useResizeObserver(element: Element | null): null | DOMRect {
  const [observedSize, setObservedSize] = useState<null | DOMRect>(null);
  const resizeObserver = useRef(
    new ResizeObserver((entries) => {
      setObservedSize(entries?.[0]?.target?.getBoundingClientRect() || null);
    })
  );

  useEffect(() => {
    const observer = resizeObserver.current;
    observer.disconnect();
    if (!element) {
      return;
    }
    observer.observe(element);
    setObservedSize(element.getBoundingClientRect() || null);

    return () => {
      observer.disconnect();
    };
  }, [element]);

  return observedSize;
}
