import { useState, useEffect } from 'react';
import { doGetList } from '../../components/action/services';

const cache = {};

export interface IKategori {
  id: number;
  namn: string;
}

export default function useKategori(
  kategori,
  includeUnspecified = true
): [IKategori[], (identifier: any, reverse?: boolean) => IKategori | ''] {
  const [kategorier, setKategorier] = useState(cache);
  const unspecified = includeUnspecified
    ? [
        {
          id: 0,
          namn: 'Välj kategori',
        },
      ]
    : [];

  useEffect(() => {
    if (cache[kategori]) {
      return;
    }
    doGetList(`/kategori/get/${kategori}`).then((response) => {
      cache[kategori] = response.data;
      setKategorier((kategorier) => ({
        ...kategorier,
        [kategori]: response.data,
      }));
    });
  }, [kategori]);

  const kategoriValues = unspecified.concat(kategorier[kategori] || []);

  const lookup = (identifier: number | string, reverse = false) => {
    const result = kategoriValues.find((k) => {
      if (reverse) {
        return k.namn === identifier;
      }
      return parseInt(String(k.id), 10) === parseInt(String(identifier), 10);
    });
    if (!result) {
      return '';
    }

    return result;
  };

  return [kategoriValues, lookup];
}
