import React, { useState, useEffect } from 'react';
import {
  Editor,
  EditorState,
  ContentState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
} from 'draft-js';
import { Button } from 'react-bootstrap';
import 'draft-js/dist/Draft.css';
import util from 'utils/util';
import draftToHtml from 'draftjs-to-html';

const RadeaEditor = ({
  initialText,
  onChange,
  id,
  readOnly,
  placeholder = '',
}) => {
  const [editorState, setEditorState] = useState(null);
  const [markup, setMarkup] = useState('');
  const [html, setHTML] = useState(false);

  useEffect(() => {
    if (editorState === null) {
      try {
        let theParse = JSON.parse(initialText ? initialText : '');
        setEditorState(EditorState.createWithContent(convertFromRaw(theParse)));
      } catch (err) {
        //old non-wysiwyg text found - set it to the editorstate
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromText(initialText ? initialText : '')
          )
        );
      }
    }
  }, [editorState, initialText]);

  useEffect(() => {
    if (readOnly) {
      let theParse = null;
      try {
        theParse = JSON.parse(initialText ? initialText : '');
        setMarkup(draftToHtml(theParse, {}, true, {}));
        setHTML(true);
      } catch (err) {
        setMarkup(initialText);
      }
    }
  }, [initialText, readOnly]);

  const onChangeHere = (editorState) => {
    setEditorState(editorState);
    const rawContentState = JSON.stringify(
      convertToRaw(editorState.getCurrentContent())
    );
    /*const markup = draftToHtml(
      rawContentState
    );*/
    onChange({
      target: {
        id: id,
        value: rawContentState,
      },
    });
  };

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChangeHere(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const onUnderlineClick = () => {
    onChangeHere(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'));
  };

  const onBoldClick = () => {
    onChangeHere(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
  };

  const onItalicClick = () => {
    onChangeHere(RichUtils.toggleInlineStyle(editorState, 'ITALIC'));
  };

  return (
    <div className={`radea-editor ${readOnly ? 'readonly' : ''}`}>
      {!readOnly && (
        <>
          {editorState !== null && (
            <div className="editors">
              <Editor
                editorState={editorState}
                handleKeyCommand={handleKeyCommand}
                onChange={onChangeHere}
                readOnly={readOnly}
                placeHolder={placeholder}
              />
            </div>
          )}
          <Button variant="primary" onClick={onUnderlineClick}>
            <u>Understruket</u>
          </Button>
          <Button variant="primary" onClick={onBoldClick}>
            <b>Fet</b>
          </Button>
          <Button variant="primary" onClick={onItalicClick}>
            <em>Kursiv</em>
          </Button>
        </>
      )}
      {readOnly && (
        <>
          <span
            dangerouslySetInnerHTML={{
              __html: html ? markup : util.replaceCarriageReturn(markup),
            }}
          ></span>
        </>
      )}
    </div>
  );
};

export default RadeaEditor;
