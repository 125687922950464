import { useEffect, useState, useRef, ReactNode } from 'react';
import util from 'utils/util';
import moment, { Moment } from 'moment-timezone';
import 'moment/locale/sv';
import {
  ArrowRightCircleFill,
  ArrowLeftCircleFill,
  CalendarEvent,
} from 'react-bootstrap-icons';
import { IPersonal, ITidsbokning } from 'types';
import CalendarBase from './calendar-base';
import { isRangeEvent, overlapsWeek } from './utils';
import { useResizeObserver } from 'hooks/useResizeObserver';
import DatePicker from 'components/common/date-picker';
import Button from 'react-bootstrap/Button';
import { CalendarContext } from './calendar-context';
import { formatName } from 'utils/personal';

const COLUMN_MODE_BREAKPOINT = 845;

export interface IRadeaCalendarProps {
  events: ITidsbokning[];
  personal: null | IPersonal;
  eventDate: null | ITidsbokning;
  registerDate: (date: string | null) => unknown;
  registerValidation: (valid: boolean, message?: string) => unknown;
  registerWeekChange: (date: Date) => unknown;
  selectedMonday?: Date;
  offHours?: boolean;
  bookable?: boolean;
  loading?: boolean;
  showDatePicker?: boolean;
}

function RadeaCalendar({
  selectedMonday = moment().startOf('isoWeek').toDate(),
  events,
  personal,
  offHours,
  eventDate,
  registerDate,
  registerValidation,
  registerWeekChange,
  bookable,
  loading,
  showDatePicker,
}: IRadeaCalendarProps) {
  const container = useRef<null | HTMLDivElement>(null);
  const containerSize = useResizeObserver(container.current);
  const [week, setWeek] = useState<Date[]>(util.getWeekArray(selectedMonday));
  const rangeEvents = events
    .concat(eventDate || [])
    .filter((e) => isRangeEvent(e) && overlapsWeek(e, week));

  useEffect(() => {
    setWeek(util.getWeekArray(selectedMonday));
  }, [selectedMonday]);

  const changeWeek = (direction: 1 | -1) => {
    if (loading) {
      return;
    }
    const updatedMonday = moment(selectedMonday)
      .startOf('isoWeek')
      .add(direction, 'week');

    if (registerWeekChange) {
      registerWeekChange(moment(updatedMonday).toDate());
    }
  };

  const validateSlot = (
    start: Moment,
    langd: number,
    isRangeEvent: boolean
  ) => {
    if (isRangeEvent) {
      registerValidation(true);

      return true;
    }
    const nonRangeEvents = events.filter(
      (e) =>
        !rangeEvents.find((re) => re.id === e.id) &&
        personal?.id === e.personalId
    );
    let valid = !nonRangeEvents.some((e) => {
      let eStart = moment(e.starttid);
      let tStart = moment(start);
      if (eStart.isSame(tStart, 'day')) {
        //om samma dag, kolla om det överlappar.
        let eEnd = moment(e.starttid).add(e.langd, 'minutes');
        let tEnd = moment(start).add(langd, 'minutes');
        return (
          eStart.isSame(tStart) ||
          (eStart.isBefore(tStart) && eEnd.isAfter(tStart)) ||
          (eStart.isAfter(tStart) && eStart.isBefore(tEnd)) ||
          (eStart.isAfter(tStart) && eEnd.isBefore(tEnd))
        );
      }
      return false;
    });
    if (!valid) {
      registerValidation(false, 'detta funkar ej');
    } else {
      registerValidation(true);
    }
    return valid;
  };

  let calendar: ReactNode = null;

  if (containerSize && containerSize?.width < COLUMN_MODE_BREAKPOINT) {
    calendar = week.map((d) => (
      <CalendarBase
        validateSlot={validateSlot}
        events={events}
        week={[d]}
        offHours={offHours}
        eventDate={eventDate}
        registerDate={registerDate}
        bookable={bookable}
        loading={loading}
      />
    ));
  } else {
    calendar = (
      <CalendarBase
        validateSlot={validateSlot}
        events={events}
        week={week}
        offHours={offHours}
        eventDate={eventDate}
        registerDate={registerDate}
        bookable={bookable}
        loading={loading}
      />
    );
  }

  return (
    <CalendarContext.Provider value={{ personal }}>
      <div ref={container}>
        <div className="calendar-header">
          <span className="week">
            <ArrowLeftCircleFill
              onClick={() => {
                changeWeek(-1);
              }}
              className="click-item"
              size={28}
              color={loading ? '#ddd' : '#669eb7'}
            />
            <span>
              {personal ? formatName(personal) : 'Allmän'} - vecka{' '}
              {moment(selectedMonday).week()}, {moment(selectedMonday).year()}
            </span>
            {showDatePicker && (
              <div style={{ marginRight: '7px' }}>
                <DatePicker
                  value={selectedMonday}
                  onRenderDisplay={({ onClick }) => (
                    <Button variant="light" onClick={onClick}>
                      <CalendarEvent />
                    </Button>
                  )}
                  onChange={(date) => {
                    registerDate(
                      moment(date)
                        .set('hours', 8)
                        .set('minutes', 0)
                        .toISOString()
                    );
                  }}
                />
              </div>
            )}
            <ArrowRightCircleFill
              onClick={() => {
                changeWeek(1);
              }}
              className="click-item"
              size={28}
              color={loading ? '#ddd' : '#669eb7'}
            />
          </span>
        </div>
        {calendar}
      </div>
    </CalendarContext.Provider>
  );
}

export default RadeaCalendar;
