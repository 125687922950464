import React, { useEffect, useState } from 'react';
import { doPost, doGet } from '../action/services';
import { PersonalSelectFormGroup } from '../common/personal.component';
import { RadeaAlert } from '../common/radea-alert.component';
import RadeaEditor from '../common/radea-editor';
import { Card, Button, Form, Col, Container, Nav } from 'react-bootstrap';
import Tidsbokningar from './tidsbokningar.component';
import SkapaTidsbokning from './skapatidsbokning.component';
import Aktiviteter from './aktiviteter.component';
import Bestallning from '../kontakt/bestallning.component';
import useKategori from 'utils/hooks/useKategori';
import kategori from '../../constants/kategori';
import styled from 'styled-components';
import BokningsbekraftelseModal from './components/bokningsbekraftelsemodal.component';
import useAktiviteter from './hooks/useAktiviteter';
import Badge from 'react-bootstrap/Badge';

const FlexedCardFooter = styled(Card.Footer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const STATE_BESIKTNING = 0,
  STATE_MATPAKET = 1,
  STATE_AKTIVITETER = 3;

const VisaBesiktning = (props) => {
  const { projekt, kontakt, create, refresh, readOnly, cancel } = props;
  const [refreshBookingList, setRefreshBookingList] = useState(0);
  const [alert, setAlert] = useState({ show: false, msg: '', type: '' });
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [active, setActive] = useState(STATE_BESIKTNING);
  const [formData, setFormData] = useState({
    id: create !== null && create ? 0 : projekt.id,
    kontaktId: projekt.kontaktId,
    friendlyName: projekt.friendlyName,
    affarsomrade: create ? 'Ospec.' : projekt.affarsomrade,
    ansvarig: create ? 0 : projekt.ansvarig,
    kundlevel: create ? 0 : projekt.kundlevel,
    besiktningsinformation: create ? '' : projekt.besiktningsinformation,
  });
  const [affarsomrade] = useKategori(kategori.Affarsomrade, false);
  const [aktiviteter] = useAktiviteter(projekt.id);

  useEffect(() => {
    setFormData({
      id: create ? 0 : projekt.id,
      kontaktId: projekt.kontaktId,
      affarsomrade: projekt.affarsomrade,
      ansvarig: projekt.ansvarig,
      kundlevel: projekt.kundlevel,
      besiktningsinformation:
        projekt.besiktningsinformation === null
          ? ''
          : projekt.besiktningsinformation,
    });
  }, [projekt, create]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    formData.namn = `Besiktning${
      formData.affarsomrade ? `, ${formData.affarsomrade}` : ''
    }`;
    let result = await doPost(
      `/projekt/${formData.id > 0 ? 'update' : 'create'}`,
      formData
    );
    if (result.data.success) {
      showAlert('Besiktning har sparats i systemet.', 'success', () => {
        refresh();
      });
    } else {
      showAlert(
        'Besiktning har inte sparats i systemet, kontakta supporten.',
        'danger'
      );
    }
  };

  const coldcase = async (coldcase) => {
    const result = await doGet(
      `/coldcase/${coldcase ? 'set' : 'remove'}/${formData.id}`
    );
    if (result.data.success) {
      let text = coldcase
        ? 'Projektet är markerat som coldcase.'
        : 'Projektet är inte längre ett coldcase.';
      showAlert(text, 'success', () => {
        refresh();
      });
    } else {
      showAlert(
        'Projektet kunde inte markeras/avmarkeras some coldcase, kontakta supporten.',
        'danger'
      );
    }
  };

  const showAlert = (msg, type, callback) => {
    setAlert({ show: true, msg: msg, type: type });
    setTimeout(() => {
      if (callback) {
        callback();
      }
      setAlert({
        show: false,
        msg: '',
        type: 'success',
      });
    }, 2800);
  };

  const sendBokningsbekraftelse = async (data) => {
    let result = await doPost('/tidsbokningar/bekraftelse', data);
    if (result.data && result.data.success) {
      showAlert(
        `${
          result.data.msg
            ? result.data.msg
            : 'Bokningsbekräftelse har skickats.'
        }`,
        'success'
      );
    } else if (result.data) {
      showAlert(
        `${
          result.data.msg
            ? result.data.msg
            : 'Fel, bokningsbekräftelsen kunde inte mailas, kontakta supporten.'
        }`,
        'danger'
      );
    } else {
      showAlert(
        'Fel, bokningsbekräftelsen kunde inte mailas, kontakta supporten.',
        'danger'
      );
    }
  };

  return (
    <>
      {!readOnly && (
        <>
          <BokningsbekraftelseModal
            show={showEmailModal}
            onHide={() => setShowEmailModal(false)}
            onSubmit={sendBokningsbekraftelse}
            projektId={projekt.id}
            isBesiktning
          />
          <Card className="form-card">
            <RadeaAlert msg={alert.msg} type={alert.type} show={alert.show} />
            <Card.Header as="h5">
              {create ? 'Boka ny besiktning' : projekt.namn}
              {projekt.coldcase ? ' - Coldcase' : ''}
            </Card.Header>
            <Card.Body>
              <Form>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Group controlId="affarsomrade">
                      <Form.Label>Affärsområde</Form.Label>
                      <Form.Control
                        as="select"
                        size="sm"
                        value={formData.affarsomrade}
                        onChange={handleChange}
                        custom
                      >
                        {affarsomrade.map((omrade, idx) => {
                          return (
                            <option key={`omr${idx}`} value={omrade.namn}>
                              {omrade.namn}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="ansvarig">
                      <PersonalSelectFormGroup
                        selectedId={formData.ansvarig}
                        label={'Kundansvarig'}
                        aktiva={create ? true : false}
                        small={true}
                        registerSelected={handleChange}
                        roll={['projektbesiktning']}
                      />
                    </Form.Group>
                    <Form.Group controlId="kundlevel">
                      <Form.Label>Level</Form.Label>
                      <Form.Control
                        as="select"
                        size="sm"
                        value={formData.kundlevel}
                        onChange={handleChange}
                        custom
                      >
                        <option value={0}>Ospec.</option>
                        <option value={1}>1. Kall</option>
                        <option value={2}>2. Ganska Kall</option>
                        <option value={3}>3. Ljummen</option>
                        <option value={4}>4. Varm</option>
                        <option value={5}>5. Heting</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="friendlyName">
                      <Form.Label>Besiktningsnamn</Form.Label>
                      <Form.Control
                        placeholder="Ange ett namn för besiktningen (valfritt)"
                        value={formData.friendlyName}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Form.Group>
                  <Form.Group as={Col} controlId="besiktningsinformation">
                    <Form.Label>Bokningsinformation</Form.Label>
                    <RadeaEditor
                      initialText={
                        formData.besiktningsinformation === null
                          ? ''
                          : formData.besiktningsinformation
                      }
                      onChange={handleChange}
                      id="besiktningsinformation"
                      readOnly={false}
                    />
                  </Form.Group>
                </Form.Row>
              </Form>
            </Card.Body>
            <FlexedCardFooter>
              <div>
                {!create && projekt.coldcase === 0 && (
                  <Button
                    variant="link"
                    onClick={() => {
                      setShowEmailModal(true);
                    }}
                  >
                    Skicka bokningsbekräftelse
                  </Button>
                )}
              </div>
              <div>
                {create && (
                  <Button
                    variant="link"
                    onClick={() => {
                      cancel();
                    }}
                  >
                    Avbryt
                  </Button>
                )}
                {!create && projekt.coldcase === 0 && (
                  <Button
                    variant="link"
                    onClick={() => {
                      coldcase(true);
                    }}
                  >
                    Gör till Coldcase
                  </Button>
                )}
                {!create && projekt.coldcase === 1 && (
                  <Button
                    variant="link"
                    onClick={() => {
                      coldcase(false);
                    }}
                  >
                    Ta bort Coldcase
                  </Button>
                )}
                <Button
                  variant="primary"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Spara
                </Button>
              </div>
            </FlexedCardFooter>
          </Card>

          {!create && (
            <>
              <Nav variant="tabs" defaultActiveKey={active}>
                <Nav.Item>
                  <Nav.Link
                    eventKey={STATE_BESIKTNING}
                    onClick={(e) => {
                      setActive(STATE_BESIKTNING);
                    }}
                  >
                    Besiktning
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey={STATE_AKTIVITETER}
                    onClick={(e) => {
                      setActive(STATE_AKTIVITETER);
                    }}
                  >
                    Aktiviteter{' '}
                    {(aktiviteter || []).length !== 0 && (
                      <Badge variant="primary">
                        {(aktiviteter || []).length}
                      </Badge>
                    )}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey={STATE_MATPAKET}
                    onClick={(e) => {
                      setActive(STATE_MATPAKET);
                    }}
                  >
                    Beställ mätpaket
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Container className={'tab-container'}>
                {active === STATE_BESIKTNING && (
                  <>
                    <h5>Inbokade åtgärder</h5>
                    <Tidsbokningar
                      projektId={projekt.id}
                      isBesiktning={true}
                      refreshList={refreshBookingList}
                    />
                    {!readOnly && (
                      <SkapaTidsbokning
                        atgardstyper={[1]}
                        projektId={projekt.id}
                        ort={projekt.ort}
                        refreshList={() => {
                          setRefreshBookingList(refreshBookingList + 1);
                        }}
                      />
                    )}
                  </>
                )}
                {active === STATE_AKTIVITETER && (
                  <>
                    <h5>Aktiviteter</h5>
                    <Aktiviteter projektId={projekt.id} />
                  </>
                )}
                {active === STATE_MATPAKET && (
                  <>
                    <h5>Beställ mätpaket</h5>
                    <Bestallning kontakt={kontakt} />
                  </>
                )}
              </Container>
            </>
          )}
        </>
      )}
      {readOnly && (
        <>
          <RadeaEditor
            initialText={
              formData.besiktningsinformation === null
                ? ''
                : formData.besiktningsinformation
            }
            onChange={handleChange}
            id="besiktningsinformation"
            readOnly={true}
          />
          <Tidsbokningar
            projektId={projekt.id}
            isBesiktning={true}
            ort={projekt.ort}
            readOnly={true}
          />
        </>
      )}
    </>
  );
};

export default VisaBesiktning;
