import { FieldType } from 'types';

export function generateChangeStyleFunction<T>(changes?: T, list?: boolean) {
  return (field: keyof T, type?: FieldType, noLabel: boolean = false) => {
    let styles = ' ';
    if (changes?.[field] === undefined) {
      return '';
    }

    styles += 'form-control-changed';

    switch (type) {
      case 'date': {
        styles += ' is-date';
        break;
      }
      case 'select': {
        styles += ' is-select';
        break;
      }
    }

    if (noLabel) {
      styles += ' no-label';
    }

    if (list) {
      styles += ' is-list';
    }

    return styles;
  };
}
