import { ISessionData } from '../global.context';
import { RoleType } from 'types';

export function hasRole(
  role: RoleType | RoleType[],
  session: ISessionData
): boolean {
  if (!session || !session.roller) {
    return false;
  }

  const rolesToCheck = Array.isArray(role) ? role : [role];

  return (
    session.roller?.some((roll) => rolesToCheck.includes(roll.typ)) ?? false
  );
}
